import React, { useEffect, useState } from "react";
import iconCal from "../../../../assets/img/icon-cal.svg";
import iconWatch from "../../../../assets/img/icon-watch.svg";
import iconCard from "../../../../assets/img/icon-card.svg";
import dpPhoto from "../../../../assets/img/img_avatar.png";
import IconLike from "../../../../assets/img/icon-like.svg";
import { AddViewer, GetEventBooking } from "../../../../redux/actions/events";
import { Notify } from "../../../../helpers/notifications";
import { useHistory } from "react-router";
import { getEventLocalDate } from "../../../../helpers/constants";
import Logo from "../../../loader/Loader";
import { connect } from "react-redux";
import Store from "../../../../redux/store/store";

function BookingDetails({
  setSelectedTab,
  eventID,
  bookingID,
  notifBID,
  notifEID,
}) {
  let notiEventID = notifEID;
  let notiBookingID = notifBID;
  const [eventData, setEventData] = useState({});
  const [videoMute, setVideoMute] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const history = useHistory();
  const { dispatch } = Store.store;

  useEffect(() => {
    document.title = "LiveStage | Booking Details";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setShowLoader(true);
    let payload = {
      eventId: notiEventID ? notiEventID : eventID,
      bookingId: notiBookingID ? notiBookingID : bookingID,
    };
    GetEventBooking(payload)
      .then((response) => {
        setEventData(response.data.data);
        setShowLoader(false);
        dispatch({
          type: "EVENTPREVIEWFILE",
          payload: {
            eventFile: response.data.data.eventImage,
            videoStatus: response.data.data.videoStatus,
          },
        });
      })
      .catch((error) => {
        setShowLoader(false);
      });
  }, [notiEventID]);

  const handleViewer = async () => {
    let match = localStorage.getItem("petros-unique-id-view");
    let payload = {
      fcmToken: localStorage.getItem("fcmToken"),
      uniqueId: match,
      eventId: eventData._id,
    };
    try {
      let response = await AddViewer(payload);
      if (response.status === 200) {
        localStorage.setItem(
          "uniqueTokenforViewing",
          `${response.data.data.fcmToken}`
        );
        localStorage.setItem(
          "petros-unique-id-view",
          response.data.data.uniqueId
        );
        history.push(`/streamEvent`, {
          eventId: eventData._id, shareEventURL: eventData.share_url
        });
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  return (
    <div>
      <section
        className="events-section enduser-section"
        style={{
          paddingTop: "0px",
          position: "relative",
          zIndex: 2,
        }}
      >
        <div className="flex-container">
          <div className="breadcrumbs">
            <ul>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    setSelectedTab(3);
                    dispatch({ type: "EMPTY_EVENTID" });
                  }}
                >
                  My Bookings
                </a>{" "}
                <span>&#62;</span>{" "}
              </li>
              <li>Booking Details</li>
            </ul>
          </div>

          {showLoader ? (
            <div>
              <Logo />
            </div>
          ) : (
            <div className="event-details">
              <h1>{eventData.eventTitle}</h1>
              <div className="content">
                <div className="date-time">
                  <div className="flex-row">
                    <div>
                      <img src={iconCal} alt="User" />
                      {
                        getEventLocalDate(
                          eventData.eventDate,
                          eventData.eventTime,
                          eventData.setTimeZone
                        ).split(",")[0]
                      }
                    </div>
                    <div>
                      <img src={iconWatch} alt="User" />
                      {
                        getEventLocalDate(
                          eventData.eventDate,
                          eventData.eventTime,
                          eventData.setTimeZone
                        ).split(",")[1]
                      }
                    </div>
                  </div>

                  <div className="flex-row">
                    <div>BOOKING ID: #{eventData.randomBookingId}</div>
                  </div>
                  <div className="flex-row">
                    <div>
                      <img src={iconCard} alt="User" /> €{" "}
                      {parseFloat(eventData.bookingPrice).toFixed(2)}
                    </div>
                  </div>

                  <div className="flex-row">
                    <div>Quantity: {eventData?.totalTickets}</div>
                  </div>
                  <div className="flex-row">
                    Category : {eventData.eventCategory}
                  </div>
                  {eventData.isVoucherApplied && (
                    <div className="flex-row">
                      Voucher Amount: € {eventData.voucherAmt}
                    </div>
                  )}
                  <div className="flex-row">
                    Duration:{" "}
                    {parseInt(parseInt(eventData?.eventDuration) / 60) >= 1 &&
                      `${parseInt(
                        parseInt(eventData?.eventDuration) / 60
                      )} hours`}{" "}
                    {parseInt(parseInt(eventData?.eventDuration) % 60) > 0 &&
                      `${parseInt(
                        parseInt(eventData?.eventDuration) % 60
                      )} minutes`}
                  </div>
                </div>
                <div className="description-scroll">
                  <p style={{ whiteSpace: "pre-line" }}>
                    {eventData?.description}
                  </p>
                </div>

                {!eventData.isCancelled && ((eventData.platform == 'easyStream' && eventData.isSessionStart) ||
                  (eventData.platform == 'rtmp' && eventData.mediaLive_stream_start
                    && !eventData.mediaLive_stream_end)) && eventData.alreadyBooked && (
                    <button
                      className="primary-button"
                      type="button"
                      onClick={() => {
                        handleViewer();
                      }}
                    >
                      Watch Now
                    </button>
                  )}
                {eventData.isCancelled && (
                  <span style={{ color: "#e50914", fontSize: "30px" }}>
                    Event Cancelled
                  </span>
                )}
              </div>

              <div className="banner-wrap">
                <div
                  className="content banner-content-box"
                  style={{ width: "100%" }}
                >
                  <div className="enduser-artist" style={{ marginTop: "0px" }}>
                    <h4>Artist</h4>
                    <div className="row-content">
                      <div
                        style={{
                          height: "200px",
                          width: "65%",
                          backgroundImage: `url(${eventData?.userId?.profilePhoto
                            ? eventData.userId?.profilePhoto
                            : dpPhoto
                            })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      ></div>
                      <div className="content">
                        <h5>
                          {eventData?.userId?.firstName}{" "}
                          {eventData?.userId?.lastName}
                        </h5>
                        <h6>{eventData?.userId?.category}</h6>
                        {eventData?.userId?.rateCount > 0 && (
                          <div className="rating">
                            <span
                              className="like-con-book"
                              style={{ marginBottom: "0px", cursor: "auto" }}
                            >
                              <img src={IconLike} alt="" />
                              {eventData.artistRating} %
                            </span>
                          </div>
                        )}
                        <div className="description-scroll">
                          <p
                            className="p-box"
                            style={{
                              maxWidth: "500px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {eventData?.userId?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = ({ NOTIF_REDUCER }) => {
  return {
    notifEID: NOTIF_REDUCER.notiEventID,
    notifBID: NOTIF_REDUCER.notiBookingID,
  };
};

export default connect(mapStateToProps, {})(BookingDetails);