import React from "react";
import { Route } from "react-router-dom";

const isEqual = (prevProps, nextProps) => {
  return prevProps.path == nextProps.path
}

const Wrapper = React.memo(({ path, Component }) => {
  return (
    <>
      <Route exact path={path} component={() => Component} />
    </>
  );
}, isEqual);

export default Wrapper;