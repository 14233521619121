import React, { useEffect, useState } from "react";
import { GetMyBookings } from "../../../redux/actions/events";
import { getEventLocalDate } from "../../../helpers/constants";
import iconPlay from "../../../assets/img/play-button.png";
import DownloadReceipt from "./downloadReceipt";

function ViewAllBookings({ text, setShowViewAll, setSelectedTab, setEventID, setBookingID }) {
  const [paramsPayload, setParamsPayload] = useState({
    type: text === "upCommingData" ? 2 : 1,
    searchString: "",
  });
  const [myBookingData, setMyBookingData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    GetMyBookings(paramsPayload)
      .then((response) => {
        setMyBookingData([]);
        setMyBookingData([...response.data.data?.bookingDetails]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [paramsPayload]);

  // Search Events
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (flag) {
        setParamsPayload({ ...paramsPayload, searchString: searchKeyword });
      }
      setFlag(true);
    }, 750);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchKeyword]);

  const downloadReceipt = async (receipt) => {
    DownloadReceipt(receipt);
  };

  return (
    <div>
      <div className="breadcrumbs">
        <ul>
          <li>
            <a className="cursorPointer" onClick={() => setShowViewAll(false)}>
              My Bookings
            </a>{" "}
            <span>&#62;</span>{" "}
          </li>
          <li>
            {text === "upCommingData"
              ? "Upcoming Events"
              : text === "pastData"
                ? "Past Events"
                : text}
          </li>
        </ul>
      </div>

      <div className="events-listing">
        <div className="title">
          <h2>
            {text === "upCommingData"
              ? "Upcoming Events"
              : text === "pastData"
                ? "Past Events"
                : text}
          </h2>
        </div>

        <div className="events-search">
          <input
            type="text"
            placeholder="Search"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </div>

        {myBookingData?.length > 0 ? (
          myBookingData?.map((eventItem, index) => (
            <div className="loop grey-wrap item" key={index}>
              <div
                className="flex-row cursorPointer"
                onClick={() => {
                  setEventID(eventItem.eventId._id);
                  setBookingID(eventItem._id);
                  setSelectedTab(9);
                }}
              >
                <div className="col-img">
                  <div
                    className="media766"
                    onMouseOver={(e) => {
                      e.target.style.backgroundImage = `url(${eventItem.eventId.videoStatus ? eventItem.eventId.gifURL : eventItem.eventId.eventImage})`
                    }}
                    onMouseOut={(e) => {
                      e.target.style.backgroundImage = `url(${eventItem.eventId.videoStatus
                        ? eventItem.eventId.thumbnailImage
                        : eventItem.eventId.eventImage})`
                    }}
                    style={{
                      minHeight: "100%",
                      width: "100%",
                      verticalAlign: "bottom",
                      backgroundImage: `url(${eventItem.eventId.videoStatus
                        ? eventItem.eventId.thumbnailImage
                        : eventItem.eventId.eventImage
                        })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                  {eventItem.eventId.videoStatus && <div style={{
                    position: "absolute",
                    bottom: "16px"
                  }}><img src={iconPlay} style={{ width: "40px" }} /></div>}
                </div>
                <div className="col-content">
                  <div className="details">
                    <h3
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {eventItem.eventId.eventTitle}{" "}
                      {eventItem.eventId.isCancelled && (
                        <span style={{ color: "#e50914" }}>
                          Event Cancelled
                        </span>
                      )}
                    </h3>
                    <div
                      className="description-scroll"
                      style={{ maxHeight: "100px" }}
                    >
                      <p style={{ whiteSpace: "pre-line" }}>
                        {eventItem.eventId.description}
                      </p>
                    </div>
                    <div className="other-detail">
                      <div className="flex-row">
                        Number of tickets <span>{eventItem.totalTickets}</span>
                      </div>
                      <div className="flex-row">
                        Price <span>€{eventItem.totalPrice}</span>
                      </div>
                      <div className="flex-row">
                        Date and Time{" "}
                        <span>
                          {getEventLocalDate(
                            eventItem.eventId.eventDate,
                            eventItem.eventId.eventTime,
                            eventItem.eventId.setTimeZone
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ textAlign: "center", padding: "10px" }}>
                <a
                  className="download cursorPointer"
                  // href={eventItem.receipt}
                  download="Receipt"
                  target="_blank"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent the default action (opening the link)
                    downloadReceipt(eventItem.receipt); // Call your custom function
                  }}
                >
                  Download Ticket Receipt
                </a>
              </div>
            </div>
          ))
        ) : (
          <div>No Bookings Found</div>
        )}
      </div>
    </div>
  );
}

export default ViewAllBookings;