import React, { useEffect, useState, useRef } from "react";
import easy from "../../../assets/img/easystream.png"
import rtmp from "../../../assets/img/rtmp.png"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SelectEvent({ setStreamType, setStepper }) {
    const [state, setState] = useState("")
    const bottomRef = useRef(null);

    const handleChange = (type) => {
        if (state !== "") {
            setStreamType(state)
            setStepper(2)
        } else {
            toast.error("Please select atleast one option");
        }
    };

    return (
        <div ref={bottomRef}>
            <ToastContainer />
            <div style={{ marginLeft: '150px' }}>
                <h1 className="streamh1">
                    Lorem ipsum dollar site amet
                </h1>
                <h5 className="streamboxh5">
                    Lorem Ipsum is simply dummy text of the sdsspthe sdssprintingypesetting industry. Lorem Ipsum has been.
                </h5>
            </div>
            <div className="stream">
                <box className={state === "easy" ? "streambox streamboxActive" : "streambox"} id="easy" onClick={() => setState("easy")}>
                    <div onClick={() => bottomRef.current?.scrollIntoView({ behavior: 'smooth', })} className="streamboxContent">
                        <img src={easy} />
                        <h4 className="streamboxContenth4">Easy Stream</h4>
                        <ul>
                            <li className="streamboxContentli">
                                Lorem Ipsum dollar site
                            </li>
                            <li className="streamboxContentli">
                                Lorem Ipsum dollar amet is dalast
                            </li>
                            <li className="streamboxContentli">
                                Lorem Ipsum dollar site
                            </li>
                            <li className="streamboxContentli">
                                Lorem Ipsum dollar amet is dalast
                            </li>
                        </ul>
                    </div>

                </box>
                <box className={state === "rtmp" ? "streambox streamboxActive" : "streambox"} id="rtmp" onClick={() => setState("rtmp")}>
                    <div onClick={() => bottomRef.current?.scrollIntoView({ behavior: 'smooth', })} className="streamboxContent">
                        <img src={rtmp} />
                        <h4 className="streamboxContenth4">RTMP</h4>
                        <ul>
                            <li className="streamboxContentli">
                                Lorem Ipsum dollar site
                            </li>
                            <li className="streamboxContentli">
                                Lorem Ipsum dollar amet is dalast
                            </li>
                            <li className="streamboxContentli">
                                Lorem Ipsum dollar site
                            </li>
                            <li className="streamboxContentli">
                                Lorem Ipsum dollar site
                            </li>
                            <li className="streamboxContentli">
                                Lorem Ipsum dollar amet is dalast
                            </li>
                        </ul>
                    </div>
                </box>

            </div>
            <div className="btn-con">
                <button
                    type="button"
                    className="primary-buttonn"
                    onClick={() => handleChange()}
                >
                    Continue
                </button>
            </div>
        </div>
    )
}

export default SelectEvent