import React, { useEffect, useState } from "react";
import logo from "../../assets/img/logo.svg";
import forgotBGImage from "../../assets/img/forgot-screen-bg-img.svg";
import eyeopen from "../../assets/img/eyeopen.svg";
import eyeclose from "../../assets/img/eyeclose.svg";
import { Validation } from "../../helpers/validations";
import { UpdatePwd } from "../../redux/actions/user";
import { Notify } from "../../helpers/notifications";
import { connect } from "react-redux";

function ChangePassword({ history, userEmail, role }) {
  const [currentPass, setCurrentPass] = useState();
  const [newPass, setNewPass] = useState();
  const [confirmPass, setConfirmPass] = useState();
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    document.title = "LiveStage | Change Password"

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [])

  // Update Password
  const handlePwdUpdate = async () => {
    let fields = ["currentPassword", "newPassword", "confirmPassword"];

    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;
    let payload = {
      password: currentPass,
      newPassword: newPass,
    };
    //API call
    try {
      const response = await UpdatePwd(payload);

      if (response && response.status === 200) {
        Notify("success", "Your password has been updated successfully.");
        if (role === "User" || role === "Corporate") {
          history.push("/home/user");
        } else if (role === "Actor") {
          history.push("/home/actor");
        }
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
      setCurrentPass("");
      setNewPass("");
      setConfirmPass("");
    }
  };

  // Password Validator function
  const handleValidation = (type) => {
    if (type === "currentPassword") {
      if (Validation.empty(currentPass)) {
        setError({ ...error, currentPass: "Current Password required" });
        return false;
      } else if (!Validation.password(currentPass)) {
        setError({
          ...error,
          currentPass:
            "Your password needs to be alphanumeric and at least 6 characters. It should contain at least one number, at least one capital letter and at least a special character (!$%*).",
        });
        return false;
      }
    } else if (type === "newPassword") {
      if (Validation.empty(newPass)) {
        setError({ ...error, newPass: "New Password required" });
        return false;
      } else if (!Validation.password(newPass)) {
        setError({
          ...error,
          newPass:
            "Your password needs to be alphanumeric and at least 6 characters. It should contain at least one number, at least one capital letter and at least a special character (!$%*).",
        });
        return false;
      }
    } else if (type === "confirmPassword") {
      if (Validation.empty(confirmPass)) {
        setError({ ...error, confirmPass: "Confirm Password required" });
        return false;
      } else if (newPass !== confirmPass) {
        setError({ ...error, confirmPass: "Passwords do not match" });
        return false;
      }
    }
    return true;
  };

  return (
    <div id="app" className="page-login">
      <header className="app-header">
        <div className="flex-container">
          <div className="flex-header">
            <div className="logo">
              <a href={(role === "User" || role === "Corporate") ? "/home/user" : "/home/actor"}>
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="user-section">
        <div className="flex-container">
          <div className="grey-wrap login-wrap">
            <div className="flex-row flex-centered">
              <div className="flex-col-2">
                <div className="img side-line">
                  <img src={forgotBGImage} alt="Hero" />
                </div>
              </div>
              <div className="flex-col-2">
                <div className="login-box">
                  <div className="title">
                    <h1>Change Password</h1>
                  </div>

                  <div className="field-wrap">
                    <a
                      className="icon-viewpswd"
                      onClick={() => setShowCurrentPass(!showCurrentPass)}
                    >
                      <img
                        src={showCurrentPass ? eyeopen : eyeclose}
                        alt="img"
                      />
                    </a>
                    <input
                      type={showCurrentPass ? "text" : "password"}
                      name=""
                      required=""
                      placeholder="Current Password*"
                      value={currentPass}
                      onChange={(e) => setCurrentPass(e.target.value)}
                      onFocus={(e) => setError({ ...error, currentPass: "" })}
                      onBlur={(e) => handleValidation("currentPassword")}
                    />
                    {error && (
                      <span style={{ color: "red" }}>{error?.currentPass}</span>
                    )}
                  </div>
                  <div className="field-wrap">
                    <a
                      className="icon-viewpswd"
                      onClick={() => setShowNewPass(!showNewPass)}
                    >
                      <img src={showNewPass ? eyeopen : eyeclose} alt="img" />
                    </a>
                    <input
                      type={showNewPass ? "text" : "password"}
                      name=""
                      required=""
                      placeholder="New Password*"
                      value={newPass}
                      onChange={(e) => setNewPass(e.target.value)}
                      onFocus={(e) => setError({ ...error, newPass: "" })}
                      onBlur={(e) => handleValidation("newPassword")}
                    />
                    {error && (
                      <span style={{ color: "red" }}>{error?.newPass}</span>
                    )}
                  </div>
                  <div className="field-wrap">
                    <a
                      className="icon-viewpswd"
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                    >
                      <img
                        src={showConfirmPass ? eyeopen : eyeclose}
                        alt="img"
                      />
                    </a>
                    <input
                      type={showConfirmPass ? "text" : "password"}
                      name=""
                      required=""
                      placeholder="Confirm your password*"
                      value={confirmPass}
                      onChange={(e) => setConfirmPass(e.target.value)}
                      onFocus={(e) => setError({ ...error, confirmPass: "" })}
                      onBlur={(e) => handleValidation("confirmPassword")}
                    />
                    {error && (
                      <span style={{ color: "red" }}>{error?.confirmPass}</span>
                    )}
                  </div>
                  <div className="btn-wrap text-center">
                    <button
                      type="button"
                      className="primary-button"
                      onClick={(e) => handlePwdUpdate()}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    userEmail: USER_REDUCER.userData?.email,
    role: USER_REDUCER.userData?.role,
  };
};

export default connect(mapStateToProps, {})(ChangePassword);