import Axios from "axios";
import store from "../store/store";
import { LogOutAction } from "../actions/user";
import { Notify } from "../../helpers/notifications";
import { REACT_APP_BASE_URL } from "../../helpers/env";

// BaseURL of Backend Server
export const baseURL = {
  URL: REACT_APP_BASE_URL
};

// Axios instance with a max timeout of 10s for Api pending request
const Api = () => {
  const defaultOptions = {
    baseURL: baseURL.URL,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  let instance = Axios.create(defaultOptions);

  instance.interceptors.response.use(
    (response) => response,
    (err) => {
      console.log("From API.JS", err.response);
      const message = err.response.data;
      const status = err.response.status;

      console.log(status);
      if (status === 401 && localStorage.getItem("isLogout") == "false") {
        localStorage.setItem("isLogout", true);
        store.store.dispatch(LogOutAction());
      }
      return Promise.reject(err);
    }
  );
  return instance;
};

export default Api();