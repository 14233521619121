// import { Notify } from "../../helpers/notifications";
import Api from "../api/api";
const cookie = key=>((new RegExp((key || '=')+'=(.*?); ','gm')).exec(document.cookie+'; ') ||['',null])[1]

// Create Event (Actor)
export const CreateEventActor = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("api/v1/users/createEvent", payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// TZ
export const GetTimeZones = () => {
   const token = cookie('jwtTokenPetros');

  return Api.get("api/v1/users/getTimeZone", {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
}

// Check Corporate Email
export const CheckCorporateEmail = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("api/v1/users/checkCorporateEmail", payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
}

// Get My Events (Actor)
export const GetMyEvents = (cb,month) => (dispatch) => {
   const token = cookie('jwtTokenPetros');

  Api.get("/api/v1/users/getMyEvent", {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      month: month
    },
  })
  .then((response) => {
    // console.log(response);
    cb(response);
    dispatch({ type: "GET_MYEVENTS", payload: response.data.data})
  })
  .catch((err) => {
    console.log(err);
    cb(err);
  })
};

// export const GetMyEventsTabs = () => {
//    const token = cookie('jwtTokenPetros');

//   return Api.get("/api/v1/users/getMyEvent", {
//     headers: {
//       "Authorization": `Bearer ${token}`,
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//     },
//   })
// }

// Get Event Details (Actor, User & Corporate)
export const GetEventDetails = (id) => {
   const token = cookie('jwtTokenPetros');

  return Api.get(`/api/v1/users/getEventDetail/${id}`, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  });
}

// Get Event Booking ( User & Corporate)
export const GetEventBooking = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.get(`/api/v1/users/getEventBooking`, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      eventId: payload.eventId,
      bookingId: payload.bookingId
    }
  });
}

// Cancel Event (Actor)
export const CancelEvent = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("/api/v1/users/cancelEvent", payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  });
}

// reschedule Event (Actor)
export const RescheduleEvent = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.put("/api/v1/users/rescheduleEvent", payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  });
}

// Graph Data (Amount Earned)
export const GetAmountEarnedData = (month) => {
   const token = cookie('jwtTokenPetros');

  return Api.get("/api/v1/users/getAmountEarned", {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      fromDate: month?.startDate,
      toDate: month?.endDate
    }
  });
}

// Event List (User, Corporate)
export const GetEventList = (paramsPayload) => {
  // console.log("EVENTS REDUCER:::: ",paramsPayload)
   const token = cookie('jwtTokenPetros');

  return Api.get("/api/v1/users/eventList", {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      skip: paramsPayload?.skip,
      limit: paramsPayload?.limit,
      category: paramsPayload?.category,
      lowTohigh: paramsPayload?.lowTohigh,
      highTolow: paramsPayload?.highTolow,
      minAmount: paramsPayload?.minAmount,
      maxAmount: paramsPayload?.maxAmount,
      searchString: paramsPayload?.searchString
    }
  });
}

// Event Booking
export const EventBooking = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("/api/v1/users/bookEvent", payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  });
}


// Event Booking
export const CheckBookedEmail = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("/api/v1/users/checkBookedEmail", payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  });
}

// Get My Bookings (User/ Corporate)
export const GetMyBookings = (paramsPayload) => {
   const token = cookie('jwtTokenPetros');

  return Api.get("/api/v1/users/getMyBookings", {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }, 
    params: {
      type: paramsPayload?.type,
      searchString: paramsPayload?.searchString
    }
  });
}

// Create Stream Session (Actor) 
export const CreateStream = (params) => {
   const token = cookie('jwtTokenPetros');

  return Api.get("/api/v1/users/createStream",{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      eventId: params?.eventId,
      deviceType: params.deviceType
    }
  })
}

// Create token for User to watch event
export const CreateParticipant = (params) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("/api/v1/users/participant",null,{
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      eventId: params?.eventId
    }
  })
}

// Start Broadcast
export const StartBroadcast = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("/api/v1/users/startBroadCast", payload, {
    headers: {
    "Authorization": `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    }
  });
}

// Close Event 
export const CloseEvent = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("api/v1/users/closeEvent", payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
}

// Send tip to Artist
export const TipArtist = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("api/v1/users/tipArtist", payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  });
}

// Rate Event (Notification);
export const RateEvent = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("api/v1/users/rateEvent", payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  });
}

// Get Event Details for Survey 
export const GetEventDetailsSurvey = (params) => {
  return Api.get(`api/v1/auth/getEventDetail/${params}`, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

// Event Survey
export const EventFeedbackApi = (payload) => {
  return Api.post("api/v1/auth/feedBackForm", payload, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  });
}

// Report Event
export const ReportEventActor = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("/api/v1/users/reportEvent", payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

// Report Comment
export const ReportComment = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("/api/v1/users/reportComment", payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

// Cancel Reason List
export const CancelReasonList = () => {
  return Api.get("/api/v1/auth/cancelReasonList", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

// Event Report Reason List
export const EventReportReasonList = () => {
  return Api.get("/api/v1/auth/reportReason", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

// Check Event Start Time
export const CheckEventStartTime = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("/api/v1/users/checkEventStartTime", payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

// Get Guest Event Data
export const GetGuestEventData = (eventId) => {
  return Api.get(`/api/v1/auth/getHyperLinkData/${eventId}`, {
    headers : {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

// Get Guest Event Data
export const GetGuestEventDataActorName = (payload) => {
  return Api.get(`/api/v1/auth/getHyperLinkDataNew/${payload.actorName}/${payload.eventName}`, {
    headers : {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

// Viewer check on Watch Now 
export const AddViewer = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post(`/api/v1/users/addViewer`, payload, {
    headers : {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

export const RemoveViewer = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post(`/api/v1/users/removeViewer`, payload, {
    headers : {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

export const GetViewerCount = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post(`/api/v1/users/getViewerCount`, payload, {
    headers : {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

export const GetChatMessages = (payload) => {
  return Api.get(`https://firestore.googleapis.com/v1/projects/livestage-4326e/databases/(default)/documents/chatrooms/60ed23a68861bf780cac6686/messages`, {
    headers : {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  });
}

export const GetCalculatedAmount = (eventId) => {
   const token = cookie('jwtTokenPetros');

  return Api.get(`/api/v1/users/getCalculatedAmt`, {
    headers : {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      eventId: eventId
    }
  })
}

export const GetTaxValue = () => {
  return Api.get(`/api/v1/auth/getTaxValue`, {
    headers : {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

export const LikeEvent = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post(`/api/v1/users/likeEvent`, payload, {
    headers : {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

// Pre-call Test Session
export const PreCallTest = () => {
  return Api.get("/api/v1/auth/preCallTest", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

// Check Create Event timings
export const CheckCreateEvent = (payload) => {
   const token = cookie('jwtTokenPetros');

  return Api.post("/api/v1/users/checkScheduleEventTime", payload, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
}

// check Company for Corporate Events
export const CheckCompany = () => {
   const token = cookie('jwtTokenPetros');

  return Api.get("/api/v1/users/checkActorDetails", {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
}

// Search Events
export const SearchEvent = (paramsPayload) => {
   const token = cookie('jwtTokenPetros');

  return Api.get("/api/v1/users/searchEvent", {
    headers: {
      "Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      searchString: paramsPayload?.searchString
    }
  })
}

// Get Upcomming Events List (Landing Page)

export const GetUpcommingEventsLandingPage = () => {

 return Api.get("/api/v1/auth/getUpcomingEventList", {
   headers: {
     "Content-Type": "application/json",
     "Access-Control-Allow-Origin": "*",
   }
 })
}
