import React, { useEffect, useState } from "react";
import iconCal from "../../../assets/img/icon-cal.svg";
import iconWatch from "../../../assets/img/icon-watch.svg";
import iconCard from "../../../assets/img/icon-card.svg";
import iconPlay from "../../../assets/img/play-button.png";
import { AddViewer, GetEventList } from "../../../redux/actions/events";
import dpPhoto from "../../../assets/img/img_avatar.png";
import IconLike from "../../../assets/img/icon-like.svg";
import copy from "copy-to-clipboard";
import ViewAll from "./viewAll";
import { useHistory } from "react-router-dom";
import { Notify } from "../../../helpers/notifications";
import { getEventLocalDate } from "../../../helpers/constants";
import { connect } from "react-redux";
import Store from "../../../redux/store/store";
import Logo from "../../loader/Loader";
import "firebase/firestore";
import firebase from "../../../helpers/firebase"

const db = firebase.firestore();

function Home({ setSelectedTab, setEventID, role, setViewAllClicked }) {
  const history = useHistory();
  const [isEventStart, setIsEventStart] = useState(false);

  const [eventsData, setEventsData] = useState([]);
  const [showViewAll, setShowViewAll] = useState(false);
  const [viewAllText, setViewAllText] = useState("");
  const [defaultEvent, setDefaultEvent] = useState({});
  const [videoMute, setVideoMute] = useState(true);
  let id = "";
  const [isFetched, setIsFetched] = useState(false);
  const { dispatch } = Store.store;

  useEffect(() => {
    document.title = "LiveStage | Home";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    GetEventList()
      .then((response) => {
        setEventsData([...response.data.data]);
        setDefaultEvent({ ...response.data.default });
        id = response.data.default._id;
        dispatch({
          type: "EVENTPREVIEWFILE",
          payload: {
            eventFile: response.data.default.eventImage,
            videoStatus: response.data.default.videoStatus,
          },
        });
        setIsFetched(true);
      })
      .catch((error) => {
        setIsFetched(false);
      });
  }, []);

  const handleViewAll = (catText) => {
    setViewAllClicked(true);
    if (catText === "allEvents") {
      setSelectedTab(2);
    } else {
      setViewAllText(catText);
      setShowViewAll(true);
    }
  };

  const handleBookEvent = () => {
    setEventID(defaultEvent._id);
    setSelectedTab(8);
  };
  useEffect(() => {
    GetEventList()
      .then((response) => {
        setEventsData([...response.data.data]);
        setDefaultEvent({ ...response.data.default });
        dispatch({
          type: "EVENTPREVIEWFILE",
          payload: {
            eventFile: response.data.default.eventImage,
            videoStatus: response.data.default.videoStatus,
          },
        });
        setIsFetched(true);
      })
      .catch((error) => {
        setIsFetched(false);
      });
  }, [isEventStart]);

  useEffect(() => {
    const interval = setInterval(() => {
      async function displayNotify() {
        try {
          db.collection('chatrooms')
            .doc(`${id}`)
            .collection('Viewers')
            .doc('eventStatus')
            .get()
            .then(async (checkEventStatus) => {
              setIsEventStart(checkEventStatus?.data()?.streaming);
            })
            .catch((error) => {
              console.log('Error in Get Live Count' + error);
            });
        } catch (error) {
          console.log(error);
        }
      }
      displayNotify();
    }, 5000);
    return () => clearInterval(interval);
  }, [])

  const handleViewer = async () => {
    let match = localStorage.getItem("petros-unique-id-view");
    let payload = {
      fcmToken: localStorage.getItem("fcmToken"),
      uniqueId: match,
      eventId: defaultEvent._id,
    };

    try {
      let response = await AddViewer(payload);

      if (response.status === 200) {
        localStorage.setItem(
          "uniqueTokenforViewing",
          `${response.data.data.fcmToken}`
        );
        localStorage.setItem(
          "petros-unique-id-view",
          response.data.data.uniqueId
        );
        history.push(`/streamEvent`, {
          eventId: defaultEvent._id, shareEventURL: defaultEvent.share_url, platform: defaultEvent.platform
        });
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  }

  return (
    <div>
      <section
        className="events-section enduser-section"
        style={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <div className="flex-container">
          {isFetched ? (
            showViewAll ? (
              <ViewAll
                text={viewAllText}
                setShowViewAll={setShowViewAll}
                setEventID={setEventID}
                setSelectedTab={setSelectedTab}
                setViewAllClicked={setViewAllClicked}
              />
            ) : (
              <>
                {Object.keys(defaultEvent).length !== 0 ?
                  <div className="event-details">
                    <h1>{defaultEvent.eventTitle}</h1>
                    <div className="content">
                      <div className="date-time">
                        <div className="flex-row">
                          <div>
                            <img src={iconCal} alt="User" />
                            {
                              getEventLocalDate(
                                defaultEvent.eventDate,
                                defaultEvent.eventTime,
                                defaultEvent.setTimeZone
                              ).split(",")[0]
                            }
                          </div>
                          <div>
                            <img src={iconWatch} alt="User" />
                            {
                              getEventLocalDate(
                                defaultEvent.eventDate,
                                defaultEvent.eventTime,
                                defaultEvent.setTimeZone
                              ).split(",")[1]
                            }
                          </div>
                        </div>
                        <div className="flex-row">
                          <div>Event ID: #{defaultEvent.randomEventId}</div>
                          <div>
                            <img src={iconCard} alt="User" /> €{" "}
                            {defaultEvent.earlyBirdTicket > 0
                              ? parseFloat(
                                defaultEvent.newEarlyBirdPrice
                              ).toFixed(2)
                              : parseFloat(
                                defaultEvent.newPrice
                              ).toFixed(2)}
                          </div>
                        </div>

                        <div className="flex-row">
                          {defaultEvent?.earlyBirdTicket > 0 && <div>
                            EarlyBird Tickets Available:{" "}
                            {defaultEvent?.earlyBirdTicket}
                          </div>}
                          <div>
                            Tickets Available :{" "}
                            {defaultEvent.ticketType === "Unlimited" ||
                              defaultEvent.audienceType === "Corporate"
                              ? "Available"
                              : defaultEvent?.availableTickets}
                          </div>
                        </div>
                      </div>

                      <p className="cat">Category: {defaultEvent.eventCategory}</p>
                      <p className="cat">
                        Duration:{" "}
                        {parseInt(parseInt(defaultEvent?.eventDuration) / 60) >=
                          1 &&
                          `${parseInt(
                            parseInt(defaultEvent?.eventDuration) / 60
                          )} hours`}{" "}
                        {parseInt(parseInt(defaultEvent?.eventDuration) % 60) > 0 &&
                          `${parseInt(
                            parseInt(defaultEvent?.eventDuration) % 60
                          )} minutes`}
                      </p>
                      <p className="desc">
                        <strong>Event Description:</strong>
                      </p>
                      <div className="description-scroll">
                        <p style={{ whiteSpace: "pre-line" }}>
                          {defaultEvent?.description}
                        </p>
                      </div>
                    </div>
                    {defaultEvent.alreadyBooked && role === "Corporate" ? (
                      ((defaultEvent.platform == 'easyStream' && defaultEvent.isSessionStart) || (defaultEvent.platform == 'rtmp' && defaultEvent.mediaLive_stream_start
                        && !defaultEvent.mediaLive_stream_end)) &&
                      <button
                        className="primary-button mLeft"
                        type="button"
                        onClick={() => handleViewer()}
                      >
                        Watch Now
                      </button>
                    ) : (
                      <>
                        {(defaultEvent.eventEndTime > +new Date() / 1000) ? (defaultEvent.availableTickets > 0 ? <button
                          className="primary-button"
                          type="button"
                          onClick={() => { handleBookEvent(); }}
                        >
                          Book Now
                        </button> : "No Tickets Available") : ""}

                        {defaultEvent.alreadyBooked && ((defaultEvent.platform == "easyStream" && defaultEvent.isSessionStart) || (defaultEvent.platform == "rtmp" && defaultEvent.mediaLive_stream_start && !defaultEvent.mediaLive_stream_end)) &&
                          <button
                            className="primary-button mLeft"
                            type="button"
                            onClick={() => handleViewer()}
                          >
                            Watch Now
                          </button>
                        }
                      </>
                    )}

                    <div className="banner-wrap">
                      <div
                        className="content banner-content-box"
                        style={{ width: "100%" }}
                      >
                        <div className="enduser-artist">
                          <h4>Artist</h4>
                          <div className="row-content">
                            <div
                              style={{
                                height: "200px",
                                width: "65%",
                                backgroundImage: `url(${defaultEvent?.userId?.profilePhoto
                                  ? defaultEvent.userId?.profilePhoto
                                  : dpPhoto
                                  })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                                borderRadius: "5px",
                                marginRight: "10px",
                              }}
                            ></div>
                            <div className="content">
                              <h5>
                                {defaultEvent?.userId?.firstName}{" "}
                                {defaultEvent?.userId?.lastName}
                              </h5>
                              <h6>{defaultEvent?.userId?.category}</h6>
                              {defaultEvent?.userId?.rateCount > 0 && <div className="rating">
                                <span
                                  className="like-con-book"
                                  style={{ marginBottom: "0px", cursor: "auto" }}
                                >
                                  <img src={IconLike} alt="" />
                                  {defaultEvent.artistRating} %
                                </span>
                              </div>}
                              <div className="description-scroll">
                                <p
                                  className="p-box"
                                  style={{
                                    maxWidth: "500px",
                                    whiteSpace: "pre-line",
                                  }}
                                >
                                  {defaultEvent?.userId?.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  : <div className="event-details" style={{ textAlign: "center" }}><h2>There are no events for now</h2></div>}
                {eventsData?.map((catData, index) => (
                  <div className="events-listing enduser-events" key={index}>
                    <div className="title">
                      <h2>
                        {catData.category === "liveData"
                          ? "Events Live Now"
                          : catData.category === "trendingData"
                            ? "Trending Events"
                            : catData.category === "allEvents"
                              ? "All Events"
                              : catData.category === "pastEvents"
                                ? "Past Events"
                                : catData.category}
                      </h2>
                      <a
                        className="viewall cursorPointer"
                        onClick={() => handleViewAll(catData.category)}
                      >
                        View All
                      </a>
                    </div>

                    <div className="flex-row gutter-space">
                      {catData.data.length > 0 ? (
                        catData.data.map((eventItem, i) => (
                          <div className="loop flex-col-4" key={i}>
                            <div
                              className="grey-wrap item cursorPointer"
                              onClick={() => {
                                setEventID(eventItem._id);
                                setSelectedTab(7);
                              }}
                            >
                              <div className="img">
                                <div
                                  onMouseOver={(e) => {
                                    e.target.style.backgroundImage = `url(${eventItem.videoStatus ? eventItem.gifURL : eventItem.eventImage})`
                                  }}
                                  onMouseOut={(e) => {
                                    e.target.style.backgroundImage = `url(${eventItem.videoStatus
                                      ? eventItem.thumbnailImage
                                      : eventItem.eventImage})`
                                  }}
                                  style={{
                                    minHeight: "150px",
                                    width: "100%",
                                    backgroundImage: `url(${eventItem.videoStatus
                                      ? eventItem.thumbnailImage
                                      : eventItem.eventImage
                                      })`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                  }}
                                >
                                </div>
                                {eventItem.videoStatus && <div style={{
                                  position: "absolute",
                                  top: "155px"
                                }}><img src={iconPlay} style={{ width: "40px" }} /></div>}
                              </div>
                              <div className="details">
                                <div className="name">
                                  <h3 title={eventItem.eventTitle}>
                                    {eventItem.eventTitle.length > 19
                                      ? eventItem.eventTitle.slice(0, 19) + "..."
                                      : eventItem.eventTitle}
                                  </h3>
                                  <span>
                                    €
                                    {eventItem.earlyBirdTicket > 0
                                      ? parseFloat(
                                        eventItem.newEarlyBirdPrice
                                      ).toFixed(2)
                                      : parseFloat(
                                        eventItem.newPrice
                                      ).toFixed(2)}
                                  </span>
                                </div>
                                <div className="ticket">{(`${eventItem.userId.firstName} ${eventItem.userId.lastName}`).length > 28 ?
                                  (`${eventItem.userId.firstName} ${eventItem.userId.lastName}`).slice(0, 28) + "..." : `${eventItem.userId.firstName} ${eventItem.userId.lastName}`}</div>
                                {eventItem.earlyBirdTicket > 0 && (
                                  <div className="ticket">
                                    Earlybird Tickets Available
                                    <span>{eventItem.earlyBirdTicket}</span>
                                  </div>
                                )}
                                <div className="ticket">
                                  Tickets Available
                                  <span>
                                    {eventItem.ticketType === "Unlimited" ||
                                      eventItem.audienceType === "Corporate"
                                      ? "Available"
                                      : eventItem?.availableTickets}
                                  </span>
                                </div>
                                <div className="date-time">
                                  {getEventLocalDate(
                                    eventItem.eventDate,
                                    eventItem.eventTime,
                                    eventItem.setTimeZone
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>
                          {catData.category === "liveData"
                            ? "There are no events live now"
                            : "No Events Present"}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )) : <Logo />}
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData.role,
  };
};

export default connect(mapStateToProps, {})(Home);