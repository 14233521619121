import React, { useEffect, useState } from "react";
import { GetVouchers } from "../../redux/actions/user";
import moment from "moment";

function Vouchers({ setTab, setSelectedTab }) {
  const [voucherList, setVoucherList] = useState([]);

  useEffect(() => {
    GetVouchers()
      .then((response) => {
        setVoucherList([...response.data.data]);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="events-section">
      <div className="flex-container">
        <div className="breadcrumbs">
          <ul>
            <li>
              <a className="cursorPointer" onClick={() => setSelectedTab(1)}>
                Home
              </a>{" "}
              <span>&#62;</span>{" "}
            </li>
            <li>
              <a className="cursorPointer" onClick={() => setTab(1)}>
                My Account
              </a>{" "}
              <span>&#62;</span>{" "}
            </li>
            <li>My Vouchers</li>
          </ul>
        </div>

        <div className="events-listing enduser-events">
          <div className="title chng-align">
            <h2>My Vouchers</h2>
          </div>
          {voucherList.length > 0 ? (
            <div className="flex-row gutter-space">
              {voucherList?.map((voucher, index) => (
                <div className="loop flex-col-4" key={index}>
                  <div className="grey-wrap item">
                    <div className="img">
                      <div
                        style={{
                          minHeight: "200px",
                          width: "100%",
                          backgroundImage: `url(${voucher.my_voucher.voucherImage})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      ></div>
                    </div>
                    <div className="details">
                      <div className="name">
                        <h3>{voucher.my_voucher.voucherName}</h3>
                        <span>€{voucher.my_voucher.voucherAmount}</span>
                      </div>
                      <div className="date-time">
                        {voucher.isRedeemed
                          ? `Applied on ${moment(
                            voucher.appliedOn.split("T")[0]
                          ).format("MMM D YYYY")}`
                          : `Expiring on ${moment(
                            voucher.my_voucher.expiryDate.split("T")[0]
                          ).format("MMM D YYYY")}`}
                      </div>
                      <div className="code-wrapper">
                        <div className="code-col">Code:</div>

                        <div className="code-col">
                          {voucher.voucherSerialCode}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="grey-wrap item">No Vouchers Found</div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Vouchers;