import HomeTabs from "../components/user/homePage/HomeTabs";
import HomeTabsActor from "../components/actor/actorHomeTabs";
import ForgotPassword from "../components/forgotPassword/forgotPassword";
import LandingPage from "../components/landingPage/LandingPage";
import Login from "../components/login/Login";
import ResetPassword from "../components/resetPassword/resetPassword";
import ActorSignup from "../components/signup/ActorSignup";
import UserSignup from "../components/signup/UserSignup";
import VerifyEmail from "../components/verifyEmail/VerifyEmail";
import ChangeEmail from "../components/changeEmail/ChangeEmail";
import ChangePassword from "../components/changePassword/ChangePassword";
import Stream from "../components/streams/Stream";
import NotFound from "../components/notFound/index";
import Payments from "../components/payments/Payments";
import Faq from "../components/miscellaneous/faq";
import Terms from "../components/miscellaneous/terms";
import Cookies from "../components/miscellaneous/cookies";
import HelpCentre from "../components/miscellaneous/helpCentre";
import Privacy from "../components/miscellaneous/privacy";
import EventInfo from "../components/miscellaneous/eventInfo";
import HowToGoLive from "../components/miscellaneous/howToGoLive";
import ShowEvent from "../components/guest/showEvent";
import termsCond from "../components/miscellaneous/termsCond";

const routes = [
  {
    path: "/",
    title: "",
    exact: true,
    protected: false,
    component: LandingPage,
  },
  {
    path: "/login/:token?",
    title: "Login",
    exact: true,
    protected: false,
    component: Login,
  },
  {
    path: "/signup/user/:mail?",
    title: "SignUp",
    exact: true,
    protected: false,
    component: UserSignup,
  },
  {
    path: "/signup/actor",
    title: "SignUp",
    exact: true,
    protected: false,
    component: ActorSignup,
  },
  {
    path: "/forgotPassword",
    title: "Forgot Password",
    exact: true,
    protected: false,
    component: ForgotPassword,
  },
  {
    path: "/verifyEmail",
    title: "",
    exact: false,
    protected: false,
    component: VerifyEmail,
  },
  {
    path: "/resetPassword/:token",
    title: "",
    exact: true,
    protected: false,
    component: ResetPassword,
  },
  {
    path: "/home/user",
    title: "",
    exact: false,
    protected: true,
    component: HomeTabs,
  },
  {
    path: "/home/actor",
    title: "",
    exact: false,
    protected: true,
    component: HomeTabsActor,
  },
  {
    path: "/changeEmail",
    title: "",
    exact: true,
    protected: true,
    component: ChangeEmail,
  },
  {
    path: "/changePassword",
    title: "",
    exact: true,
    protected: true,
    component: ChangePassword,
  },
  {
    path: "/streamEvent",
    title: "",
    exact: true,
    protected: true,
    component: Stream,
  },
  {
    path: "/makePayment",
    title: "",
    exact: true,
    protected: true,
    component: Payments,
  },
  {
    path: "/faq",
    title: "",
    exact: true,
    protected: false,
    component: Faq,
  },
  {
    path: "/termsOfUse",
    title: "",
    exact: true,
    protected: false,
    component: Terms,
  },
  {
    path: "/termsAndConditions",
    title: "",
    exact: true,
    protected: false,
    component: termsCond,
  },
  {
    path: "/cookiePreference",
    title: "",
    exact: true,
    protected: false,
    component: Cookies,
  },
  {
    path: "/helpCentre",
    title: "",
    exact: true,
    protected: false,
    component: HelpCentre,
  },
  {
    path: "/privacy",
    title: "",
    exact: true,
    protected: false,
    component: Privacy,
  },
  {
    path: "/eventInformation",
    title: "",
    exact: true,
    protected: false,
    component: EventInfo,
  },
  {
    path: "/howToGoLive",
    title: "",
    exact: true,
    protected: true,
    component: HowToGoLive,
  }, {
    path: "/public/showEvent/:eventID",
    title: "",
    exact: true,
    protected: false,
    component: ShowEvent,
  }, {
    path: "/public/showEvent/:actorName/:eventName",
    title: "",
    exact: true,
    protected: false,
    component: ShowEvent,
  },
  {
    path: "*",
    title: "",
    exact: false,
    protected: false,
    component: NotFound,
  },
];

export default routes;