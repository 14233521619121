import React from "react";
import { withRouter } from "react-router-dom";

const Progress = ({ stepper, streamType, setStepper }) => {
  return (
    <React.Fragment>
      <div className="steps">
        <div
          className={stepper == 1 ? "active step" : "step"}
          style={{ color: stepper === 1 ? "red" : "red" }}
        >
          <div
            style={{
              width: "12px",
              height: 11,
              backgroundColor: stepper === 1 ? "red" : "red",
              borderRadius: 50,
              marginLeft: '50px',
              marginBottom: '10px',
              marginTop: "21px"
            }}
          ></div>
          <div style={{ marginLeft: "30px", width: '70px' }}>Step 1</div>
        </div>
        <div
          style={{ width: "80%", height: "2px", backgroundColor: "grey", marginTop: "25px", marginRight: "15px" }}
        ></div>
        <div
          className={stepper == 2 ? "active step" : "step"}
          style={{ color: stepper === 2 || stepper === 3 ? "red" : "grey" }}
        >
          <div
            style={{
              width: "12px",
              height: 11,
              backgroundColor: stepper === 2 || stepper === 3 ? "red" : "grey",
              borderRadius: 50,
              marginLeft: '25px',
              marginTop: "21px",
              marginBottom: '10px',
            }}
          ></div>

          <div style={{ width: '60px', marginRight: "2px" }}>Step 2</div>
        </div>
        {streamType === "rtmp" && (
          <div
            style={{ width: "80%", height: "2px", backgroundColor: "gray", marginTop: "25px", marginRight: "25px" }}
          ></div>
        )}
        {streamType == "rtmp" ? (
          <div
            className={stepper == 2 ? "active step" : "step"}
            style={{ color: stepper === 2 ? "grey" : "red" || stepper == 3 ? "red" : "grey" && stepper == 1 ? "gray" : 'red' }}
          >
            <div
              style={{
                width: "12px",
                height: 11,
                backgroundColor: stepper == 2 ? "grey" : "red" || stepper == 3 ? "red" : "grey",
                borderRadius: 50,
                marginLeft: '12px',
                marginBottom: '10px',
                marginTop: "21px"
              }}
            ></div>
            <div>
              <div style={{ width: '70px', marginRight: "21px" }}>Step 3</div>
            </div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default withRouter(Progress);