import iconVisa from "../assets/img/icon-visa.png";
import iconCard from "../assets/img/icon-card.svg";
import masterCard from "../assets/img/img-mastercard.svg";
import iconMaestro from "../assets/img/icons-maestro.png";
import dinersClubIcon from "../assets/img/icons-diners-club.png";
import jcbIcon from "../assets/img/icons-jcb.png";
import paypalIcon from "../assets/img/icons-paypal.png";
import stripeIcon from "../assets/img/stripe-icon.png";
import unionPayIcon from "../assets/img/uinon-pay-icon.png";
import moment from "moment-timezone";
const localTZone = moment.tz.guess();

export const clientId = "536534825979-8emq788ct5ciimedbc6gfi311eersi35.apps.googleusercontent.com"

export const appId = "554251812221586"; // Facebook AppId

export const monthDropdown = [
  {
    text: "January",
    id: "01",
  },
  {
    text: "February",
    id: "02",
  },
  {
    text: "March",
    id: "03",
  },
  {
    text: "April",
    id: "04",
  },
  {
    text: "May",
    id: "05",
  },
  {
    text: "June",
    id: "06",
  },
  {
    text: "July",
    id: "07",
  },
  {
    text: "August",
    id: "08",
  },
  {
    text: "September",
    id: "09",
  },
  {
    text: "October",
    id: "10",
  },
  {
    text: "November",
    id: "11",
  },
  {
    text: "December",
    id: "12",
  },
];

export const yearDropdown = [
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
];

export const cardImages = {
  visa: iconVisa,
  mastercard: masterCard,
  maestro: iconMaestro,
  "diners-club": dinersClubIcon,
  jcb: jcbIcon,
  paypal: paypalIcon,
  stripe: stripeIcon,
  unionpay: unionPayIcon,
  default: iconCard,
};

export const getEventLocalDate = (Edate, Etime, tZone) => {
  if (Edate?.includes("/")) {
    let fullDate = Edate.split("/");
    let year = fullDate[2];
    let month = fullDate[0];
    let date = fullDate[1];
    let finalDate = `${year}-${month}-${date}`;

    let fullTime = Etime.split(":").join(" ").split(" ");
    let hours = fullTime[0];
    if (fullTime[2] == "PM" && fullTime[0] !== "12") {
      hours = (parseInt(fullTime) + 12).toString();
    }

    let finalTime = `${hours}:${fullTime[1]}`;
    let a = moment.tz(`${finalDate} ${finalTime}`, `${tZone}`);
    let b = moment.tz(a, `${localTZone}`);
    return b.format("MMM D YYYY, h:mm A");
  } else {
    let a = moment.tz(`${Edate} ${Etime}`, `${tZone}`);
    let b = moment.tz(a, `${localTZone}`);
    return b.format("MMM D YYYY, h:mm A");
  }
};

export const getCalculatedSellingPrice = (Com, Vat, SerFee, price) => {
  let newFilterPrice = ((price / (100 - Com) * 100) + SerFee) * (1 + Vat / 100);
  return parseFloat(newFilterPrice).toFixed(2);
};

export const getCalculatedReversePrice = (Com, Vat, SerFee, price) => {
  let reversePrice = ((((price / (1 + Vat / 100)) - SerFee) / 100) * (100 - Com));
  return parseFloat(reversePrice).toFixed(2);
}

export const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million
  } else if (num < 1000) {
    return num; // if value < 1000, nothing to do
  }
};