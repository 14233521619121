import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import routes from "./page-routes";

function Content() {
  const { pathname } = useLocation();
  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      {routes.map((route, index) =>
        route.protected ? (
          <ProtectedRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ) : (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        )
      )}
    </Switch>
  );
}

export default Content;