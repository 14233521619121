import React, { useEffect, useState } from "react";
import ViewAllBookings from "./viewAllBookings";
import { GetMyBookings } from "../../../redux/actions/events";
import { getEventLocalDate } from "../../../helpers/constants";
import iconPlay from "../../../assets/img/play-button.png";
import API from "../../../redux/api/api";
import DownloadReceipt from "./downloadReceipt";

function MyBookings({ setSelectedTab, setEventID, setBookingID }) {
  const [myBookingUpcomming, setMyBookingUpcomming] = useState([]);
  const [myBookingPast, setMyBookingPast] = useState([]);
  const [showViewAll, setShowViewAll] = useState(false);
  const [viewAllText, setViewAllText] = useState("");

  useEffect(() => {
    document.title = "LiveStage | My Bookings"
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    localStorage.removeItem("testRemoveViewer");
  }, []);

  useEffect(() => {
    GetMyBookings({ type: 1 })
      .then((response) => {
        setMyBookingPast([...response.data.data?.bookingDetails]);
      })
      .catch((error) => {
        console.log(error);
      });

    GetMyBookings({ type: 2 })
      .then((response) => {
        setMyBookingUpcomming([...response.data.data?.bookingDetails]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleViewAll = (catText) => {
    setViewAllText(catText);
    setShowViewAll(true);
  };

  const downloadReceipt = async (receipt) => {
    DownloadReceipt(receipt);
  };

  return (
    <div>
      <section className="events-section">
        <div className="flex-container">
          {showViewAll ? (
            <ViewAllBookings
              text={viewAllText}
              setShowViewAll={setShowViewAll}
              setEventID={setEventID}
              setSelectedTab={setSelectedTab}
              setBookingID={setBookingID}
            />
          ) : (
            <>
              <div className="events-listing">
                <div className="title">
                  <h2>Upcoming Events</h2>
                  <a
                    className="viewall cursorPointer"
                    onClick={() => handleViewAll("upCommingData")}
                  >
                    View All
                  </a>
                </div>

                {myBookingUpcomming?.length > 0 ? (
                  myBookingUpcomming?.map(
                    (eventItem, index) =>
                      index < 2 && (
                        <div className="loop grey-wrap item" key={index}>
                          <div
                            className="flex-row cursorPointer"
                            onClick={() => {
                              setEventID(eventItem.eventId._id);
                              setBookingID(eventItem._id);
                              setSelectedTab(9);
                            }}
                          >
                            <div className="col-img">
                              <div
                                className="media766"
                                onMouseOver={(e) => {
                                  e.target.style.backgroundImage = `url(${eventItem.eventId.videoStatus ? eventItem.eventId.gifURL : eventItem.eventId.eventImage})`
                                }}
                                onMouseOut={(e) => {
                                  e.target.style.backgroundImage = `url(${eventItem.eventId.videoStatus
                                    ? eventItem.eventId.thumbnailImage
                                    : eventItem.eventId.eventImage})`
                                }}
                                style={{
                                  minHeight: "100%",
                                  width: "100%",
                                  verticalAlign: "bottom",
                                  backgroundImage: `url(${eventItem.eventId.videoStatus
                                    ? eventItem.eventId.thumbnailImage
                                    : eventItem.eventId.eventImage
                                    })`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                }}
                              ></div>
                              {eventItem.eventId.videoStatus && <div style={{
                                position: "absolute",
                                bottom: "16px"
                              }}><img src={iconPlay} style={{ width: "40px" }} /></div>}
                            </div>
                            <div className="col-content">
                              <div className="details">
                                <h3
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {eventItem.eventId.eventTitle}{" "}
                                  {eventItem.eventId.isCancelled && (
                                    <span style={{ color: "#e50914" }}>
                                      Event Cancelled
                                    </span>
                                  )}
                                </h3>
                                <div
                                  className="description-scroll"
                                  style={{ maxHeight: "100px" }}
                                >
                                  <p style={{ whiteSpace: "pre-line" }}>
                                    {eventItem.eventId.description}
                                  </p>
                                </div>
                                <div className="other-detail">
                                  <div className="flex-row">
                                    Number of tickets{" "}
                                    <span>{eventItem.totalTickets}</span>
                                  </div>
                                  <div className="flex-row">
                                    Price <span>€{eventItem.totalPrice}</span>
                                  </div>
                                  <div className="flex-row">
                                    Date and Time{" "}
                                    <span>
                                      {getEventLocalDate(
                                        eventItem.eventId.eventDate,
                                        eventItem.eventId.eventTime,
                                        eventItem.eventId.setTimeZone
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ textAlign: "center", padding: "10px" }}>
                            <a
                              className="download cursorPointer"
                              // href={eventItem.receipt}
                              download="Receipt"
                              target="_blank"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default action (opening the link)
                                downloadReceipt(eventItem.receipt); // Call your custom function
                              }}
                            >
                              Download Ticket Receipt
                            </a>
                          </div>
                        </div>
                      )
                  )
                ) : (
                  <div>No Bookings Found</div>
                )}
              </div>

              <div className="events-listing">
                <div className="title">
                  <h2>Past Events</h2>
                  <a
                    className="viewall cursorPointer"
                    onClick={() => handleViewAll("pastData")}
                  >
                    View All
                  </a>
                </div>

                {myBookingPast?.length > 0 ? (
                  myBookingPast?.map(
                    (eventItem, index) =>
                      index < 2 && (
                        <div className="loop grey-wrap item" key={index}>
                          <div
                            className="flex-row cursorPointer"
                            onClick={() => {
                              setEventID(eventItem.eventId._id);
                              setBookingID(eventItem._id);
                              setSelectedTab(9);
                            }}
                          >
                            <div className="col-img">
                              <div
                                className="media766"
                                onMouseOver={(e) => {
                                  e.target.style.backgroundImage = `url(${eventItem.eventId.videoStatus ? eventItem.eventId.gifURL : eventItem.eventId.eventImage})`
                                }}
                                onMouseOut={(e) => {
                                  e.target.style.backgroundImage = `url(${eventItem.eventId.videoStatus
                                    ? eventItem.eventId.thumbnailImage
                                    : eventItem.eventId.eventImage})`
                                }}
                                style={{
                                  minHeight: "100%",
                                  width: "100%",
                                  verticalAlign: "bottom",
                                  backgroundImage: `url(${eventItem.eventId.videoStatus
                                    ? eventItem.eventId.thumbnailImage
                                    : eventItem.eventId.eventImage
                                    })`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                }}
                              ></div>
                              {eventItem.eventId.videoStatus && <div style={{
                                position: "absolute",
                                bottom: "16px"
                              }}><img src={iconPlay} style={{ width: "40px" }} /></div>}
                            </div>
                            <div className="col-content">
                              <div className="details">
                                <h3
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {eventItem.eventId.eventTitle}{" "}
                                  {eventItem.eventId.isCancelled && <span style={{ color: "#e50914" }}>
                                    Event Cancelled
                                  </span>}
                                </h3>
                                <div
                                  className="description-scroll"
                                  style={{ maxHeight: "100px" }}
                                >
                                  <p style={{ whiteSpace: "pre-line" }}>
                                    {eventItem.eventId.description}
                                  </p>
                                </div>
                                <div className="other-detail">
                                  <div className="flex-row">
                                    Number of tickets{" "}
                                    <span>{eventItem.totalTickets}</span>
                                  </div>
                                  <div className="flex-row">
                                    Price <span>€{eventItem.totalPrice}</span>
                                  </div>
                                  <div className="flex-row">
                                    Date and Time{" "}
                                    <span>
                                      {getEventLocalDate(
                                        eventItem.eventId.eventDate,
                                        eventItem.eventId.eventTime,
                                        eventItem.eventId.setTimeZone
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ textAlign: "center", padding: "10px" }}>
                            <a
                              className="download cursorPointer"
                              // href='#'
                              download="Receipt"
                              target="_blank"
                              onClick={(e) => {
                                e.preventDefault(); // Prevent the default action (opening the link)
                                downloadReceipt(eventItem.receipt); // Call your custom function
                              }}
                            >
                              Download Ticket Receipt
                            </a>
                          </div>
                        </div>
                      )
                  )
                ) : (
                  <div>No Bookings Found</div>
                )}
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
}

export default MyBookings;