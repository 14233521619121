import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Bar } from "react-chartjs-2";
import EventDetails from "./events/eventDetails";
import ShareEvent from "./shareEvent";
import { GetAmountEarnedData } from "../../redux/actions/events";
import ViewAll from "./viewAll";
import IconLike from "../../assets/img/icon-like.svg";
import { GetMyEvents } from "../../redux/actions/events";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Notify } from "../../helpers/notifications";
import { getEventLocalDate } from "../../helpers/constants";
import iconPlay from "../../assets/img/play-button.png";

function Home({ myEventsData, setSelectedTab, GetMyEvents, flag, notifEID }) {
  const testDate = new Date();
  let notiEventID = notifEID;
  const [showEventDetails, setShowEventDetails] = useState(
    notiEventID ? true : false
  );
  const [eventIndex, setEventIndex] = useState("");
  const [eventType, setEventType] = useState("");
  const [shareDialog, setShareDialog] = useState({});
  const [graphData, setGraphData] = useState({
    labels: [],
    data: [],
  });
  const [showViewAll, setShowViewAll] = useState(false);
  const [viewAllText, setViewAllText] = useState("");
  const [startDate, setStartDate] = useState(new Date(testDate.getFullYear(), testDate.getMonth() - 2));
  const [endDate, setEndDate] = useState(new Date(testDate.getFullYear(), testDate.getMonth() + 1, 0));
  const initialRender = useRef(true);

  useEffect(() => {
    document.title = "LiveStage | Actor - Home";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    notiEventID && setShowEventDetails(true);
  }, [notiEventID])

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setShowEventDetails(false);
      setShowViewAll(false);
    }
  }, [flag]);

  useEffect(() => {
    let upCommingData = myEventsData?.upCommingData;
    let pastData = myEventsData?.pastData;

    upCommingData = upCommingData?.map((obj) => {
      return { open: false };
    });

    pastData = pastData?.map((obj) => {
      return { open: false };
    });

    setShareDialog({ upCommingData, pastData });
  }, [myEventsData]);

  useEffect(() => {
    if (+startDate <= +endDate) {
      GetAmountEarnedData({
        startDate: parseInt(+startDate / 1000),
        endDate: parseInt(+endDate / 1000),
      })
        .then((response) => {
          setGraphData({
            ...graphData,
            labels: [...response.data.labels],
            data: [...response.data.datasets[0].data],
            tipAmt: [...response.data.tipAmountArr],
            totalEarning: [...response.data.totalEarning],
            soldTickets: [...response.data.soldTickets],
          });
        })
        .catch((error) => console.log(error));
    } else {
      Notify("warning", "Please select a Valid date range");
    }
  }, [startDate, endDate]);

  const isFetched = (response) => {
    console.log("Data fetched", response.status);
  };

  useEffect(() => {
    GetMyEvents(isFetched);
  }, [showEventDetails]);

  const handleViewEventDetails = (index, type) => {
    setEventIndex(index);
    setEventType(type);
    setShowEventDetails(true);
  };

  const handleOpenShare = (index, text) => {
    let newArray = shareDialog[text].map((item, i) =>
      i === index
        ? {
          ...item,
          open: !item.open,
        }
        : {
          ...item,
          open: false,
        }
    );

    if (text === "pastData") {
      setShareDialog({
        upCommingData: shareDialog["upCommingData"],
        pastData: newArray,
      });
    } else {
      setShareDialog({
        upCommingData: newArray,
        pastData: shareDialog["pastData"],
      });
    }
  };

  const handleViewAll = (catText) => {
    setViewAllText(catText);
    setShowViewAll(true);
  };

  /////// GRAPH DATA //////
  const dataLine = {
    labels: [...graphData?.labels],
    datasets: [
      {
        label: "",
        data: [...graphData?.data],
        backgroundColor: "#d63031",
      },
    ],
  };

  const optionsLine = {
    responsive: true,
    maintainAspectRatio: true,
    title: {
      display: false,
      text: "Line Chart",
      fontColor: "#fff",
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            offset: false,
            offsetGridLines: false,
            display: true,
            color: "#fff",
            zeroLineColor: "#fff",
          },
          ticks: {
            fontColor: "#fff",
            fontSize: 12,
          },
          scaleLabel: {
            display: true,
            labelString: "Amount Earned (€)",
            fontColor: "#fff",
            fontSize: 16,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            offset: true,
            offsetGridLines: true,
            display: false,
            color: "#fff",
          },
          ticks: {
            fontColor: "#fff",
          },
          scaleLabel: {
            display: true,
            labelString: "Event Name",
            fontColor: "#fff",
            fontSize: 16,
          },
          barPercentage: 0.5,
        },
      ],
    },
    legend: {
      position: "top",
      align: "center",
      fontColor: "#fff",
      display: false,
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem) {
          return [tooltipItem[0].xLabel];
        },
        label: function (tooltipItem) {
          return [`Total earned amount: ${tooltipItem.yLabel}`];
        },
        beforeLabel: function (tooltipItem) {
          return [
            `Earned Amount (from tickets): ${graphData.totalEarning[tooltipItem.index]
            }`,
            `Sold Tickets: ${graphData.soldTickets[tooltipItem.index]}`,
            `Tip Amount: ${graphData.tipAmt[tooltipItem.index]}`,
          ];
        },
      },
    },
  };

  // handle Date Range
  const handleDateRange = (text, e) => {
    if (text === "start") {
      setStartDate(new Date(e.getFullYear(), e.getMonth()));
    } else {
      setEndDate(new Date(e.getFullYear(), e.getMonth() + 1, 0));
    }
  };

  return (
    <div>
      {showEventDetails ? (
        <EventDetails
          setShowEventDetails={setShowEventDetails}
          setSelectedTab={setSelectedTab}
          eventIndex={eventIndex}
          eventType={eventType}
        />
      ) : (
        <>
          {showViewAll ? (
            <ViewAll
              text={viewAllText}
              setShowViewAll={setShowViewAll}
              from={"Home"}
              setShowEventDetails={setShowEventDetails}
              setEventIndex={setEventIndex}
              setEventType={setEventType}
            />
          ) : (
            <div>
              <section className="graph-section">
                <div className="flex-container">
                  <div className="filter-bar">
                    <div className="monthwise">
                      <div className="custom-select">
                        <div className="datepicker-react" id="date1">
                          <DatePicker
                            selected={startDate}
                            placeholderText="Select Month"
                            maxDate={
                              new Date(
                                testDate.getFullYear(),
                                testDate.getMonth()
                              )
                            }
                            onChange={(e) => handleDateRange("start", e)}
                            className="datepicker-react-input"
                            selectsStart
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                          />
                        </div>
                      </div>

                      <div className="custom-select">
                        <div className="datepicker-react" id="date2">
                          <DatePicker
                            selected={endDate}
                            placeholderText="Select Month"
                            maxDate={
                              new Date(
                                testDate.getFullYear(),
                                testDate.getMonth() + 1
                              )
                            }
                            onChange={(e) => handleDateRange("end", e)}
                            className="datepicker-react-input"
                            selectsEnd
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="grey-wrap event-graph"
                    style={{ position: "relative" }}
                  >
                    {graphData.data.length < 1 && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "30%",
                        }}
                      >
                        Your earnings will show here once your events will be
                        completed.
                      </div>
                    )}
                    <Bar data={dataLine} options={optionsLine} />
                  </div>
                </div>
              </section>

              <section className="events-section">
                <div className="flex-container">
                  <div className="events-listing">
                    <div className="title">
                      <h2>Upcoming Events</h2>
                      <a
                        className="viewall cursorPointer"
                        onClick={() => handleViewAll("upCommingData")}
                      >
                        View All
                      </a>
                    </div>

                    {myEventsData?.upCommingData?.length > 0 ? (
                      myEventsData?.upCommingData?.map(
                        (events, index) =>
                          index < 2 && (
                            <div className="loop grey-wrap item" key={index}>
                              <div
                                className="flex-row cursorPointer"
                                onClick={() =>
                                  handleViewEventDetails(index, "upCommingData")
                                }
                              >
                                <div className="col-img">
                                  <div
                                    onMouseOver={(e) => {
                                      e.target.style.backgroundImage = `url(${events.videoStatus ? events.gifURL : events.eventImage})`
                                    }}
                                    onMouseOut={(e) => {
                                      e.target.style.backgroundImage = `url(${events.videoStatus
                                        ? events.thumbnailImage
                                        : events.eventImage})`
                                    }}
                                    style={{
                                      minHeight: "250px",
                                      width: "100%",
                                      backgroundImage: `url(${events.videoStatus
                                        ? events.thumbnailImage
                                        : events.eventImage
                                        })`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  ></div>
                                  {events.videoStatus && <div style={{
                                    position: "absolute",
                                    bottom: "20px"
                                  }}><img src={iconPlay} style={{ width: "40px" }} /></div>}
                                </div>
                                <div className="col-content">
                                  <div className="details">
                                    <h3
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {events.eventTitle}{" "}
                                      {events.isCancelled && (
                                        <span style={{ color: "#e50914" }}>
                                          Event Cancelled
                                        </span>
                                      )}
                                    </h3>
                                    <div
                                      className="description-scroll"
                                      style={{ maxHeight: "100px" }}
                                    >
                                      <p style={{ whiteSpace: "pre-line" }}>
                                        {events.description}
                                      </p>
                                    </div>
                                    <div className="other-detail">
                                      <div className="flex-row">
                                        Total booked tickets{" "}
                                        <span>{events.soldTickets}</span>
                                      </div>
                                      <div className="flex-row">
                                        Date and Time{" "}
                                        <span>
                                          {getEventLocalDate(
                                            events.eventDate,
                                            events.eventTime,
                                            events.setTimeZone
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex-row">
                                        Audience Type{" "}
                                        <span>{events.audienceType}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {!events.isCancelled && (
                                <div className="share-event">
                                  <a
                                    className="cursorPointer"
                                    onClick={() =>
                                      handleOpenShare(index, "upCommingData")
                                    }
                                  >
                                    Share
                                  </a>
                                  <div>
                                    <ShareEvent
                                      open={
                                        shareDialog["upCommingData"]
                                          ? shareDialog["upCommingData"][index]
                                            ?.open
                                          : false
                                      }
                                      shareEventID={events._id}
                                      shareEventURL={events.share_url}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                      )
                    ) : (
                      <div>No Events Created</div>
                    )}
                  </div>

                  <div className="events-listing">
                    <div className="title">
                      <h2>Past Events</h2>
                      <a
                        className="viewall cursorPointer"
                        onClick={() => handleViewAll("pastData")}
                      >
                        View All
                      </a>
                    </div>

                    {myEventsData?.pastData?.length > 0 ? (
                      myEventsData?.pastData?.map(
                        (events, index) =>
                          index < 2 && (
                            <div className="loop grey-wrap item" key={index}>
                              <div
                                className="flex-row cursorPointer"
                                onClick={() =>
                                  handleViewEventDetails(index, "pastData")
                                }
                              >
                                <div className="col-img">
                                  <div
                                    onMouseOver={(e) => {
                                      e.target.style.backgroundImage = `url(${events.videoStatus ? events.gifURL : events.eventImage})`
                                    }}
                                    onMouseOut={(e) => {
                                      e.target.style.backgroundImage = `url(${events.videoStatus
                                        ? events.thumbnailImage
                                        : events.eventImage})`
                                    }}
                                    style={{
                                      minHeight: "250px",
                                      height: "100%",
                                      width: "100%",
                                      backgroundImage: `url(${events.videoStatus
                                        ? events.thumbnailImage
                                        : events.eventImage
                                        })`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  ></div>
                                  {events.videoStatus && <div style={{
                                    position: "absolute",
                                    bottom: "20px"
                                  }}><img src={iconPlay} style={{ width: "40px" }} /></div>}
                                </div>
                                <div className="col-content">
                                  <div className="details">
                                    <h3
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {events.eventTitle}{" "}
                                      {events.isCancelled && (
                                        <span style={{ color: "#e50914" }}>
                                          Event Cancelled
                                        </span>
                                      )}
                                    </h3>
                                    <div
                                      className="description-scroll"
                                      style={{ maxHeight: "100px" }}
                                    >
                                      <p style={{ whiteSpace: "pre-line" }}>
                                        {events.description}
                                      </p>
                                    </div>
                                    <div className="other-detail">
                                      <div className="flex-row">
                                        Total booked tickets{" "}
                                        <span>{events.soldTickets}</span>
                                      </div>
                                      <div className="flex-row">
                                        Date and Time{" "}
                                        <span>
                                          {getEventLocalDate(
                                            events.eventDate,
                                            events.eventTime,
                                            events.setTimeZone
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex-row">
                                        Audience Type{" "}
                                        <span>{events.audienceType}</span>
                                      </div>
                                      <div className="flex-row">
                                        Amount Earned{" "}
                                        <span>€ {events.earnedAmount}</span>
                                      </div>
                                      {events.userId.actorDocIssueCountry ===
                                        "CY" && (
                                          <div className="flex-row">
                                            VAT (19%/CY account){" "}
                                            <span>€ {events.vatAdd ? events.vatAdd : "0"}</span>
                                          </div>
                                        )}
                                      <div className="flex-row rating">
                                        <span className="like-con-book">
                                          <img src={IconLike} alt="" />
                                          {events?.rate_percentage
                                            ? events?.rate_percentage
                                            : 0}{" "}
                                          %
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                      )
                    ) : (
                      <div>No Events Present</div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          )}
        </>
      )}
    </div>
  );
}

const mapStateToProps = ({ EVENTS_REDUCER, NOTIF_REDUCER }) => {
  return {
    myEventsData: EVENTS_REDUCER?.myEventsData,
    notifEID: NOTIF_REDUCER.notiEventID
  };
};

export default connect(mapStateToProps, { GetMyEvents, })(Home);