import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { permission } from "./permissions";
import { connect } from "react-redux";

function ProtectedRoute({ component: Component, path, role, isAuth, history, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth
          && permission?.[role]?.includes(path)
          ? (
            <Component {...props} history={history} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
      }
    />
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData?.role,
    isAuth: USER_REDUCER.isAuth
  };
};

export default connect(mapStateToProps)(withRouter(ProtectedRoute));