/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import iconAcUser from "../../assets/img/icon-ac-user.svg";
import dpPhoto from "../../assets/img/img_avatar.png";
import iconEdit from "../../assets/img/icon-edit.png";
import iconSocialLink from "../../assets/img/icon-sociallinks.svg";
import iconGoogle from "../../assets/img/icon-google.png";
import banking from "../../assets/img/icon-banking.svg";
import settings from "../../assets/img/icon-settings.svg";
import voucher from "../../assets/img/icon-voucher.svg";
import facebook from "../../assets/img/icon-facebook.png";
import iconOffice from "../../assets/img/icon-office.svg";
import iconLike from "../../assets/img/icon-like.svg";
import addIcon from "../../assets/img/add.svg";
import MinusPNG from "../../assets/img/icon-minus-lite.png";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import PaymentMethods from "./paymentMethods";
import BankingInfo from "./BankingInfo";
import {
  FileUpload,
  MyAccountInfo,
  UpdateUserInfo,
  UpdateSocialStatus,
  LogOutAction,
  DeactivateAccount,
  ChangeNotificationStatus,
  LogOutOfAllDevices,
  GetCountryList,
} from "../../redux/actions/user";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import { Notify } from "../../helpers/notifications";
import { Validation } from "../../helpers/validations";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { appId, clientId } from "../../helpers/constants";
import Vouchers from "./vouchers";
import Store from "../../redux/store/store";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
  },

  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#e50c13",
        opacity: 1,
        border: "none",
      },
    },

    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },

  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function MyAccount({
  MyAccountInfo,
  myAccountData,
  setSelectedTab,
  history,
  resetTab,
}) {
  const [tab, setTab] = useState(1);
  const [checkedG, setCheckedG] = useState(myAccountData?.googleStatus);
  const [checkedF, setCheckedF] = useState(myAccountData?.fbStatus);
  const [checkedNoti, setCheckedNoti] = useState(myAccountData ? myAccountData.notifStatus : false);
  const [editDetails, setEditDetails] = useState(false);
  const [editCompanyDetails, setEditCompanyDetails] = useState(false);
  const [firstName, setFirstName] = useState(myAccountData ? myAccountData?.firstName : "");
  const [lastName, setLastName] = useState(myAccountData ? myAccountData?.lastName : "");
  const [phone, setPhone] = useState(myAccountData ? myAccountData?.phone : "");
  const [description, setDescription] = useState(myAccountData ? myAccountData?.description : "");
  const role = myAccountData?.role;
  const [photo, setPhoto] = useState(myAccountData ? myAccountData?.profilePhoto : "");
  const [photoPreview, setPhotoPreview] = useState();
  const [companyName, setCompanyName] = useState(myAccountData?.legalCompanyName ? myAccountData.legalCompanyName : "");
  const [cRegNumber, setCRegNumber] = useState(myAccountData?.companyRegNo ? myAccountData.companyRegNo : "");
  const [cVAT, setCVAT] = useState(myAccountData?.companyVatNo ? myAccountData.companyVatNo : "");
  const [cCountry, setCCountry] = useState(myAccountData?.companyEstablishmentCountry ? myAccountData.companyEstablishmentCountry : "");
  const [cAddress, setCAddress] = useState({
    line1: myAccountData?.companyAddressLineOne
      ? myAccountData.companyAddressLineOne
      : "",
    line2: myAccountData?.companyAddressLineTwo
      ? myAccountData.companyAddressLineTwo
      : "",
    city: myAccountData?.companyCity ? myAccountData.companyCity : "",
    postalCode: myAccountData?.companyPostalCode
      ? myAccountData.companyPostalCode
      : "",
  });

  const [companyWebLink, setCompanyWebLink] = useState(
    myAccountData?.companyWebsites.length > 0
      ? myAccountData?.companyWebsites
      : [
        {
          link: "",
        },
      ]
  );
  const [error, setError] = useState({});
  const [compError, setCompError] = useState({});
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [reasonDeactive, setReasonDeactive] = useState("");
  const [passDeactive, setPassDeactive] = useState("");
  const [errorDeactivate, setErrorDeactivate] = useState("");
  const [errorReasonDeactive, setErrorReasonDeactivate] = useState("");
  const [confirmDeactivate, setConfirmDeactivate] = useState(false);
  const [countryList, setCountryList] = useState("");
  const [countryCode, setCountryCode] = useState("US");
  const [dialCode, setDialCode] = useState("");
  const { dispatch } = Store.store;

  useEffect(() => {
    document.title = "LiveStage | MyAccount";
    GetCountryList()
      .then((response) => {
        setCountryList(response.data.data.country);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    setTab(1);
  }, [resetTab]);

  useEffect(() => {
    const t = localStorage.getItem("setTab");
    if (t) {
      setTab(parseInt(t));
    } else setTab(1);
  }, []);

  const handleCancel = () => {
    setEditDetails(false);
    setFirstName(myAccountData?.firstName);
    setLastName(myAccountData?.lastName);
    setPhone(myAccountData?.phone);
    setPhoto(myAccountData?.profilePhoto);
    setPhotoPreview("");
    setDescription(myAccountData?.description);
  };

  const handleCompanyCancel = () => {
    setEditCompanyDetails(false);
    setCompanyName(myAccountData?.legalCompanyName);
    setCAddress({
      line1: myAccountData?.companyAddressLineOne,
      line2: myAccountData?.companyAddressLineTwo,
      city: myAccountData?.companyCity,
      postalCode: myAccountData?.companyPostalCode,
    });
    setCCountry(myAccountData?.companyEstablishmentCountry);
    setCRegNumber(myAccountData?.companyRegNo);
    setCVAT(myAccountData?.companyVatNo);
    setCompanyWebLink(
      myAccountData?.companyWebsites.length > 0
        ? myAccountData?.companyWebsites
        : [
          {
            link: "",
          },
        ]
    );
    setCompError({});
  };

  const handleUserSave = async () => {
    let fields = ["firstName", "lastName", "phone"];
    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;
    let payload = {
      firstName: firstName,
      lastName: lastName,
      profilePhoto: photo,
      phone: phone,
      description: description,
      countryCode: countryCode.toUpperCase(),
      phoneNumber: phone.slice(dialCode.length),
      companyWebsites: companyWebLink,
      legalCompanyName: companyName,
      companyAddressLineOne: cAddress.line1,
      companyAddressLineTwo: cAddress.line2,
      companyCity: cAddress.city,
      companyPostalCode: cAddress.postalCode,
      companyEstablishmentCountry: cCountry,
      companyRegNo: cRegNumber,
      companyVatNo: cVAT,
    };
    try {
      const response = await UpdateUserInfo(payload);
      if (response && response.status === 200) {
        MyAccountInfo();
        setEditDetails(false);
        Notify("success", "Details have been updated successfully.");
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  // Validator function (User Details)
  const handleValidation = (type) => {
    if (type === "firstName") {
      if (Validation.empty(firstName)) {
        setError({ ...error, firstName: "First Name is required" });
        return false;
      } else if (!Validation.name(firstName)) {
        setError({
          ...error,
          firstName: "Min 2 and Max 50 characters allowed",
        });
        return false;
      }
    } else if (type === "lastName") {
      if (Validation.empty(lastName)) {
        setError({ ...error, lastName: "Last Name is required" });
        return false;
      } else if (!Validation.name(lastName)) {
        setError({ ...error, lastName: "Min 2 and Max 50 characters allowed" });
        return false;
      }
    } else if (type === "phone") {
      if (Validation.empty(phone)) {
        setError({ ...error, phone: "Phone Number is required" });
        return false;
      } else if (phone.length < 7) {
        setError({
          ...error,
          phone: "Please enter a valid phone number",
        });
        return false;
      }
    }
    return true;
  };

  const handleCompanySave = async () => {
    let fields = [
      "companyName",
      "companyAddress",
      "companyCity",
      "companyPostalCode",
      "companyCountry",
      "companyReg",
      "companyVAT",
      "companyWebLink",
    ];
    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleCompanyValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;
    let payload = {
      firstName: firstName,
      lastName: lastName,
      profilePhoto: photo,
      phone: phone,
      description: description,
      countryCode: countryCode.toUpperCase(),
      phoneNumber: phone.slice(dialCode.length),
      companyWebsites: companyWebLink,
      legalCompanyName: companyName,
      companyAddressLineOne: cAddress.line1,
      companyAddressLineTwo: cAddress.line2,
      companyCity: cAddress.city,
      companyPostalCode: cAddress.postalCode,
      companyEstablishmentCountry: cCountry,
      companyRegNo: cRegNumber,
      companyVatNo: cVAT,
    };
    try {
      const response = await UpdateUserInfo(payload);
      if (response && response.status === 200) {
        MyAccountInfo();
        setEditCompanyDetails(false);
        Notify("success", "Details have been updated successfully.");
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  // Validator function (Company Details)
  const handleCompanyValidation = (type) => {
    if (type === "companyName") {
      if (Validation.empty(companyName)) {
        setCompError({
          ...compError,
          companyName: "Legal Company Name is required",
        });
        return false;
      }
    } else if (type === "companyAddress") {
      if (Validation.empty(cAddress.line1)) {
        setCompError({
          ...compError,
          cAddrLine1: "Company Address is required",
        });
        return false;
      }
    } else if (type === "companyCity") {
      if (Validation.empty(cAddress.city)) {
        setCompError({ ...compError, cAddrCity: "Company City is required" });
        return false;
      }
    } else if (type === "companyPostalCode") {
      if (Validation.empty(cAddress.postalCode)) {
        setCompError({
          ...compError,
          cAddrPostalCode: "Company Postal Code is required",
        });
        return false;
      } else if (cAddress.postalCode.length < 4) {
        setCompError({
          ...compError,
          cAddrPostalCode: "Postal Code should be greater than 3 characters",
        });
        return false;
      }
    } else if (type === "companyCountry") {
      if (Validation.empty(cCountry)) {
        setCompError({
          ...compError,
          cCountry: "Country of Establishment is required",
        });
        return false;
      }
    } else if (type === "companyReg") {
      if (Validation.empty(cRegNumber)) {
        setCompError({
          ...compError,
          cRegNumber: "Registration Number is required",
        });
        return false;
      } else if (cRegNumber.length < 7) {
        setCompError({
          ...compError,
          cRegNumber: "Registration Number should be greater than 6 characters",
        });
        return false;
      }
    } else if (type === "companyVAT") {
      if (Validation.empty(cVAT)) {
        setCompError({
          ...compError,
          cVAT: "VAT Registration Number is required",
        });
        return false;
      } else if (cVAT.length < 7) {
        setCompError({
          ...compError,
          cVAT: "VAT Registration Number should be greater than 6 characters",
        });
        return false;
      }
    } else if (type === "companyWebLink") {
      if (Validation.empty(companyWebLink[0].link)) {
        setCompError({
          ...compError,
          companyWebLink: "Company Link is required",
        });
        return false;
      }
    }
    return true;
  };

  // Update Company Website Link Text
  const handleWebsiteLink = (text, index) => {
    let newArray = companyWebLink.map((links, i) =>
      index === i
        ? {
          ...links,
          link: text,
        }
        : links
    );
    setCompanyWebLink(newArray);
  };

  // Add New Company Website Field
  const addWebsiteLink = () => {
    if (companyWebLink[0].link) {
      setCompanyWebLink([
        {
          link: "",
        },
        ...companyWebLink,
      ]);
    } else {
      setCompError({
        ...compError,
        companyWebLink: "Company Link is required",
      });
    }
  };

  const removeWebsiteLink = (index) => {
    let newArray = companyWebLink.filter((item, i) => index !== i);
    setCompanyWebLink(newArray);
  };

  const handleImage = async (file) => {
    if (file) {
      let reader = new FileReader();
      reader.onload = async () => {
        if (file.size > 1048576) {
          setError({ ...error, photo: "File size is greater than 1 MB" });
          return;
        } else {
          let preview = URL.createObjectURL(file);
          setPhotoPreview(preview);
          const formdata = new FormData();
          formdata.append("image", file);
          try {
            const response = await FileUpload(formdata);
            if (response && response.status === 200) {
              setPhoto(response.data.fileName);
              setError({ ...error, photo: "" });
            }
          } catch (error) {
            console.log(error);
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  // Google account linking
  const responseGoogleFailure = (res) => {
    setCheckedG(false);
  };

  const responseGoogle = (res) => {
    setCheckedG(true);
    const { profileObj } = res;
    const userData = {
      profileObj,
      type: "google",
    };
    handleSociaLinking(userData);
  };

  // Facebook Account Linking

  const facebookFailureResponse = (res) => {
    setCheckedF(false);
  };

  const responseFacebook = (res) => {
    setCheckedF(true);
    const { name, email, picture, userID } = res;
    const userData = {
      name,
      email,
      picture,
      userID,
      type: "facebook",
    };
    handleSociaLinking(userData);
  };

  // Social Account Linking/ DeLinking
  const handleSociaLinking = async (data) => {
    var payload;

    if (data.type === "google") {
      payload = {
        socialType: data.type,
        socialId: !checkedG ? data.profileObj.googleId : "",
      };
    } else {
      payload = {
        socialType: data.type,
        socialId: !checkedF ? data.userID : "",
      };
    }
    try {
      const response = await UpdateSocialStatus(payload);
      if (response && response.status === 200) {
        MyAccountInfo();
        if (
          (payload.socialType === "google" && response.data.data.googleId) ||
          (payload.socialType === "facebook" && response.data.data.fbId)
        )
          Notify(
            "success",
            `Your ${payload.socialType} account has been linked successfully!!`
          );
        else
          Notify(
            "warning",
            `Your ${payload.socialType} account has been de-linked successfully!!`
          );
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  const handleDeLink = (text) => {
    if (text === "google") {
      setCheckedG(false);
    } else {
      setCheckedF(false);
    }
    handleSociaLinking({ type: text });
  };

  const handleDeactivateAcc = async () => {
    let payload = {
      deactivatMessage: reasonDeactive,
      password: passDeactive,
    };

    try {
      const response = await DeactivateAccount(payload);
      if (response && response.status === 200) {
        Notify("success", "Your account has been deactivated successfully!!");
        dispatch(LogOutAction());
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  const handleNotifications = async () => {
    let payload = {
      status: !checkedNoti,
    };
    try {
      const response = await ChangeNotificationStatus(payload);
      if (response && response.status === 200) {
        MyAccountInfo();
        Notify("success", `Notifications ${!checkedNoti ? "ON!!" : "OFF!!"}`);
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  const handleLogOutAllDevices = async () => {
    LogOutOfAllDevices()
      .then((response) => {
        dispatch(LogOutAction());
      })
      .catch((error) => console.log(error));
  };
  return (
    <div>
      {tab === 1 ? (
        <>
          <section className="myaccount-section">
            <div className="flex-container ">
              <div className="breadcrumbs">
                <ul>
                  <li>
                    <a
                      className="cursorPointer"
                      onClick={() => setSelectedTab(1)}
                    >
                      Home
                    </a>{" "}
                    <span>&#62;</span>{" "}
                  </li>

                  <li>My Account</li>
                </ul>
              </div>

              <div className="myaccount enuser-myaccount">
                <div className="top-heading">
                  <h1>My Account</h1>
                </div>
                <div className="form-myaccount">
                  <div className="myaccount-item">
                    <div className="flex-row gutter-space">
                      <div className="flex-col-35">
                        <div className="title">
                          <img src={iconAcUser} width="20" height="20" alt="User" />
                          <h4> User Details  </h4>
                          <span className="icon-edit-s">
                            <img
                              width="20" height="20"
                              title="Edit Details"
                              src={iconEdit}
                              alt="User"
                              className="cursorPointer"
                              onClick={() => setEditDetails(true)}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="flex-col-65">
                        <div className="flex-row gutter-space">
                          <div className="flex-col-65">
                            <div className="profile-photo">
                              <div
                                className="file-field dp-img-actor m-auto-cancel"
                                style={{
                                  backgroundImage: `url(${photoPreview
                                    ? `${photoPreview}`
                                    : photo
                                      ? `${photo}`
                                      : dpPhoto
                                    })`,
                                  border: "0.4px solid white",
                                }}
                              >
                                <div className="icon-upload">
                                  {editDetails && (
                                    <input
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) =>
                                        handleImage(e.target.files[0])
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              {error && (
                                <span
                                  className="displayBlock"
                                  style={{ color: "red" }}
                                >
                                  {error?.photo}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="flex-col-35"></div>
                        </div>
                        <div className="flex-row gutter-space">
                          <div className="flex-col-65">
                            {editDetails ? (
                              <div className="field-wrap company-wrap">
                                <input
                                  type="text"
                                  value={firstName}
                                  placeholder="First Name"
                                  onChange={(e) => setFirstName(e.target.value)}
                                  onFocus={(e) =>
                                    setError({ ...error, firstName: "" })
                                  }
                                  onBlur={(e) => handleValidation("firstName")}
                                />
                                {error && (
                                  <span
                                    className="displayBlock"
                                    style={{ color: "red" }}
                                  >
                                    {error?.firstName}
                                  </span>
                                )}
                              </div>
                            ) : (
                              <p>{firstName}</p>
                            )}
                          </div>
                          <div className="flex-col-35"></div>
                        </div>
                        <div className="flex-row gutter-space">
                          <div className="flex-col-65">
                            {editDetails ? (
                              <div className="field-wrap company-wrap">
                                <input
                                  type="text"
                                  value={lastName}
                                  placeholder="Last Name"
                                  onChange={(e) => setLastName(e.target.value)}
                                  onFocus={(e) =>
                                    setError({ ...error, lastName: "" })
                                  }
                                  onBlur={(e) => handleValidation("lastName")}
                                />
                                {error && (
                                  <span
                                    className="displayBlock"
                                    style={{ color: "red" }}
                                  >
                                    {error?.lastName}
                                  </span>
                                )}
                              </div>
                            ) : (
                              <p>{lastName}</p>
                            )}
                          </div>
                          <div className="flex-col-35"></div>
                        </div>
                        <div className="flex-row gutter-space">
                          <div className="flex-col-65">
                            {editDetails ? (
                              <div className="field-wrap company-wrap">
                                <div className="phonenumber phonenumber-myaccount">
                                  <PhoneInput
                                    placeholder="Phone Number"
                                    value={phone}
                                    onChange={(
                                      phonenumber,
                                      value,
                                      event,
                                    ) => {
                                      if (value.dialCode) {
                                        if (phone) {
                                          setPhone(phonenumber);
                                        } else {
                                          setPhone(`${value.dialCode}${phone}`);
                                        }
                                        setDialCode(value.dialCode);
                                        setCountryCode(value.countryCode);
                                      } else {
                                        setError({
                                          ...error,
                                          phone:
                                            "Please select dial code first",
                                        });
                                      }
                                    }}
                                    onFocus={(e) =>
                                      setError({ ...error, phone: "" })
                                    }
                                    onBlur={(e) => handleValidation("phone")}
                                  />
                                </div>
                                {error && (
                                  <span
                                    className="displayBlock"
                                    style={{ color: "red" }}
                                  >
                                    {error?.phone}
                                  </span>
                                )}
                              </div>
                            ) : (
                              <p>{"Phone: " + phone}</p>
                            )}
                          </div>
                          <div className="flex-col-35"></div>
                        </div>
                        <div className="flex-row gutter-space">
                          <div className="flex-col-65">
                            <p>{myAccountData?.email}</p>
                          </div>
                          <div className="flex-col-35">
                            {myAccountData?.password === "true" && (
                              <p>
                                <a
                                  className="btn-text cursorPointer"
                                  onClick={() => history.push("/changeEmail")}
                                >
                                  Edit
                                </a>
                              </p>
                            )}
                          </div>
                        </div>
                        {role === "Actor" && (
                          <div className="field-wrap w-wrap-text">
                            <textarea
                              className="reason-deactivate"
                              placeholder="Description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              disabled={!editDetails}
                            ></textarea>
                          </div>
                        )}
                        {editDetails && (
                          <div className="field-wrap btn-ac-row">
                            <button
                              type="button"
                              className="primary-button"
                              onClick={() => handleUserSave()}
                            >
                              Save
                            </button>

                            <button
                              type="button"
                              className="primary-button btn-space-cancel"
                              onClick={() => handleCancel()}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {(role === "Corporate" || role === "Actor") && (
                    <div className="myaccount-item">
                      <div className="flex-row gutter-space">
                        <div className="flex-col-35">
                          <div className="title">
                            <img src={iconOffice} width="25" height="25" alt="User" />
                            <h4>Company Details</h4>
                            <span className="icon-edit-s">
                              <img
                                title="Edit Company Details"
                                src={iconEdit}
                                alt="User"
                                className="cursorPointer"
                                onClick={() => setEditCompanyDetails(true)}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="flex-col-65">
                          <div className="field-wrap company-wrap">
                            <input
                              type="text"
                              name=""
                              required=""
                              placeholder="Legal Company Name"
                              value={companyName}
                              onChange={(e) => setCompanyName(e.target.value)}
                              disabled={!editCompanyDetails}
                              onFocus={(e) =>
                                setCompError({ ...compError, companyName: "" })
                              }
                              onBlur={(e) =>
                                handleCompanyValidation("companyName")
                              }
                            />
                            {compError && (
                              <span
                                className="displayBlock"
                                style={{ color: "red" }}
                              >
                                {compError?.companyName}
                              </span>
                            )}
                          </div>
                          <div className="field-wrap company-wrap">
                            <input
                              type="text"
                              name=""
                              value={cAddress.line1}
                              required=""
                              disabled={!editCompanyDetails}
                              placeholder="Company's Address Line 1"
                              onChange={(e) =>
                                setCAddress({
                                  ...cAddress,
                                  line1: e.target.value,
                                })
                              }
                              onFocus={(e) =>
                                setCompError({ ...compError, cAddrLine1: "" })
                              }
                              onBlur={(e) =>
                                handleCompanyValidation("companyAddress")
                              }
                            />
                            {compError && (
                              <span
                                className="displayBlock"
                                style={{ color: "red" }}
                              >
                                {compError?.cAddrLine1}
                              </span>
                            )}
                          </div>
                          <div className="field-wrap company-wrap">
                            <input
                              type="text"
                              name=""
                              value={cAddress.line2}
                              disabled={!editCompanyDetails}
                              required=""
                              placeholder="Company's Address Line 2 (optional)"
                              onChange={(e) =>
                                setCAddress({
                                  ...cAddress,
                                  line2: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="field-wrap company-wrap">
                            <input
                              type="text"
                              name=""
                              value={cAddress.city}
                              disabled={!editCompanyDetails}
                              required=""
                              placeholder="City"
                              onChange={(e) =>
                                setCAddress({
                                  ...cAddress,
                                  city: e.target.value.replace(
                                    /[^a-zA-Z ]/g,
                                    ""
                                  ),
                                })
                              }
                              onFocus={(e) =>
                                setCompError({ ...compError, cAddrCity: "" })
                              }
                              onBlur={(e) =>
                                handleCompanyValidation("companyCity")
                              }
                            />
                            {compError && (
                              <span
                                className="displayBlock"
                                style={{ color: "red" }}
                              >
                                {compError?.cAddrCity}
                              </span>
                            )}
                          </div>
                          <div className="field-wrap company-wrap">
                            <input
                              type="text"
                              name=""
                              value={cAddress.postalCode}
                              required=""
                              disabled={!editCompanyDetails}
                              placeholder="Postal Code"
                              maxLength={10}
                              onChange={(e) =>
                                setCAddress({
                                  ...cAddress,
                                  postalCode: e.target.value.toUpperCase(),
                                })
                              }
                              onFocus={(e) =>
                                setCompError({
                                  ...compError,
                                  cAddrPostalCode: "",
                                })
                              }
                              onBlur={(e) =>
                                handleCompanyValidation("companyPostalCode")
                              }
                            />
                            {compError && (
                              <span
                                className="displayBlock"
                                style={{ color: "red" }}
                              >
                                {compError?.cAddrPostalCode}
                              </span>
                            )}
                          </div>
                          <div className="field-wrap company-wrap">
                            <select
                              value={cCountry}
                              onChange={(e) => setCCountry(e.target.value)}
                              onFocus={(e) =>
                                setCompError({ ...compError, cCountry: "" })
                              }
                              disabled={!editCompanyDetails}
                              onBlur={(e) =>
                                handleCompanyValidation("companyCountry")
                              }
                            >
                              <option
                                style={{ backgroundColor: "black" }}
                                value=""
                              >
                                Choose Country of Establishment
                              </option>
                              {Object.keys(countryList)?.map((item, index) => (
                                <option
                                  style={{ backgroundColor: "black" }}
                                  value={item}
                                  key={index}
                                >
                                  {countryList[item]}
                                </option>
                              ))}
                            </select>
                            {compError && (
                              <span
                                className="displayBlock"
                                style={{ color: "red" }}
                              >
                                {compError?.cCountry}
                              </span>
                            )}
                          </div>
                          <div className="field-wrap company-wrap">
                            <input
                              type="text"
                              name=""
                              value={cRegNumber}
                              required=""
                              placeholder="Registration Number"
                              maxLength={50}
                              disabled={!editCompanyDetails}
                              onChange={(e) =>
                                setCRegNumber(
                                  e.target.value
                                    .replace(/[^a-zA-Z0-9]/g, "")
                                    .toUpperCase()
                                )
                              }
                              onFocus={(e) =>
                                setCompError({ ...compError, cRegNumber: "" })
                              }
                              onBlur={(e) =>
                                handleCompanyValidation("companyReg")
                              }
                            />
                            {compError && (
                              <span
                                className="displayBlock"
                                style={{ color: "red" }}
                              >
                                {compError?.cRegNumber}
                              </span>
                            )}
                          </div>
                          <div className="field-wrap company-wrap">
                            <input
                              type="text"
                              name=""
                              value={cVAT}
                              required=""
                              disabled={!editCompanyDetails}
                              placeholder="VAT Registration Number"
                              maxLength={30}
                              onChange={(e) =>
                                setCVAT(
                                  e.target.value
                                    .replace(/[^a-zA-Z0-9]/g, "")
                                    .toUpperCase()
                                )
                              }
                              onFocus={(e) =>
                                setCompError({ ...compError, cVAT: "" })
                              }
                              onBlur={(e) =>
                                handleCompanyValidation("companyVAT")
                              }
                            />
                            {compError && (
                              <span
                                className="displayBlock"
                                style={{ color: "red" }}
                              >
                                {compError?.cVAT}
                              </span>
                            )}
                          </div>
                          <div className="field-wrap company-wrap">
                            {companyWebLink.map((links, index) => (
                              <div
                                className="inner-field-wrapper  minus-outer"
                                key={index}
                              >
                                {editCompanyDetails &&
                                  (index < 1 ? (
                                    <a
                                      className="icon-viewpswd add-company-web"
                                      onClick={() => addWebsiteLink()}
                                    >
                                      <img src={addIcon} alt="img" />
                                    </a>
                                  ) : (
                                    <a
                                      className="add-company-web icon-minus-field cursorPointer"
                                      style={{ top: "0px" }}
                                      onClick={() => removeWebsiteLink(index)}
                                    >
                                      <img src={MinusPNG} alt="img" />
                                    </a>
                                  ))}
                                <input
                                  type="text"
                                  name=""
                                  required=""
                                  placeholder="Website Link"
                                  value={links.link}
                                  onChange={(e) =>
                                    handleWebsiteLink(e.target.value, index)
                                  }
                                  onFocus={() =>
                                    setCompError({
                                      ...compError,

                                      companyWebLink: "",
                                    })
                                  }
                                  disabled={!editCompanyDetails}
                                />
                                {index < 1 && compError && (
                                  <span
                                    className="displayBlock"
                                    style={{ color: "red" }}
                                  >
                                    {compError?.companyWebLink}
                                  </span>
                                )}
                              </div>
                            ))}
                          </div>
                          {editCompanyDetails && (
                            <div className="field-wrap btn-ac-row">
                              <button
                                type="button"
                                className="primary-button"
                                onClick={() => handleCompanySave()}
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="primary-button btn-space-cancel"
                                onClick={() => handleCompanyCancel()}
                              >
                                Cancel
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="myaccount-item">
                    <div className="flex-row gutter-space">
                      <div className="flex-col-35">
                        <div className="title">
                          <img src={iconSocialLink} alt="User" />

                          <h4>Social linked accounts </h4>
                        </div>
                      </div>
                      <div className="flex-col-65">
                        <div className="flex-row gutter-space">
                          <div className="flex-col-65">
                            <p>
                              <a
                                style={{ cursor: "auto" }}
                                className="social-profile"
                              >
                                <img src={iconGoogle} alt="User" /> Google
                              </a>
                            </p>
                          </div>
                          <div className="flex-col-35">
                            <button className="active-toggle">
                              <GoogleLogin
                                clientId={clientId}
                                icon={false}
                                render={(renderProps) => (
                                  <IOSSwitch
                                    checked={checkedG}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        renderProps.onClick();
                                      } else {
                                        handleDeLink("google");
                                      }
                                    }}
                                    name="checkedG"
                                  />
                                )}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogleFailure}
                              />
                            </button>
                          </div>
                        </div>
                        <div className="flex-row gutter-space">
                          <div className="flex-col-65">
                            <p>
                              <a
                                style={{ cursor: "auto" }}
                                className="social-profile"
                              >
                                <img src={facebook} alt="User" /> Facebook
                              </a>
                            </p>
                          </div>
                          <div className="flex-col-35">
                            <button className="active-toggle">
                              <FacebookLogin
                                appId={appId}
                                autoLoad={false}
                                fields="name,email,picture"
                                onFailure={facebookFailureResponse}
                                callback={responseFacebook}
                                textButton=""
                                render={(renderProps) => (
                                  <IOSSwitch
                                    checked={checkedF}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        renderProps.onClick();
                                      } else {
                                        handleDeLink("facebook");
                                      }
                                    }}
                                    name="checkedF"
                                  />
                                )}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {role === "Actor" ? (
                    <div className="myaccount-item">
                      <div className="flex-row gutter-space">
                        <div className="flex-col-35">
                          <div className="title">
                            <img src={banking} width="23" height="23" alt="User" />
                            <h4>Manage Bank Account</h4>
                          </div>
                        </div>
                        <div className="flex-col-65">
                          <div className="flex-row gutter-space">
                            <div className="flex-col-65"> </div>
                            <div className="flex-col-35">
                              <a
                                className="btn-text cursorPointer"
                                onClick={() => setTab(3)}
                              >
                                Manage Bank Account
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="myaccount-item">
                      <div className="flex-row gutter-space">
                        <div className="flex-col-35">
                          <div className="title">
                            <img src={banking} alt="User" />

                            <h4>My Payment Method </h4>
                          </div>
                        </div>
                        <div className="flex-col-65">
                          <div className="flex-row gutter-space">
                            <div className="flex-col-65">
                            </div>
                            <div className="flex-col-35">
                              <a
                                className="btn-text cursorPointer"
                                onClick={() => setTab(2)}
                              >
                                Manage Payment Method
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {role === "Actor" && (
                    <div className="myaccount-item">
                      <div className="flex-row gutter-space">
                        <div className="flex-col-35">
                          <div className="title">
                            <img
                              src={iconLike}
                              alt="User"
                              width="23" height="23"
                            />
                            <h4>Rating</h4>
                          </div>
                        </div>
                        <div className="flex-col-65">
                          <div className="flex-row gutter-space">
                            <div className="flex-col-65">
                              {myAccountData?.artistRating}%
                            </div>
                            <div className="flex-col-35"> </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {role !== "Actor" && (
                    <div className="myaccount-item">
                      <div className="flex-row gutter-space">
                        <div className="flex-col-35">
                          <div className="title">
                            <img src={voucher} alt="User" />

                            <h4>My Vouchers </h4>
                          </div>
                        </div>
                        <div className="flex-col-65">
                          <div className="flex-row gutter-space">
                            <div className="flex-col-65"> </div>
                            <div className="flex-col-35">
                              <a
                                className="btn-text cursorPointer"
                                onClick={() => setTab(4)}
                              >
                                Redeem your vouchers
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="myaccount-item">
                    <div className="flex-row gutter-space">
                      <div className="flex-col-35">
                        <div className="title">
                          <img src={settings} width="21" height="21" alt="User" />

                          <h4>Settings </h4>
                        </div>
                      </div>
                      <div className="flex-col-65">
                        <div className="flex-row gutter-space">
                          <div className="flex-col-65">
                            <p>
                              <a className="btn-text cursorPointer">
                                Notifications Alert
                              </a>
                            </p>
                          </div>
                          <div className="flex-col-35">
                            <button className="active-toggle">
                              <IOSSwitch
                                checked={checkedNoti}
                                onChange={(e) => {
                                  setCheckedNoti(e.target.checked);
                                  handleNotifications();
                                }}
                                name="checkedNoti"
                              />
                            </button>
                          </div>
                          <div className="flex-col-65">
                            {myAccountData?.password === "true" && (
                              <p>
                                <a
                                  className="btn-text cursorPointer"
                                  onClick={() =>
                                    history.push("/changePassword")
                                  }
                                >
                                  Change Password
                                </a>
                              </p>
                            )}
                            <p>
                              <a
                                className="btn-text cursorPointer"
                                onClick={() => {
                                  setShowDeactivate(true);
                                  window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                  });
                                }}
                              >
                                Deactivate your account{" "}
                              </a>
                            </p>
                            <p>
                              <a
                                className="btn-text cursorPointer"
                                onClick={() => handleLogOutAllDevices()}
                              >
                                Log out of all devices{" "}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* De-Activate Account */}
          <div className={`popup-model ${showDeactivate && "show"}`}>
            <div className="model-head">
              <h2>Deactivate your account</h2>

              <a
                className="model-close cursorPointer"
                onClick={() => {
                  setShowDeactivate(false);
                  setReasonDeactive("");
                  setPassDeactive("");
                  setErrorDeactivate("");
                  setConfirmDeactivate(false);
                }}
              >
                &#10006;
              </a>
            </div>
            <div className="model-body">
              {confirmDeactivate ? (
                <div className="model-content">
                  <h3 style={{ textAlign: "center" }}>
                    Are you sure you want to Deactivate your account ?
                  </h3>

                  <div className="btn-wrap text-center">
                    <button
                      type="button"
                      className="primary-button"
                      style={{ margin: "10px" }}
                      onClick={() => handleDeactivateAcc()}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="secondary-button"
                      style={{ margin: "10px" }}
                      onClick={() => {
                        setShowDeactivate(false);
                        setReasonDeactive("");
                        setPassDeactive("");
                        setErrorDeactivate("");
                        setConfirmDeactivate(false);
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              ) : (
                <div className="model-content">
                  <form>
                    <div className="field-wrap">
                      <textarea
                        className="reason-deactivate fullWidth"
                        placeholder="Enter the reason"
                        value={reasonDeactive}
                        onChange={(e) => setReasonDeactive(e.target.value)}
                        onFocus={() => setErrorReasonDeactivate("")}
                        onBlur={() => {
                          if (Validation.empty(reasonDeactive)) {
                            setErrorReasonDeactivate("Please enter the reason");
                          }
                        }}
                      ></textarea>
                      {errorReasonDeactive && (
                        <span style={{ color: "red" }}>
                          {errorReasonDeactive}
                        </span>
                      )}
                    </div>
                    <div className="field-wrap2">
                      <input
                        type="password"
                        className="pswd-deactivate"
                        placeholder="Enter password*"
                        value={passDeactive}
                        onChange={(e) => setPassDeactive(e.target.value)}
                        onFocus={() => setErrorDeactivate("")}
                        onBlur={() => {
                          if (Validation.empty(passDeactive)) {
                            setErrorDeactivate("Please enter your password");
                          }
                        }}
                      />
                      {errorDeactivate && (
                        <span style={{ color: "red" }}>{errorDeactivate}</span>
                      )}
                    </div>
                    <div className="btn-row text-center">
                      <button
                        className="primary-button"
                        type="button"
                        onClick={() => {
                          if (Validation.empty(reasonDeactive)) {
                            setErrorReasonDeactivate("Please enter the reason");
                          } else if (Validation.empty(passDeactive)) {
                            setErrorDeactivate("Please enter your password");
                            return;
                          } else {
                            setConfirmDeactivate(true);
                          }
                        }}
                      >
                        Deactivate
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
          <div className={`overlay ${showDeactivate && "show"}`}></div>
        </>
      ) : tab === 2 ? (
        <PaymentMethods setTab={setTab} setSelectedTab={setSelectedTab} />
      ) : tab === 3 ? (
        <BankingInfo setTab={setTab} setSelectedTab={setSelectedTab} />
      ) : (
        <Vouchers setTab={setTab} setSelectedTab={setSelectedTab} />
      )}
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    myAccountData: USER_REDUCER.myAccountData,
  };
};

export default connect(mapStateToProps, { MyAccountInfo, })(withRouter(MyAccount));