import React, { useEffect, useState, useRef, useCallback } from "react";
import iconFilter from "../../../assets/img/icon-filter.svg";
import iconPlay from "../../../assets/img/play-button.png";
import Loader from "../../loader/Loader";
import { GetEventList } from "../../../redux/actions/events";
import { GetCategoryList } from "../../../redux/actions/user";
import { getEventLocalDate } from "../../../helpers/constants";

function AllEvents({ setSelectedTab, setEventID }) {
  const [openFilter, setOpenFilter] = useState(false);
  const [paramsPayload, setParamsPayload] = useState({
    skip: 0, // Integer
    limit: 12, // Integer
    category: "allEvents", // String, Array
    lowTohigh: "", //0,1 Boolean
    highTolow: "", // 0,1 Boolean
    minAmount: "", // Integer
    maxAmount: "", // Integer
    searchString: "", // String
  });
  const [eventsData, setEventsData] = useState([]);
  const [priceSort, setPriceSort] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [priceFilter, setPriceFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [catFilterArray, setCatFilterArray] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [flag, setFlag] = useState(false);

  const observer = useRef();

  const loadMore = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      if (!hasMore || loading) return;
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setParamsPayload({ ...paramsPayload, skip: paramsPayload.skip + 12 });
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading]
  );

  useEffect(() => {
    document.title = "LiveStage | All Events";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // Fetch Events List
  useEffect(() => {
    console.log(paramsPayload);
    console.log("Event List API called in AllEvents");
    setLoading(true);
    GetEventList(paramsPayload)
      .then((response) => {
        setEventsData([...eventsData, ...response?.data?.data]);
        setHasMore(
          response.data.count -
          (eventsData.length + response?.data?.data.length) >
          0
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [paramsPayload]);

  // Fetch Category List
  useEffect(() => {
    GetCategoryList()
      .then((response) => {
        setCategoryList([...response.data.data]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Search Events
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (flag) {
        setEventsData([]);
        setParamsPayload({
          ...paramsPayload,
          skip: 0,
          searchString: searchKeyword,
        });
      }
    }, 750);
    setFlag(true);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchKeyword]);

  // Handle Price Sort
  const handlePriceSortChange = (text) => {
    setPriceSort(text);
    setEventsData([]);
    if (text === "HTL") {
      setParamsPayload({
        ...paramsPayload,
        skip: 0,
        lowTohigh: 0,
        highTolow: 1,
      });
    } else if (text === "LTH") {
      setParamsPayload({
        ...paramsPayload,
        skip: 0,
        lowTohigh: 1,
        highTolow: 0,
      });
    } else {
      setParamsPayload({
        ...paramsPayload,
        skip: 0,
        lowTohigh: "",
        highTolow: "",
      });
    }
  };

  const handlePriceFilterText = (text) => {
    setPriceFilter(text);
  };

  // Handle Apply Filter
  const handleApplyFilters = () => {
    setEventsData([]);

    var minAmt = 0,
      maxAmt = 0;
    if (priceFilter === "Below €15") {
      maxAmt = parseInt(15);
    } else if (priceFilter === "€16 - €20") {
      minAmt = parseInt(16);
      maxAmt = parseInt(20);
    } else if (priceFilter === "€20 - €25") {
      minAmt = parseInt(20);
      maxAmt = parseInt(25);
    } else if (priceFilter === "Above €25") {
      minAmt = parseInt(25);
    }

    setParamsPayload({
      ...paramsPayload,
      skip: 0,
      category:
        catFilterArray.length !== 0 ? catFilterArray.join() : "allEvents",
      minAmount: minAmt === 0 && maxAmt === 0 ? "" : minAmt,
      maxAmount: minAmt === 0 && maxAmt === 0 ? "" : maxAmt,
    });
    setOpenFilter(false);
  };

  // Handle Clear All Filters
  const handleClearAll = () => {
    setEventsData([]);
    setParamsPayload({
      skip: 0,
      limit: 12,
      category: "allEvents",
      lowTohigh: "",
      highTolow: "",
      minAmount: "",
      maxAmount: "",
      searchString: "",
    });
    setPriceSort("");
    Array.from(
      document.querySelectorAll('input[name="price"]:checked'),
      (input) => (input.checked = false)
    );
    Array.from(
      document.querySelectorAll('input[name="CatChecklist"]:checked'),
      (input) => (input.checked = false)
    );
    setCatFilterArray([]);
    setPriceFilter("");
    setOpenFilter(false);
    setSearchKeyword("");
  };

  return (
    <div>
      <section className="events-section">
        <div className="flex-container">
          <div className="events-listing enduser-events">
            <div className="title">
              <h2>All Events</h2>
              <div className="sorting-auction">
                <div className="sortby-dropdown" onClick={() => setOpenFilter(false)}>
                  <select
                    value={priceSort}
                    onChange={(e) => handlePriceSortChange(e.target.value)}
                  >
                    <option value="" style={{ backgroundColor: "black" }}>
                      Sort By
                    </option>
                    <option value="LTH" style={{ backgroundColor: "black" }}>
                      Price - Low to High
                    </option>
                    <option value="HTL" style={{ backgroundColor: "black" }}>
                      Price - High to Low
                    </option>
                  </select>
                </div>
                <div className="filterby fliter-new">
                  <a
                    className="cursorPointer"
                    onClick={() => setOpenFilter(!openFilter)}
                  >
                    <img src={iconFilter} alt="filter" />
                  </a>
                  <div
                    className={`filterby-options filter-opt-2 reveal-filter-1 ${openFilter && "slow"
                      }`}
                  >
                    <div className="filter-menu-wrapper">
                      <div className="filter-title">
                        <h5>Filter</h5>
                      </div>
                    </div>
                    <div className="field-wrap filter-m-wrap checkbox">
                      <label className="empty-change">Price</label>

                      <div className="ticket filter-item-change">
                        <label className="pure-checkbox">
                          <input
                            type="radio"
                            name="price"
                            value="Below €15"
                            id="price1"
                            onChange={(e) =>
                              handlePriceFilterText(e.target.value)
                            }
                          />
                          <span className="checkmark check-filer-m"></span>Below
                          €15
                        </label>
                      </div>
                      <div className="ticket filter-item-change">
                        <label className="pure-checkbox">
                          <input
                            type="radio"
                            name="price"
                            value="€16 - €20"
                            id="price2"
                            onChange={(e) =>
                              handlePriceFilterText(e.target.value)
                            }
                          />
                          <span className="checkmark check-filer-m"></span>€16 -
                          €20
                        </label>
                      </div>
                      <div className="ticket filter-item-change">
                        <label className="pure-checkbox">
                          <input
                            type="radio"
                            name="price"
                            value="€20 - €25"
                            id="price3"
                            onChange={(e) =>
                              handlePriceFilterText(e.target.value)
                            }
                          />
                          <span className="checkmark check-filer-m"></span>€20 -
                          €25
                        </label>
                      </div>
                      <div className="ticket filter-item-change">
                        <label className="pure-checkbox">
                          <input
                            type="radio"
                            name="price"
                            value="Above €25"
                            id="price4"
                            onChange={(e) =>
                              handlePriceFilterText(e.target.value)
                            }
                          />
                          <span className="checkmark check-filer-m"></span>Above
                          €25
                        </label>
                      </div>
                    </div>

                    <div className="field-wrap filter-m-wrap checkbox">
                      <label className="empty-change">Category</label>

                      {categoryList.map(
                        (cat, index) =>
                          !cat.actorCategory && (
                            <div
                              className="ticket filter-item-change"
                              key={index}
                            >
                              <label className="pure-checkbox">
                                <input
                                  type="checkbox"
                                  value={cat.categoryName}
                                  name="CatChecklist"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      let newArray = [...catFilterArray];

                                      newArray.push(e.target.value);

                                      setCatFilterArray(newArray);
                                    } else {
                                      let filtered = catFilterArray.filter(
                                        (item) => item !== e.target.value
                                      );

                                      setCatFilterArray(filtered);
                                    }
                                  }}
                                />
                                <span className="checkmark check-filer-m"></span>
                                {cat.categoryName}
                              </label>
                            </div>
                          )
                      )}

                      <div className="filter-btn-row">
                        <div className="filter-btn-col">
                          <button
                            type="button"
                            className="primary-button prime-btn-filter btn-res-fix"
                            onClick={() => handleApplyFilters()}
                          >
                            Apply
                          </button>
                        </div>
                        <div className="filter-btn-col">
                          <button
                            type="button"
                            className="primary-button prime-btn-filter btn-res-fix"
                            onClick={() => handleClearAll()}
                          >
                            Clear All
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="events-search">
              <input
                type="text"
                placeholder="Search"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
            </div>

            <div className="flex-row gutter-space">
              {eventsData.length > 0 ? (
                eventsData.map((eventItem, index) => {
                  if (eventsData.length === index + 1) {
                    return (
                      <div
                        className="loop flex-col-4"
                        key={index}
                        ref={loadMore}
                      >
                        <div
                          className="grey-wrap item cursorPointer"
                          onClick={() => {
                            setEventID(eventItem._id);
                            setSelectedTab(7);
                          }}
                        >
                          <div className="img">
                            <div
                              onMouseOver={(e) => {
                                e.target.style.backgroundImage = `url(${eventItem.videoStatus ? eventItem.gifURL : eventItem.eventImage})`
                              }}
                              onMouseOut={(e) => {
                                e.target.style.backgroundImage = `url(${eventItem.videoStatus
                                  ? eventItem.thumbnailImage
                                  : eventItem.eventImage})`
                              }}
                              style={{
                                minHeight: "150px",
                                width: "100%",
                                backgroundImage: `url(${eventItem.videoStatus
                                  ? eventItem.thumbnailImage
                                  : eventItem.eventImage
                                  })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            ></div>
                            {eventItem.videoStatus && <div style={{
                              position: "absolute",
                              top: "155px"
                            }}><img src={iconPlay} style={{ width: "40px" }} /></div>}
                          </div>
                          <div className="details">
                            <div className="name">
                              <h3 title={eventItem.eventTitle}>
                                {eventItem.eventTitle.length > 19
                                  ? eventItem.eventTitle.slice(0, 19) + "..."
                                  : eventItem.eventTitle}
                              </h3>
                              <span>
                                €
                                {eventItem.earlyBirdTicket > 0
                                  ? parseFloat(
                                    eventItem.newEarlyBirdPrice
                                  ).toFixed(2)
                                  : parseFloat(
                                    eventItem.newPrice
                                  ).toFixed(2)}
                              </span>
                            </div>
                            <div className="ticket">{(`${eventItem.userId.firstName} ${eventItem.userId.lastName}`).length > 28 ?
                              (`${eventItem.userId.firstName} ${eventItem.userId.lastName}`).slice(0, 28) + "..." : `${eventItem.userId.firstName} ${eventItem.userId.lastName}`}</div>
                            {eventItem.earlyBirdTicket > 0 && (
                              <div className="ticket">
                                Earlybird Tickets Available
                                <span>{eventItem.earlyBirdTicket}</span>
                              </div>
                            )}
                            <div className="ticket">
                              Tickets Available
                              <span>
                                {eventItem.ticketType === "Unlimited" ||
                                  eventItem.audienceType === "Corporate"
                                  ? "Available"
                                  : eventItem?.availableTickets}
                              </span>
                            </div>
                            <div className="date-time">
                              {getEventLocalDate(
                                eventItem.eventDate,
                                eventItem.eventTime,
                                eventItem.setTimeZone
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className="loop flex-col-4" key={index}>
                        <div
                          className="grey-wrap item cursorPointer"
                          onClick={() => {
                            setEventID(eventItem._id);
                            setSelectedTab(7);
                          }}
                        >
                          <div className="img">
                            <div
                              onMouseOver={(e) => {
                                e.target.style.backgroundImage = `url(${eventItem.videoStatus ? eventItem.gifURL : eventItem.eventImage})`
                              }}
                              onMouseOut={(e) => {
                                e.target.style.backgroundImage = `url(${eventItem.videoStatus
                                  ? eventItem.thumbnailImage
                                  : eventItem.eventImage})`
                              }}
                              style={{
                                minHeight: "150px",
                                width: "100%",
                                backgroundImage: `url(${eventItem.videoStatus
                                  ? eventItem.thumbnailImage
                                  : eventItem.eventImage
                                  })`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            ></div>
                            {eventItem.videoStatus && <div style={{
                              position: "absolute",
                              top: "155px"
                            }}><img src={iconPlay} style={{ width: "40px" }} /></div>}
                          </div>
                          <div className="details">
                            <div className="name">
                              <h3 title={eventItem.eventTitle}>
                                {eventItem.eventTitle.length > 19
                                  ? eventItem.eventTitle.slice(0, 19) + "..."
                                  : eventItem.eventTitle}
                              </h3>
                              <span>
                                €
                                {eventItem.earlyBirdTicket > 0
                                  ? parseFloat(
                                    eventItem.newEarlyBirdPrice
                                  ).toFixed(2)
                                  : parseFloat(
                                    eventItem.newPrice
                                  ).toFixed(2)}
                              </span>
                            </div>
                            <div className="ticket">{(`${eventItem.userId.firstName} ${eventItem.userId.lastName}`).length > 28 ?
                              (`${eventItem.userId.firstName} ${eventItem.userId.lastName}`).slice(0, 28) + "..." : `${eventItem.userId.firstName} ${eventItem.userId.lastName}`}</div>
                            {eventItem.earlyBirdTicket > 0 && (
                              <div className="ticket">
                                Earlybird Tickets Available
                                <span>{eventItem.earlyBirdTicket}</span>
                              </div>
                            )}
                            <div className="ticket">
                              Tickets Available
                              <span>
                                {eventItem.ticketType === "Unlimited" ||
                                  eventItem.audienceType === "Corporate"
                                  ? "Available"
                                  : eventItem?.availableTickets}
                              </span>
                            </div>
                            <div className="date-time">
                              {getEventLocalDate(
                                eventItem.eventDate,
                                eventItem.eventTime,
                                eventItem.setTimeZone
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <div>{loading ? "" : "No Events Found"}</div>
              )}
            </div>
            {loading && <Loader />}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AllEvents;