import React, { useCallback, useEffect, useRef, useState } from "react";
import Home from "./actorHome";
import AllEvents from "./allEvents";
import ContactUs from "../contactus/ContactUs";
import CreateEvent from "./createEvents/index";
import canvasmenu from "../../assets/img/icon-canvasmenu.svg";
import dpPhoto from "../../assets/img/img_avatar.png";
import logo from "../../assets/img/logo.svg";
import homeMenu from "../../assets/img/home-menu.svg";
import myEventMenu from "../../assets/img/myevent-menu.svg";
import createEventMenu from "../../assets/img/createevent-menu.svg";
import iconBellM from "../../assets/img/icon-bel-m.svg";
import iconAccountM from "../../assets/img/icon-account-m.svg";
import iconAboutM from "../../assets/img/icon-about-m.svg";
import iconContactM from "../../assets/img/icon-contact-m.svg";
import iconLogoutM from "../../assets/img/icon-logout-m.svg";
import iconMute from "../../assets/img/icon-mute.png";
import iconUnMute from "../../assets/img/icon-unmute.png";
import AboutUs from "../aboutus/AboutUs";
import Store from "../../redux/store/store";
import { connect } from "react-redux";
import {
  GetNotifications,
  LogOutAction,
  MyAccountInfo,
  MarkAsRead,
} from "../../redux/actions/user";
import MyAccount from "../myAccount/MyAccount";
import HomeCreateEvent from "./homeCreateEvent";
import { GetMyEvents, SearchEvent } from "../../redux/actions/events";
import Wrapper from "../../config/Wrapper";
import moment from "moment-timezone";
import Loader from "../loader/Loader";
import { getEventLocalDate } from "../../helpers/constants";
import firebase from "firebase";

function HomeTabsActor({
  history,
  profilePhoto,
  MyAccountInfo,
  userName,
  GetMyEvents,
  vidStatus,
  eventFile,
  showActorFile,
  isUserDataExists
}) {
  const [selectedTab, setSelectedTab] = useState(1);
  const [noti, setNoti] = useState(false);
  const [isUserData, setIsUserData] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const [flag, setFlag] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [paramsPayload, setParamsPayload] = useState({
    skip: 0, // Integer
    limit: 12, // Integer
  });
  const localTZone = moment.tz.guess();
  const [videoMute, setVideoMute] = useState(true);
  const [resetTab, setResetTab] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [showSugg, setShowSugg] = useState(false);
  const [reRender, setReRender] = useState(false);
  const messaging = firebase.messaging();
  messaging.onMessage((payload) => { setReRender(!reRender); });
  const observer = useRef();

  const loadMore = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      if (!hasMore || loading) return;
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setParamsPayload({ ...paramsPayload, skip: paramsPayload.skip + 12 });
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, loading]
  );

  const { dispatch } = Store.store;

  useEffect(() => {
    document.title = "LiveStage | Actor";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // Notifications
  useEffect(() => {
    setLoading(true);
    GetNotifications(paramsPayload)
      .then((response) => {
        setNotificationList([...notificationList, ...response.data.data]);
        setIsNewNotification(response.data.isNewNotification);
        setHasMore(
          response.data.count -
          (notificationList.length + response.data.data.length) >
          0
        );
        setLoading(false);
      })
      .catch((error) => {
        setHasMore(false);
        setLoading(false);
      });
  }, [paramsPayload]);

  useEffect(() => {
    setLoading(true);
    GetNotifications(paramsPayload)
      .then((response) => {
        setNotificationList(response.data.data);
        setIsNewNotification(response.data.isNewNotification);
        setLoading(false);
      })
      .catch((error) => {
        setHasMore(false);
        setLoading(false);
      });
  }, [reRender]);

  const toggleTab = (index) => {
    setSelectedTab(index);
  };

  const handleNotification = () => {
    setNoti(!noti);
  };

  const isFetched = (response) => {
    if (
      response.status === 200 &&
      (response.data.data.pastData.length > 0 ||
        response.data.data.upCommingData.length > 0)
    ) {
      setIsUserData(true);
      Store.store.dispatch({ type: "UPDATE_USERDATA", payload: true })
    } else {
      setIsUserData(false);
      Store.store.dispatch({ type: "UPDATE_USERDATA", payload: false })
    }
  };

  useEffect(() => {
    MyAccountInfo();
    GetMyEvents(isFetched);
  }, []);

  // Mark Notifications as read
  const handleMarkAsRead = async (type, id = "") => {
    let payload = {
      type: type,
      notificationId: id,
    };

    try {
      const response = await MarkAsRead(payload);

      if (response.status === 200) {
        setNotificationList([]);
        setParamsPayload({ ...paramsPayload, skip: 0 });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVideoMute = () => {
    setVideoMute(!videoMute);
  };

  useEffect(() => {
    if (vidStatus) {
      const video = document.getElementById("videoElement");
      video?.load();
    }
  }, [eventFile]);

  useEffect(() => {
    localStorage.removeItem("notiEventID");
  }, [history.location.pathname]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchText.length > 2) {
        SearchEvent({ searchString: searchText })
          .then((response) => {
            setShowSugg(true);
            setSearchSuggestions([...response?.data?.data]);
          })
          .catch((error) => console.log(error));
      } else {
        setShowSugg(false);
      }
    }, 750);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchText]);

  const handleOnBlurSearch = () => {
    setShowSugg(false);
    setSearchText("");
    setShowSearch(false);
  };

  return (
    <div>
      <div
        id="app"
        className={
          selectedTab === 1
            ? "for-overlay page-home event-height"
            : selectedTab === 2
              ? "for-overlay page-events event-height"
              : selectedTab === 3
                ? "for-overlay page-create-event"
                : "for-overlay page-home"
        }
        style={{
          backgroundImage: `url(${!vidStatus && showActorFile && eventFile})`,
          backgroundRepeat: "no-repeat",
          height: "750px",
          width: "100%",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {vidStatus && showActorFile && (
          <div className="bg_video">
            <div
              className="cursorPointer video-mute-div mIcon"
              onClick={() => handleVideoMute()}
            >
              {videoMute ? <img src={iconMute} /> : <img src={iconUnMute} />}
            </div>
            <video
              id="videoElement"
              width="100%"
              autoPlay={true}
              loop={true}
              muted={videoMute}
            >
              <source src={eventFile} />
            </video>
          </div>
        )}
        {showActorFile && <div className="layer"></div>}

        <header
          className="app-header"
          style={{
            position: "relative",
            zIndex: 25,
          }}
        >
          <div className="flex-container">
            <div className="flex-header">
              <button
                className="btn-canvas-menu"
                type="button"
                onClick={() => setShowCanvas(true)}
              >
                <img src={canvasmenu} alt="menu" />
              </button>

              <div className="logo">
                <a href="/home/actor">
                  <img src={logo} alt="logo" />
                </a>
              </div>

              <nav className="app-menu">
                <ul>
                  <li className={selectedTab === 1 ? "active" : ""}>
                    <a
                      className="cursorPointer"
                      onClick={() => {
                        toggleTab(1);
                        dispatch({ type: "EMPTY_EVENTID" });
                      }}
                    >
                      <img src={homeMenu} alt="Home" /> Home
                    </a>
                  </li>
                  <li className={selectedTab === 2 ? "active" : ""}>
                    <a
                      className="cursorPointer"
                      onClick={() => {
                        toggleTab(2);
                        dispatch({ type: "EMPTY_EVENTID" });
                      }}
                    >
                      <img src={myEventMenu} alt="My Events" /> My Events
                    </a>
                  </li>
                  <li className={selectedTab === 3 ? "active" : ""}>
                    <a
                      className="cursorPointer"
                      onClick={() => {
                        toggleTab(3);
                        dispatch({ type: "EMPTY_EVENTID" });
                      }}
                    >
                      <img src={createEventMenu} alt="Create Events" /> Create
                      Events
                    </a>
                  </li>
                  <li className={selectedTab === 5 ? "active" : ""}>
                    <a
                      className="cursorPointer"
                      onClick={() => {
                        toggleTab(5);
                        dispatch({ type: "EMPTY_EVENTID" });
                        setResetTab(!resetTab);
                      }}
                    >
                      <img src={iconAccountM} alt="My Account" /> My Account
                    </a>
                  </li>
                </ul>
              </nav>

              <div className="account-links">
                <div className="search-header-wrapper">
                  <div className={`searchBox ${showSearch && "input"}`}>
                    <div className="inputBox">
                      <form autoComplete="off">
                        <input
                          className="srch-in-field"
                          type="searchInput"
                          placeholder="Search"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          onBlur={() => handleOnBlurSearch()}
                        />
                      </form>
                      <button
                        className="srch-bnts"
                        onClick={() => setShowSearch(true)}
                      >
                        <span></span>
                      </button>
                    </div>
                    <span
                      className={`closeBtn ${!showSearch && "cls-btn-hide"}`}
                      onClick={() => {
                        setShowSugg(false);
                        setSearchText("");
                        setShowSearch(false);
                      }}
                    ></span>
                    <div
                      className={`sugesst-box-main ${!showSugg && "hide-suggestions"
                        }`}
                    >
                      <ul className="sugesst-box">
                        {searchSuggestions.length > 0
                          ? searchSuggestions.map((item, index) => (
                            <li
                              className="sugesstion cursorPointer"
                              key={index}
                              onClick={() => {
                                dispatch({ type: "SET_EVENTID", payload: { notiEventID: item._id, notiBookingID: "" } })
                                setShowSugg(false);
                                setSearchText("");
                                setShowSearch(false);
                                setSelectedTab(1);
                              }}
                            >
                              <a>{item.eventTitle}</a>
                              <span className="searchTime">
                                {
                                  getEventLocalDate(
                                    item.eventDate,
                                    item.eventTime,
                                    item.setTimeZone
                                  ).split(",")[0]
                                }
                                ,
                                {
                                  getEventLocalDate(
                                    item.eventDate,
                                    item.eventTime,
                                    item.setTimeZone
                                  ).split(",")[1]
                                }
                              </span>
                            </li>
                          ))
                          : "No Event Found"}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="notification-bell">
                  <a
                    className="icon cursorPointer"
                    onClick={(e) => handleNotification()}
                  >
                    Notification
                  </a>
                  {isNewNotification && <span className="count"></span>}
                </div>
                <div className="account-dropdown">
                  <a className="icon cursorPointer">
                    <img
                      src={profilePhoto ? profilePhoto : dpPhoto}
                      alt="User"
                    />
                  </a>
                  <ul>
                    <li>
                      <a
                        className="cursorPointer"
                        onClick={() => setSelectedTab(6)}
                      >
                        About Livestage
                      </a>
                    </li>
                    <li>
                      <a
                        className="cursorPointer"
                        onClick={(e) => dispatch(LogOutAction())}
                      >
                        Sign Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>

        {selectedTab === 1 ? (
          <>
            {(() => {
              history.location.pathname !== "/home/actor" &&
                history.push("/home/actor");
            })()}
            {isUserDataExists ? <Home setSelectedTab={setSelectedTab} flag={flag} /> : <HomeCreateEvent setSelectedTab={setSelectedTab} />}
          </>) : selectedTab === 2 ? (
            <>
              {(() => {
                history.location.pathname !== "/home/actor/allevents" &&
                  history.push("/home/actor/allevents");
              })()}
              <AllEvents setSelectedTab={setSelectedTab} flag={flag} />
            </>
          ) : selectedTab === 3 ? (
            <>
              {(() => {
                history.location.pathname !== "/home/actor/createevent" &&
                  history.push("/home/actor/createevent");
              })()}
              <Wrapper
                path="/home/actor/createevent"
                Component={<CreateEvent setSelectedTab={setSelectedTab} />}
              />
            </>
          ) : selectedTab === 4 ? (
            <>
              {(() => {
                history.location.pathname !== "/home/actor/contactus" &&
                  history.push("/home/actor/contactus");
              })()}
              <Wrapper path="/home/actor/contactus" Component={<ContactUs />} />
            </>
          ) : selectedTab === 5 ? (
            <>
              {(() => {
                history.location.pathname !== "/home/actor/myaccount" &&
                  history.push("/home/actor/myaccount");
              })()}
              <MyAccount setSelectedTab={setSelectedTab} resetTab={resetTab} />
            </>
          ) : (
          <>
            {(() => {
              history.location.pathname !== "/home/actor/aboutus" &&
                history.push("/home/actor/aboutus");
            })()}
            <Wrapper
              path="/home/actor/aboutus"
              Component={<AboutUs setSelectedTab={setSelectedTab} />}
            />
          </>
        )}

        <footer className="app-footer">
          <div className="flex-container">
            <div className="footer-content">
              <div className="question">Questions? Call 415-784-458</div>
              <div className="footer-menu">
                <ul>
                  <li>
                    <a href="/faq">FAQ</a>
                  </li>
                  <li>
                    <a href="/termsAndConditions">Terms And Conditions</a>
                  </li>
                  <li>
                    <a href="/cookiePreference">Cookie Policy</a>
                  </li>
                  <li>
                    <a href="/helpCentre">Help Centre</a>
                  </li>
                  <li>
                    <a href="/privacy">Privacy</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="copyright">
              Copyright 2020. All Rights Reserved Live Stage
            </div>
          </div>
        </footer>
      </div>

      {/* Side Menu */}
      <div className={`canvas-menu ${showCanvas && "show"}`}>
        <div className="relative">
          <button
            className="btn-menu-hide"
            type="button"
            onClick={() => setShowCanvas(false)}
          >
            Close
          </button>
          <div className="canvas-menu-items">
            <div className="user">
              <img src={profilePhoto ? profilePhoto : dpPhoto} alt="user" />
              <h3>Hi {userName},</h3>
              <h4>Welcome</h4>
            </div>
            <ul>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    setSelectedTab(1);
                    setShowCanvas(false);
                  }}
                >
                  <img src={homeMenu} alt="nav" /> Events Dashboard
                </a>
              </li>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    setSelectedTab(3);
                    setShowCanvas(false);
                  }}
                >
                  <img src={createEventMenu} alt="nav" /> Create Events
                </a>
              </li>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    setSelectedTab(2);
                    setShowCanvas(false);
                  }}
                >
                  <img src={myEventMenu} alt="nav" /> My Events
                </a>
              </li>
              <li>
                <a className="cursorPointer"
                  onClick={() => {
                    setShowCanvas(false);
                    handleNotification();
                  }}
                >
                  <img src={iconBellM} alt="nav" /> Notifications
                </a>
              </li>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    setSelectedTab(5);
                    setShowCanvas(false);
                  }}
                >
                  <img src={iconAccountM} alt="nav" /> My Account
                </a>
              </li>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    setSelectedTab(6);
                    setShowCanvas(false);
                  }}
                >
                  <img src={iconAboutM} alt="nav" /> About Livestage
                </a>
              </li>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => {
                    setSelectedTab(4);
                    setShowCanvas(false);
                  }}
                >
                  <img src={iconContactM} alt="nav" /> Contact Us
                </a>
              </li>
              <li>
                <a
                  className="cursorPointer"
                  onClick={() => dispatch(LogOutAction())}
                >
                  <img src={iconLogoutM} alt="nav" /> Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div
        className={`sidebar-model notification-sidebar-model ${noti && "show"}`}
        style={{ background: "#0e0e0e" }}
      >
        <div className="model-head">
          <h2>Notifications</h2>
          <a
            className="model-close cursorPointer"
            onClick={(e) => handleNotification()}
          >
            &#10006;
          </a>
        </div>
        <div className="model-body">
          <div style={{ textAlign: "end" }}>
            <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                if (isNewNotification) {
                  handleMarkAsRead("multiple");
                }
              }}
            >
              Mark all as read
            </span>
          </div>
          <div className="notification-items">
            {notificationList.length > 0 ? (
              notificationList?.map((notif, index) => {
                if (notificationList.length === index + 1) {

                  return (
                    <>
                      <div
                        className="loop item"
                        key={index}
                        style={{ background: notif.markRead && "#0e0e0e" }}
                        ref={loadMore}
                      >
                        <div
                          className="flex-item cursorPointer"
                          style={{ flexWrap: "nowrap" }}
                          onClick={() => {
                            if (!notif.markRead) {
                              handleMarkAsRead("single", notif._id);
                            }
                            if (notif.type === "1") {
                              dispatch({ type: "SET_EVENTID", payload: { notiEventID: notif.eventId, notiBookingID: notif.bookingId } })
                              setSelectedTab(1);
                              setNoti(!noti);
                            }
                          }}
                        >
                          <div className="img" style={{ width: "190px" }}>
                            <div
                              style={{
                                height: "130px",
                                borderRadius: "5px",
                                verticalAlign: "bottom",
                                backgroundImage: `url(${notif.eventImage})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            ></div>
                          </div>
                          <div className="content" style={{ width: "100%" }}>
                            <div className="title">
                              <h4>{notif.eventName}</h4>
                            </div>
                            <div>
                              <p className="date">
                                {moment
                                  .tz(`${notif.createdAt}`, `${localTZone}`)
                                  .format("DD MMM, hh:mm A")}
                              </p>
                            </div>
                            <br></br>
                            <p>{notif.message}</p>
                          </div>
                        </div>
                      </div>
                      {notif.markRead && <hr />}
                    </>
                  );
                } else {
                  return (
                    <>
                      <div
                        className="loop item"
                        key={index}
                        style={{ background: notif.markRead && "#0e0e0e" }}
                      >
                        <div
                          className="flex-item cursorPointer"
                          style={{ flexWrap: "nowrap" }}
                          onClick={() => {
                            if (!notif.markRead) {
                              handleMarkAsRead("single", notif._id);
                            }
                            if (notif.type === "1") {
                              dispatch({ type: "SET_EVENTID", payload: { notiEventID: notif.eventId, notiBookingID: notif.bookingId } })
                              setSelectedTab(1);
                              setNoti(!noti);
                            }
                          }}
                        >
                          <div className="img" style={{ width: "190px" }}>
                            <div
                              style={{
                                height: "130px",
                                borderRadius: "5px",
                                verticalAlign: "bottom",
                                backgroundImage: `url(${notif.eventImage})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                              }}
                            ></div>
                          </div>
                          <div className="content" style={{ width: "100%" }}>
                            <div className="title">
                              <h4>{notif.eventName}</h4>
                            </div>
                            <div>
                              <p className="date">
                                {moment
                                  .tz(`${notif.createdAt}`, `${localTZone}`)
                                  .format("DD MMM, hh:mm A")}
                              </p>
                            </div>
                            <br></br>
                            <p>{notif.message}</p>
                          </div>
                        </div>
                      </div>
                      {notif.markRead && <hr />}
                    </>
                  );
                }
              })
            ) : (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                {loading ? '' : 'No Notifications Found'}
              </div>
            )}
          </div>
          <div>{loading && <Loader />}</div>
        </div>
      </div>
      <div className={`overlay ${noti && "show"}`}></div>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER, EVENT_FILE, EVENTS_REDUCER }) => {
  return {
    profilePhoto: USER_REDUCER.myAccountData?.profilePhoto,
    userName: USER_REDUCER.myAccountData?.firstName,
    vidStatus: EVENT_FILE.videoStatus,
    eventFile: EVENT_FILE.eventFile,
    showActorFile: EVENT_FILE.showActorFile,
    isUserDataExists: EVENTS_REDUCER.isUserDataExists
  };
};

export default connect(mapStateToProps, {
  LogOutAction,
  MyAccountInfo,
  GetMyEvents,
})(HomeTabsActor);