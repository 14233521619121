import firebase from "firebase";
import platform from "platform";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

export const getToken = () => {
  if (platform.name == "Chrome" || platform.name == "Microsoft Edge" || platform.name == "Firefox") {
    const messaging = firebase.messaging();
    return messaging
      .getToken({
        vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID_KEY,
      })
      .then((currentToken) => {
        if (currentToken) {
          localStorage.setItem("fcmToken", currentToken);
        } else {
          console.error(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch((err) => {
        console.error("An error occurred while retrieving token. ", err);
      });
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (platform.name === "Chrome" && platform.name === "Microsoft Edge" && platform.name === "Firefox") {
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    }
  });

export default firebase;