import Api, { baseURL } from "../api/api";
import { Notify } from "../../helpers/notifications";

const cookie = (key) =>
  (new RegExp((key || "=") + "=(.*?); ", "gm").exec(document.cookie + "; ") || [
    "",
    "null",
  ])[1];

// Manual Login for End User, Corporate User & Actor
export const LoginAction = (payload, cb) => (dispatch) => {
  Api.post("api/v1/auth/login", payload)
    .then((response) => {
      cb(response.status);
      if (response.data.role == 'Admin' || response.data.role == 'admin' || response.data.role == 'subAdmin') {
        Notify("danger", "This account is registered as an admin account");
      } else {
        localStorage.setItem("petros-user-token", response.data.data);
        dispatch({ type: "LOGIN", payload: response.data.user });
        if (cookie("UserConsent").includes("prefer")) {
          document.cookie = `jwtTokenPetros=${response.data.data}; path=/; expires=${new Date(new Date().getFullYear() + 100, new Date().getMonth(), new Date().getDate())}`;
        } else {
          document.cookie = `jwtTokenPetros=${response.data.data}; path=/`;
        }
        localStorage.setItem("isLogout", false);
        Notify("success", "Logged in successfully!!");
      }
    })
    .catch((err) => {
      cb(err.response.data.message);
      Notify("danger", `Error: ${err.response.data.message}`);
    });
};

// Verify Email
export const verifyEmail = (token) => {
  Api.post(`api/v1/auth/verify/email/${token}`)
    .then((res) => console.log("Email Verified Successfully"))
    .catch((err) => console.log("Error occured in verification", err));
};

// Signup Api for End User, Corporate User and Actor
export const RegisterUserActor = (payload) => {
  return Api.post("api/v1/auth/signup", payload);
};

// FileUpload Api to upload images/ videos
export const FileUpload = (file) => {
  return Api.post("api/v1/upload", file, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};


// FileUpload Api to upload images/ videos
export const FileUploadAdmin = (file) => {
  return Api.post("api/v1/uploadAdmin", file, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
};

// Social Login
export const SocialLogin = (payload) => {
  return Api.post("api/v1/auth/socialLogin", payload, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Bank Details for Actor
export const BankDetails = (payload) => {
  const token = localStorage.getItem("petros-user-token");
  return Api.post("api/v1/users/addBankDetails", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Forgot Password
export const ForgotPass = (payload) => {
  return Api.post("api/v1/auth/forgotPassword", payload);
};

// Reset Password
export const ResetPass = (payload) => {
  return Api.post("api/v1/auth/resetPasswordWeb", payload);
};

// Get CategoryList
export const GetCategoryList = () => {
  return Api.get("/api/v1/auth/categoryList", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Get MyAccount Details
export const MyAccountInfo = () => (dispatch) => {
  const token = cookie("jwtTokenPetros");
  Api.get("api/v1/users/userInfo", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => {
      dispatch({ type: "MYACCOUNT", payload: response.data.data });
    })
    .catch((err) => {
      console.log(err, "Error fetching details");
    });
};

// Update MyAccount Details
export const UpdateUserInfo = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.put("api/v1/users/updateUserInfo", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Update Password
export const UpdatePwd = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.post("/api/v1/auth/updatePassword", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Get Bank Details of Actor
export const GetBankDetails = () => {
  const token = cookie("jwtTokenPetros");
  return Api.get("/api/v1/users/getBankDetails", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Update Bank Details
export const UpdateBankDetails = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.put("/api/v1/users/updateBankDetails", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Get Payment Methods
export const GetPaymentMethods = () => {
  const token = cookie("jwtTokenPetros");
  return Api.get("/api/v1/users/getCardList", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Add Card Details (Payment Methods)
export const AddPaymentMethods = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.post("/api/v1/users/addCardDetails", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Remove Card Details (Payment Methods)
export const DeletePaymentMethods = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.post("/api/v1/users/removeCard", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Fetch Card Details (Payment Methods)
export const FetchPaymentMethod = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.post("/api/v1/users/fetchCardDetails", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Update Card Details (Payment Methods)
export const UpdatePaymentMethod = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.post("/api/v1/users/updateCard", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Update Default Card (Payment Methods)
export const MakeDefaultCard = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.post("/api/v1/users/makeDefaultCard", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Update Social Status
export const UpdateSocialStatus = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.put("/api/v1/users/updateSocialStatus", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Get Voucher List
export const GetVouchers = () => {
  const token = cookie("jwtTokenPetros");
  return Api.get("/api/v1/users/getMyVoucher", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Apply Voucher
export const ApplyVoucher = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.post("/api/v1/users/applyVoucher", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Update Notification Status
export const ChangeNotificationStatus = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.post("/api/v1/users/changeNotificationStatus", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Get Notifications
export const GetNotifications = (params) => {
  const token = cookie("jwtTokenPetros");
  return Api.get("/api/v1/users/getNotification", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      limit: params ? params.limit : 10,
      skip: params ? params.skip : 0,
    },
  });
};

// Mark as Read Notifications
export const MarkAsRead = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.post("/api/v1/users/markRead", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Check Email for Corporate Register
export const CheckEmailForCorporateRegister = (payload) => {
  return Api.post("/api/v1/auth/checkEmailForCorporateRegister", payload, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Logout Function
export const LogOutAction = () => (dispatch) => {
  const token = cookie("jwtTokenPetros");
  Api.get("/api/v1/users/logout", {
    headers: {
      Authorization: `Bearer ${token ? token : localStorage.getItem("petros-user-token")
        }`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => {
      localStorage.removeItem("isLogout");
    })
    .catch((err) => console.log(err));
  document.cookie = "jwtTokenPetros=false; path=/";
  localStorage.removeItem("petros-user-token");
  localStorage.removeItem("petros-unique-id-view");
  localStorage.removeItem("petros-unique-id");
  dispatch({ type: "LOGOUT" });
  dispatch({ type: "EMPTY_DATA" });
};

// De-activate Account (Actor, User, Corporate)
export const DeactivateAccount = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.put("/api/v1/users/deactivateAccount", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// LogOut of All Devices
export const LogOutOfAllDevices = () => {
  const token = cookie("jwtTokenPetros");
  return Api.get("/api/v1/users/logoutAllDevice", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Get Country List
export const GetCountryList = () => {
  return Api.get("/api/v1/auth/countryList", {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Alloww-Origin": "*",
    },
  });
};

// Contact Us
export const ContactUsApi = (payload) => {
  const token = cookie("jwtTokenPetros");
  return Api.post("/api/v1/auth/contactUs", payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};

// Login Status
export const LoginStatus = () => {
  const token = cookie("jwtTokenPetros");
  return Api.get("/api/v1/users/loginStatus", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
};