import React from "react";
import { withRouter } from "react-router";
import danceDownHome from "../../assets/img/dance-down-home.svg";

function HomeCreateEvent({ setSelectedTab }) {
  return (
    <div>
      <section className="hero-section">
        <div className="flex-container">
          <div className="flex-row">
            <div className="flex-col-2">
              <div className="img">
                <img src={danceDownHome} alt="Hero" />
              </div>
            </div>
            <div className="flex-col-2">
              <div className="content left-space-fix">
                <h1>
                  Create your <span> first event </span>
                  <br />
                  to get started.
                </h1>
                <button
                  type="button"
                  className="primary-button"
                  onClick={() => setSelectedTab(3)}
                >
                  Create Event
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default withRouter(HomeCreateEvent);