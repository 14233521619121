import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EventDetails from "./events/eventDetails";
import ShareEvent from "./shareEvent";
import ViewAll from "./viewAll";
import { GetMyEvents } from "../../redux/actions/events";
import { getEventLocalDate } from "../../helpers/constants";
import IconLike from "../../assets/img/icon-like.svg";
import iconPlay from "../../assets/img/play-button.png";

function AllEvents({ myEventsData, setSelectedTab, GetMyEvents, flag }) {
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [eventIndex, setEventIndex] = useState("");
  const [eventType, setEventType] = useState("");
  const [shareDialog, setShareDialog] = useState({});
  const [showViewAll, setShowViewAll] = useState(false);
  const [viewAllText, setViewAllText] = useState("");

  useEffect(() => {
    document.title = "LiveStage | Actor - My Events";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setShowEventDetails(false);
    setShowViewAll(false);
  }, [flag]);

  useEffect(() => {
    let upCommingData = myEventsData?.upCommingData;
    let pastData = myEventsData?.pastData;

    upCommingData = upCommingData?.map((obj) => {
      return { open: false };
    });

    pastData = pastData?.map((obj) => {
      return { open: false };
    });

    setShareDialog({ upCommingData, pastData });
  }, [myEventsData]);

  const isFetched = (response) => {
    console.log("Data fetched", response.status);
  };

  useEffect(() => {
    GetMyEvents(isFetched);
  }, [showEventDetails]);

  const handleViewEventDetails = (index, type) => {
    setEventIndex(index);
    setEventType(type);
    setShowEventDetails(true);
  };

  const handleOpenShare = (index, text) => {
    let newArray = shareDialog[text].map((item, i) =>
      i === index
        ? {
          ...item,
          open: !item.open,
        }
        : {
          ...item,
          open: false,
        }
    );
    if (text === "pastData") {
      setShareDialog({
        upCommingData: shareDialog["upCommingData"],
        pastData: newArray,
      });
    } else {
      setShareDialog({
        upCommingData: newArray,
        pastData: shareDialog["pastData"],
      });
    }
  };

  const handleViewAll = (catText) => {
    setViewAllText(catText);
    setShowViewAll(true);
  };

  return (
    <div>
      {showEventDetails ? (
        <EventDetails
          setShowEventDetails={setShowEventDetails}
          setSelectedTab={setSelectedTab}
          eventIndex={eventIndex}
          eventType={eventType}
        />
      ) : (
        <>
          {showViewAll ? (
            <ViewAll
              text={viewAllText}
              setShowViewAll={setShowViewAll}
              from={"My Events"}
              setShowEventDetails={setShowEventDetails}
              setEventIndex={setEventIndex}
              setEventType={setEventType}
            />
          ) : (
            <>
              <section className="events-section">
                <div className="flex-container">
                  <div className="events-listing">
                    <div className="title">
                      <h2>Upcoming Events</h2>
                      <a
                        className="viewall cursorPointer"
                        onClick={() => handleViewAll("upCommingData")}
                      >
                        View All
                      </a>
                    </div>

                    {myEventsData?.upCommingData?.length > 0 ? (
                      myEventsData?.upCommingData?.map(
                        (events, index) =>
                          index < 2 && (
                            <div className="loop grey-wrap item" key={index}>
                              <div
                                className="flex-row cursorPointer"
                                onClick={() =>
                                  handleViewEventDetails(index, "upCommingData")
                                }
                              >
                                <div className="col-img">
                                  <div
                                    onMouseOver={(e) => {
                                      e.target.style.backgroundImage = `url(${events.videoStatus ? events.gifURL : events.eventImage})`
                                    }}
                                    onMouseOut={(e) => {
                                      e.target.style.backgroundImage = `url(${events.videoStatus
                                        ? events.thumbnailImage
                                        : events.eventImage})`
                                    }}
                                    style={{
                                      minHeight: "250px",
                                      width: "100%",
                                      backgroundImage: `url(${events.videoStatus
                                        ? events.thumbnailImage
                                        : events.eventImage
                                        })`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  ></div>
                                  {events.videoStatus && <div style={{
                                    position: "absolute",
                                    bottom: "20px"
                                  }}><img src={iconPlay} style={{ width: "40px" }} /></div>}
                                </div>
                                <div className="col-content">
                                  <div className="details">
                                    <h3
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {events.eventTitle}{" "}
                                      {events.isCancelled && (
                                        <span style={{ color: "#e50914" }}>
                                          Event Cancelled
                                        </span>
                                      )}
                                    </h3>
                                    <div
                                      className="description-scroll"
                                      style={{ maxHeight: "100px" }}
                                    >
                                      <p style={{ whiteSpace: "pre-line" }}>
                                        {events.description}
                                      </p>
                                    </div>
                                    <div className="other-detail">
                                      <div className="flex-row">
                                        Total booked tickets{" "}
                                        <span>{events.soldTickets}</span>
                                      </div>
                                      <div className="flex-row">
                                        Date and Time{" "}
                                        <span>
                                          {getEventLocalDate(
                                            events.eventDate,
                                            events.eventTime,
                                            events.setTimeZone
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex-row">
                                        Audience Type{" "}
                                        <span>{events.audienceType}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {!events.isCancelled && (
                                <div className="share-event">
                                  <a
                                    className="cursorPointer"
                                    onClick={() =>
                                      handleOpenShare(index, "upCommingData")
                                    }
                                  >
                                    Share
                                  </a>
                                  <div>
                                    <ShareEvent
                                      open={
                                        shareDialog["upCommingData"]
                                          ? shareDialog["upCommingData"][index]
                                            .open
                                          : false
                                      }
                                      shareEventID={events._id}
                                      shareEventURL={events.share_url}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                      )
                    ) : (
                      <div>No Events Created</div>
                    )}
                  </div>

                  <div className="events-listing">
                    <div className="title">
                      <h2>Past Events</h2>
                      <a
                        className="viewall cursorPointer"
                        onClick={() => handleViewAll("pastData")}
                      >
                        View All
                      </a>
                    </div>

                    {myEventsData?.pastData?.length > 0 ? (
                      myEventsData?.pastData?.map(
                        (events, index) =>
                          index < 2 && (
                            <div className="loop grey-wrap item" key={index}>
                              <div
                                className="flex-row cursorPointer"
                                onClick={() =>
                                  handleViewEventDetails(index, "pastData")
                                }
                              >
                                <div className="col-img">
                                  <div
                                    onMouseOver={(e) => {
                                      e.target.style.backgroundImage = `url(${events.videoStatus ? events.gifURL : events.eventImage})`
                                    }}
                                    onMouseOut={(e) => {
                                      e.target.style.backgroundImage = `url(${events.videoStatus
                                        ? events.thumbnailImage
                                        : events.eventImage})`
                                    }}
                                    style={{
                                      minHeight: "250px",
                                      height: "100%",
                                      width: "100%",
                                      backgroundImage: `url(${events.videoStatus
                                        ? events.thumbnailImage
                                        : events.eventImage
                                        })`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  ></div>
                                  {events.videoStatus && <div style={{
                                    position: "absolute",
                                    bottom: "20px"
                                  }}><img src={iconPlay} style={{ width: "40px" }} /></div>}
                                </div>
                                <div className="col-content">
                                  <div className="details">
                                    <h3
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {events.eventTitle}{" "}
                                      {events.isCancelled && (
                                        <span style={{ color: "#e50914" }}>
                                          Event Cancelled
                                        </span>
                                      )}
                                    </h3>
                                    <div
                                      className="description-scroll"
                                      style={{ maxHeight: "100px" }}
                                    >
                                      <p style={{ whiteSpace: "pre-line" }}>
                                        {events.description}
                                      </p>
                                    </div>
                                    <div className="other-detail">
                                      <div className="flex-row">
                                        Total booked tickets{" "}
                                        <span>{events.soldTickets}</span>
                                      </div>
                                      <div className="flex-row">
                                        Date and Time{" "}
                                        <span>
                                          {getEventLocalDate(
                                            events.eventDate,
                                            events.eventTime,
                                            events.setTimeZone
                                          )}
                                        </span>
                                      </div>
                                      <div className="flex-row">
                                        Audience Type{" "}
                                        <span>{events.audienceType}</span>
                                      </div>
                                      <div className="flex-row">
                                        Amount Earned{" "}
                                        <span>€ {events.earnedAmount}</span>
                                      </div>
                                      {events.userId.actorDocIssueCountry ===
                                        "CY" && (
                                          <div className="flex-row">
                                            VAT (19%/CY account){" "}
                                            <span>€ {events.vatAdd ? events.vatAdd : "0"}</span>
                                          </div>
                                        )}
                                      <div className="flex-row rating">
                                        <span className="like-con-book">
                                          <img src={IconLike} alt="" />
                                          {events?.rate_percentage
                                            ? events?.rate_percentage
                                            : 0}{" "}
                                          %
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                      )
                    ) : (
                      <div>No Events Present</div>
                    )}
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </div>
  );
}

const mapStateToProps = ({ EVENTS_REDUCER }) => {
  return {
    myEventsData: EVENTS_REDUCER?.myEventsData,
  };
};

export default connect(mapStateToProps, { GetMyEvents, })(AllEvents);