import React, { useEffect, useState } from "react";
import editIcon2 from "../../assets/img/icon-edit-2.svg";
import iconTrash from "../../assets/img/icon-trash.svg";
import LeftArrow from "../../assets/img/left-arrow.svg";
import cvvCard from "../../assets/img/icon-cvv-card.svg";
import InputMask from "react-input-mask";
import valid from "card-validator";
import {
  monthDropdown,
  yearDropdown,
  cardImages,
} from "../../helpers/constants";
import {
  GetPaymentMethods,
  AddPaymentMethods,
  DeletePaymentMethods,
  FetchPaymentMethod,
  UpdatePaymentMethod,
  MakeDefaultCard,
} from "../../redux/actions/user";
import { Notify } from "../../helpers/notifications";
import { Validation } from "../../helpers/validations";

function PaymentMethods({ setTab, setSelectedTab }) {
  const [payMethodArray, setPayMethodArray] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cardCode, setCardCode] = useState("");
  const [cardImage, setCardImage] = useState(cardImages["default"]);
  const [error, setError] = useState({});
  const [editError, setEditError] = useState({});
  const [editCardHolderName, setEditCardHolderName] = useState("");
  const [editCardNumber, setEditCardNumber] = useState("");
  const [editCardCode, setEditCardCode] = useState("");
  const [editExpiryDate, setEditExpiryDate] = useState("");
  const [editIndex, setEditIndex] = useState("");
  const [deleteIndex, setDeleteIndex] = useState("");
  const [onComplete, setOnComplete] = useState(false);
  const [defaultChecked, setDefaultChecked] = useState(false);
  const [addDisabled, setAddDisabled] = useState(false);
  let currentMonth = new Date().toISOString().split("-")[1];
  let currentYear = new Date().toISOString().split("-")[0];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [])

  useEffect(() => {
    GetPaymentMethods()
      .then((response) => {
        setPayMethodArray([...response.data.data]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [onComplete]);

  useEffect(() => {
    if (!showAdd) {
      setCardHolderName("");
      setCardNumber("");
      setExpiryMonth("");
      setExpiryYear("");
      setCardCode("");
      setCardImage(cardImages["default"]);
      setError({});
    }
  }, [showAdd])

  // Delete Card Details
  const handleDeleteCardDetails = async () => {
    setOnComplete(false);

    const payload = {
      cardId: payMethodArray[deleteIndex]._id,
    };

    try {
      let response = await DeletePaymentMethods(payload); //API call
      if (response && response.status === 200) {
        setOnComplete(true);
        setShowDelete(false);
        setDeleteIndex("");

        Notify("success", "The Card has been deleted successfully!!");
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  // Cancel Button Clicked (Delete)
  const handleDeleteCancel = () => {
    setShowDelete(false);
    setDeleteIndex("");
  };

  // Cancel Button Clicked (Edit)
  const handleEditCancel = () => {
    setShowEdit(false);
    setEditIndex("");
  };

  // Fetch Details of Card
  const handleEditCard = async (index) => {
    setShowAdd(false);
    setShowEdit(true);
    setEditIndex(index);

    const payload = {
      cardId: payMethodArray[index]._id,
    };

    try {
      const response = await FetchPaymentMethod(payload);
      if (response && response.status === 200) {
        const cardData = response.data.data;
        setEditCardHolderName(cardData.name);
        setEditCardNumber(`XXXX XXXX XXXX ${cardData.last4}`);
        setEditCardCode("000");
        setEditExpiryDate(
          cardData.expMonth < 10
            ? `0${cardData.expMonth}/${cardData.expYear}`
            : `${cardData.expMonth}/${cardData.expYear}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Card Number Change Handler
  const handleCardNumberChange = (value) => {
    let numberValidation = valid.number(value);
    setCardNumber(value);
    if (numberValidation.isPotentiallyValid && numberValidation.card) {
      setCardImage(cardImages[numberValidation.card.type]);
    } else {
      setCardImage(cardImages["default"]);
    }
  };

  // Update Card Details
  const handleUpdateCardDetails = async () => {
    setOnComplete(false);
    let fields = ["editCardHolderName", "editExpiryDate"];

    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleEditValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;

    const payload = {
      cardId: payMethodArray[editIndex]._id,
      name: editCardHolderName.trim(),
      expMonth: editExpiryDate.split("/")[0],
      expYear: editExpiryDate.split("/")[1],
    };

    try {
      let response = await UpdatePaymentMethod(payload);
      if (response && response.status === 200) {
        setOnComplete(true);
        setShowEdit(false);
        setEditCardHolderName("");
        setEditCardNumber("");
        setEditCardCode("");
        setEditExpiryDate("");
        setEditIndex("");
        Notify("success", "The Card has been updated successfully!!");
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  // Add Card Details
  const addCardDetails = async () => {
    setOnComplete(false);
    let fields = ["cardHolderName", "cardNumber", "expirationDate", "cardCode"];
    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;
    setAddDisabled(true);
    const payload = {
      cardNumber: cardNumber,
      cvv: cardCode,
      expMonth: expiryMonth,
      expYear: expiryYear,
      name: cardHolderName.trim(),
      isDefault: payMethodArray.length > 0 ? `${defaultChecked}` : "true",
    };
    try {
      let response = await AddPaymentMethods(payload); //API call
      if (response && response.status === 200) {
        setShowAdd(false);
        setCardHolderName("");
        setCardNumber("");
        setExpiryMonth("");
        setExpiryYear("");
        setCardCode("");
        setCardImage(cardImages["default"]);
        setOnComplete(true);
        setAddDisabled(false);

        Notify("success", "Your card has been added successfully!!");
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
      setAddDisabled(false);
    }
  };

  // validator function
  const handleValidation = (type) => {
    if (type === "cardHolderName") {
      if (Validation.empty(cardHolderName)) {
        setError({ ...error, cardHolderName: "Card Holder Name is required" });
        return false;
      } else if (!Validation.name(cardHolderName)) {
        setError({
          ...error,
          cardHolderName: "Min 2 and Max 50 characters allowed",
        });
        return false;
      }
    } else if (type === "cardNumber") {
      let numberValidation = valid.number(cardNumber, {
        luhnValidateUnionPay: true,
      });
      let test = numberValidation.isValid;
      if (!numberValidation.isValid) {
        setError({ ...error, cardNumber: "Invalid Card Number" });
        return false;
      }
    } else if (type === "expirationDate") {
      let expireValidation = valid.expirationDate(
        `${expiryMonth}/${expiryYear}`
      );
      if (!expireValidation.isPotentiallyValid || !expireValidation.isValid) {
        setError({ ...error, expirationDate: "Invalid expiry date selected" });
        return false;
      }
    } else if (type == "cardCode") {
      if (cardCode.length < 3 || cardCode.length > 4) {
        setError({
          ...error,
          cardCode: "CVV should be of 3 or 4 characters long",
        });
        return false;
      }
    }
    return true;
  };

  // validator for edit Card
  const handleEditValidation = (type) => {
    if (type === "editCardHolderName") {
      if (Validation.empty(editCardHolderName)) {
        setEditError({ ...editError, editCardHolderName: "Card Holder Name is required" });
        return false;
      } else if (!Validation.name(editCardHolderName)) {
        setEditError({
          ...editError,
          editCardHolderName: "Min 2 and Max 50 characters allowed",
        });
        return false;
      }
    } else if (type === "expirationDate") {
      let expireValidation = valid.expirationDate(editExpiryDate);
      if (!expireValidation.isPotentiallyValid || !expireValidation.isValid) {
        setEditError({
          ...editError,
          expirationDate: "Invalid expiry date selected",
        });
        return false;
      }
    }
    return true;
  };

  const handleSetDefaultCard = async (text, id) => {
    setOnComplete(false);
    const payload = {
      cardId: id,
    };
    try {
      const response = await MakeDefaultCard(payload);
      if (response && response.status === 200) {
        setOnComplete(true);
        Notify("success", "Default has been updated successfully!!");
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  return (
    <div>
      <section className="myaccount-section">
        <div className="flex-container ">
          <div className="breadcrumbs enableDisplay">
            {localStorage.getItem("setTab") ? (
              <ul>
                <li>
                  <a className="cursorPointer" onClick={() => setSelectedTab(8)}>
                    <img src={LeftArrow} style={{ width: "15px" }} />Back to Booking
                  </a>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <a className="cursorPointer" onClick={() => setSelectedTab(1)}>
                    Home
                  </a>{" "}
                  <span>&#62;</span>{" "}
                </li>
                <li>
                  <a className="cursorPointer" onClick={(e) => setTab(1)}>
                    My Account
                  </a>{" "}
                  <span>&#62;</span>{" "}
                </li>
                <li>My Payment</li>
              </ul>
            )}
          </div>
          <div className="myaccount banking-information">
            <div className="top-heading">
              <h1>Choose Payment Method</h1>
            </div>
            {payMethodArray.map((method, index) => (
              <div className="form-wrap" key={index}>
                <div className="form-bankingInfo">
                  <div className="field-wrap">
                    <div className="input-pay-wrapper">
                      <div className="forgotlink">
                        <label className="pure-checkbox">
                          <input
                            type="radio"
                            name="defaultCard"
                            value={index}
                            id={index}
                            onChange={(e) =>
                              handleSetDefaultCard(e.target.value, method._id)
                            }
                            checked={method.isDefault}
                          />
                          <span className="checkmark checkmark-pay"></span>
                          <span className="space-lhs">
                            {`${method.brand} XXXX XXXX XXXX ${method.lastDigit}`}
                          </span>
                        </label>
                      </div>
                      <div className="icons-container" style={{ top: "15px" }}>
                        <span className="space-bw">
                          <a
                            className="cursorPointer"
                            onClick={() => handleEditCard(index)}
                          >
                            <img src={editIcon2} alt="" />
                          </a>
                        </span>
                        <span>
                          <a
                            className="delete-pay cursorPointer"
                            onClick={() => {
                              setShowAdd(false);
                              setShowDelete(true);
                              setDeleteIndex(index);
                            }}
                          >
                            <img src={iconTrash} alt="" />
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="form-wrap">
              <div className="form-bankingInfo">
                <div className="field-wrap">
                  <div className="input-pay-wrapper border-bottom">
                    <div className="forgotlink">
                      <div
                        className="boder-con cursorPointer"
                        onClick={() => setShowAdd(!showAdd)}
                      >
                        <label className="pure-checkbox">
                          <span className="checkmark checkmark-pay plus-btn"></span>
                          <span className="space-lhs">Add New Card</span>
                        </label>
                      </div>
                    </div>

                    {showAdd && (
                      <>
                        <div className="field-wrap corner-space">
                          <label>Card Holder's Name</label>
                          <input
                            type="text"
                            name=""
                            required=""
                            placeholder="Name on card"
                            value={cardHolderName}
                            onChange={(e) => setCardHolderName(e.target.value)}
                            onFocus={(e) =>
                              setError({ ...error, cardHolderName: "" })
                            }
                            onBlur={(e) => handleValidation("cardHolderName")}
                          />
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.cardHolderName}
                            </span>
                          )}

                          <div
                            className="card-field-wrap"
                            style={{ position: "relative" }}
                          >
                            <label>Card Number</label>
                            <input
                              type="text"
                              name=""
                              required=""
                              placeholder="0000 0000 0000 0000"
                              value={cardNumber}
                              onChange={(e) =>
                                handleCardNumberChange(
                                  e.target.value.replace(/[^0-9]/g, "")
                                )
                              }
                              onFocus={(e) =>
                                setError({ ...error, cardNumber: "" })
                              }
                              onBlur={(e) => handleValidation("cardNumber")}
                            />
                            <div
                              className="card-icon-con"
                              style={{ top: "40px" }}
                            >
                              <img src={cardImage} alt="" />
                            </div>
                            {error && (
                              <span style={{ color: "red" }}>
                                {error?.cardNumber}
                              </span>
                            )}
                          </div>

                          <div className="drop-wrapper">
                            <label for="">Expiry Date</label>
                            <div className="drop-row-1">
                              <div className="drop-col-1">
                                <select
                                  name=""
                                  id=""
                                  className="chng-arrow"
                                  value={expiryMonth}
                                  onChange={(e) =>
                                    setExpiryMonth(e.target.value)
                                  }
                                  onFocus={(e) =>
                                    setError({ ...error, expirationDate: "" })
                                  }
                                >
                                  <option value="">Select Month</option>
                                  {monthDropdown.map((month, index) =>
                                    expiryYear === currentYear ? (
                                      currentMonth <= month.id && (
                                        <option value={month.id} key={index}>
                                          {month.text}
                                        </option>
                                      )
                                    ) : (
                                      <option value={month.id} key={index}>
                                        {month.text}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                              <div className="drop-col-1">
                                <select
                                  name=""
                                  id=""
                                  className="chng-arrow"
                                  value={expiryYear}
                                  onChange={(e) =>
                                    setExpiryYear(e.target.value)
                                  }
                                  onFocus={(e) =>
                                    setError({ ...error, expirationDate: "" })
                                  }
                                >
                                  <option value="">Select Year</option>

                                  {yearDropdown.map((year, index) => (
                                    <option value={year} key={index}>
                                      {year}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="drop-col-1 w-change">
                                <div className="field-wrap cvv-wrap">
                                  <div className="label-cvv">
                                    <label for="birthday">CVV</label>
                                  </div>
                                  <input
                                    type="password"
                                    name=""
                                    placeholder="XXX"
                                    value={cardCode}
                                    onChange={(e) =>
                                      setCardCode(
                                        e.target.value
                                          .replace(/[^0-9]/g, "")
                                          .slice(0, 4)
                                      )
                                    }
                                    onFocus={(e) =>
                                      setError({ ...error, cardCode: "" })
                                    }
                                    onBlur={(e) => handleValidation("cardCode")}
                                  />
                                  <div className="card-icon-cvv">
                                    <a>
                                      <img src={cvvCard} alt="" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="drop-row-1">
                              <div
                                className="drop-col-1"
                                style={{ width: "70%" }}
                              >
                                {error && (
                                  <span style={{ color: "red" }}>
                                    {error?.expirationDate}
                                  </span>
                                )}
                              </div>
                              <div style={{ marginBottom: '24px' }} className="drop-col-1">
                                {error && (
                                  <span style={{ color: "red" }}>
                                    {error?.cardCode}
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className="forgotlink chng-forgot">
                              <label className="pure-checkbox">
                                <input
                                  className="change-input"
                                  type="checkbox"
                                  checked={defaultChecked}
                                  onChange={(e) =>
                                    setDefaultChecked(e.target.checked)
                                  }
                                />
                                <span className="checkmark checkmark-2"></span>
                                Set this as Default Payment Method.
                              </label>
                            </div>

                            <div className="btn-wrap text-center">
                              <button
                                type="button"
                                className="primary-button"
                                onClick={() => addCardDetails()}
                                disabled={addDisabled}
                              >
                                Save Card
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal Popup for deleting payment method */}
      <div className={`popup-model popup-model-alert ${showDelete && "show"}`}>
        <div className="model-head remove-border">
          <a
            className="model-close cursorPointer"
            onClick={() => setShowDelete(false)}
          >
            &#10006;
          </a>
        </div>
        <div className="model-body">
          <div className="model-content">
            <h2>Are you sure you want to delete this payment method?</h2>
            <div className="btn-row text-center">
              <button
                type="button"
                className="primary-button"
                onClick={(e) => handleDeleteCardDetails()}
              >
                Delete
              </button>
              <button
                type="button"
                className="secondary-button btn-reschedule"
                onClick={() => handleDeleteCancel()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`overlay fix-opaicty ${showDelete && "show"}`}></div>

      {/* Modal popup for Editing Payment Method */}
      <div className={`popup-model popup-model-alert-2 ${showEdit && "show"}`}>
        <div className="model-head remove-border">
          <a
            className="model-close cursorPointer"
            onClick={() => handleEditCancel()}
          >
            &#10006;
          </a>
        </div>
        <div className="model-body">
          <div className="model-content">
            <h2>Edit Card Details</h2>
            <form>
              <div className="field-wrap">
                <label>Name on card</label>
                <div className="custom-date-time">
                  <input
                    type="text"
                    placeholder="Card holder name"
                    value={editCardHolderName}
                    onChange={(e) => setEditCardHolderName(e.target.value)}
                    onFocus={(e) =>
                      setEditError({ ...editError, editCardHolderName: "" })
                    }
                    onBlur={(e) => handleEditValidation("editCardHolderName")}
                  />
                  {editError && (
                    <span style={{ color: "red" }}>
                      {editError?.editCardHolderName}
                    </span>
                  )}
                </div>
              </div>
              <div className="field-wrap">
                <label>Card Number</label>
                <div className="custom-date-time">
                  <input
                    type="text"
                    placeholder="XXXX XXXX XXXX XXXX"
                    value={editCardNumber}
                    disabled={true}
                  />
                </div>
              </div>

              <div className="card-details-wrap">
                <div className="card-details-row">
                  <div className="card-details-col-1">
                    <div className="field-wrap">
                      <label>Expiry (MM/YYYY)</label>
                      <div className="custom-date-time">
                        <InputMask
                          mask="99/9999"
                          value={editExpiryDate}
                          placeholder="MM/YYYY"
                          onChange={(e) => setEditExpiryDate(e.target.value)}
                          onBlur={(e) => handleEditValidation("expirationDate")}
                          onFocus={(e) =>
                            setEditError({ ...editError, expirationDate: "" })
                          }
                        />
                        {editError && (
                          <span style={{ color: "red" }}>
                            {editError?.editExpiryDate}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card-details-col-2">
                    <div className="field-wrap cvv-outer">
                      <label>CVV</label>
                      <div className="custom-date-time">
                        <input
                          type="password"
                          placeholder=""
                          value={editCardCode}
                          disabled={true}
                        />
                      </div>
                      <div className="modal-card-icon-cvv">
                        <a className="cursorPointer">
                          <img src={cvvCard} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="btn-row text-center">
                <button
                  className="primary-button submit-cancellation"
                  type="button"
                  onClick={(e) => handleUpdateCardDetails()}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={`overlay fix-opaicty ${showEdit && "show"}`}></div>
    </div>
  );
}

export default PaymentMethods;