

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CreateParticipant, CreateStream } from "../../redux/actions/events";
import Publisher from "./Publisher";
import PublisherRTMP from "./PublisherRTMP"
import SubscriberHLS from "./SubscriberHLS";
import Loader from "../loader/Loader";
import { Notify } from "../../helpers/notifications";
import { useHistory } from "react-router"

function Stream({ role }) {

  const history = useHistory();
  const [apiKey, setApiKey] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [broadCastURL, setBrodCastURL] = useState("");
  const [eventEndTime, setEventEndTime] = useState("");
  const [eventStartTime, setEventStartTime] = useState("");
  const [blockedStatus, setBlockedStatus] = useState(false);
  const [isHLS, setIsHLS] = useState(false);
  const [actorName, setActorName] = useState("");
  const [mediaLiveChannelStatus, setMediaLiveChannelStatus] = useState("")
  const [mediaLivePullUrl, setMediaLivePullUrl] = useState("")
  const [mediaLivePushUrl, setMediaLivePushUrl] = useState("")
  const [mediaLivePushBackupUrl, setMediaLivePushBackupUrl] = useState("")
  const [mediaLivePushBackupKey, setMediaLivePushBackupkey] = useState("")

  const [mediaLivePushKey, setmediaLivePushKey] = useState("")
  const [platform, setPlatform] = useState("rtmp")
  const [quality, setQuality] = useState("")

  useEffect(() => {
    if (role === "Actor") {
      if (history.location.state.platform == "easyStream") {
        setLoading(true);
        CreateStream({ eventId: history.location.state.eventId, deviceType: "web" })
          .then((res) => {
            setApiKey(res.data.data.apiKey);
            setSessionId(res.data.data.sessionId);
            setToken(res.data.data.token);
            setEventEndTime(res.data.data.eventEndTime);
            setEventStartTime(res.data.data.startTime);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
            history.push("/home/actor");
            Notify("danger", "Please wait for 10-15 seconds before restarting the event", "", "top-right", "", 10000);
          });
      }
      else {
        setLoading(true);
        CreateStream({ eventId: history.location.state.eventId, deviceType: "web" })
          .then((res) => {
            setMediaLiveChannelStatus(res.data.data.mediaLive_channel_status);
            setMediaLivePullUrl(res.data.data.mediaLive_pull_url);
            setEventEndTime(res.data.data.eventEndTime);
            setEventStartTime(res.data.data.startTime);
            setMediaLivePushUrl(res.data.data.mediaLive_push_url);
            setMediaLivePushBackupUrl(res.data.data.mediaLive_backup_push_url)
            setPlatform(res.data.data.platform);
            setQuality(res.data.data.quality);
            setLoading(false);
            let mediaLivePushUrl = res.data?.data?.mediaLive_push_url;
            const lastIndex = mediaLivePushUrl.lastIndexOf('/')
            const streamUrl = mediaLivePushUrl.slice(0, lastIndex + 1)
            const streamKey = mediaLivePushUrl.slice(lastIndex + 1)
            setmediaLivePushKey(streamKey)
            setMediaLivePushUrl(streamUrl)
            let mediaLivePushBackupUrl = res.data?.data?.mediaLive_backup_push_url;
            const lastBackupIndex = mediaLivePushBackupUrl.lastIndexOf('/')
            const streamBackupUrl = mediaLivePushBackupUrl.slice(0, lastBackupIndex + 1)
            const streamBackupKey = mediaLivePushBackupUrl.slice(lastBackupIndex + 1)
            setMediaLivePushBackupkey(streamBackupKey)
            setMediaLivePushBackupUrl(streamBackupUrl)
          })
          .catch((error) => {
            setLoading(false);
            history.push("/home/actor");
            Notify("danger", "Infrastructure is being created. Please wait for some more time.", "", "top-right", "", 10000);
          });
      }
    }
    else {
      setLoading(true);
      CreateParticipant({ eventId: history.location.state.eventId })
        .then((res) => {
          setApiKey(res.data.data.apiKey);
          setSessionId(res.data.data.sessionId);
          setPlatform(res.data.data.platform)
          setToken(res.data.data.token);
          setBrodCastURL(res.data.data.platform == 'easyStream' ? res.data.data.broadCastUrl : res.data.data.mediaLive_pull_url);
          setBlockedStatus(res.data.data.chatEnable);
          setIsHLS(res.data.data.isHLS);
          setActorName(res.data.data.actorName);
          localStorage.setItem("isSessionJoined", true);
          setLoading(false);
          if (!res.data.data.isHLS && res.data.data.platform == "easyStream") {
            Notify("danger", "Session failed");
            history.push("/home/user");
          }
        })
        .catch((error) => {
          setLoading(false);
          Notify("danger", "Session failed");
        });
    }
  }, []);

  return (
    <div>
      {(!loading) ? (
        <>

          {/* Actor With Easy Stream */}
          {role === "Actor" && apiKey && sessionId && token &&
            <Publisher apiKey={apiKey} sessionId={sessionId} token={token}
              eventId={history.location.state.eventId} eventEndTime={eventEndTime}
              shareEventURL={history.location.state.shareEventURL} eventStartTime={eventStartTime} />}

          {/* End User With Easy Stream & RTMP */}
          {(role === "User" || role === "Corporate") &&
            ((isHLS && platform == "easyStream") || platform == "rtmp") &&
            <SubscriberHLS eventId={history.location.state.eventId}
              broadCastURL={broadCastURL} blockedStatus={blockedStatus}
              actorName={actorName} shareEventURL={history.location.state.shareEventURL} />}


          {/* Actor  With  RTMP */}
          {role === "Actor" && platform == "rtmp" && mediaLivePullUrl &&
            <PublisherRTMP mediaLivePushBackupUrl={mediaLivePushBackupUrl}
              mediaLivePushBackupKey={mediaLivePushBackupKey}
              mediaLivePushUrl={mediaLivePushUrl}
              mediaLivePushKey={mediaLivePushKey}
              broadCastURL={mediaLivePullUrl}
              apiKey={""} sessionId={""} token={""} eventId={history.location.state.eventId}
              eventEndTime={eventEndTime} shareEventURL={history.location.state.shareEventURL}
              eventStartTime={eventStartTime} />}
        </>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData?.role,
  };
};

export default connect(mapStateToProps, {})(Stream);