import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
import ReactNotification from "react-notifications-component";
import ErrorBoundary from "./components/ErrorBoundary/index";
import "react-notifications-component/dist/theme.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./assets/css/video-style.css";
import "./assets/css/stylesheet.css";
import "./assets/css/responsive.css";
import "./assets/css/reset.css";

import getStore from "./redux/store/store";

const { store, persistor } = getStore;

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ReactNotification />
          <ErrorBoundary>
            <App/>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();