import React, { useEffect, useState } from "react";
import MinusPNG from "../../../../assets/img/icon-minus-lite.png";
import dpPhoto from "../../../../assets/img/img_avatar.png";
import IconLike from "../../../../assets/img/icon-like.svg";
import IconAddRound from "../../../../assets/img/icon-addround.svg";
import cvvCard from "../../../../assets/img/icon-cvv-card.svg";
import { GetEventDetails } from "../../../../redux/actions/events";
import {
  GetPaymentMethods,
  ApplyVoucher,
  AddPaymentMethods,
  MakeDefaultCard,
} from "../../../../redux/actions/user";
import {
  cardImages,
  monthDropdown,
  yearDropdown,
} from "../../../../helpers/constants";
import { connect } from "react-redux";
import {
  EventBooking,
  CheckBookedEmail,
} from "../../../../redux/actions/events";
import { Notify } from "../../../../helpers/notifications";
import BookingSuccess from "./bookingSuccess";
import { Validation } from "../../../../helpers/validations";
import { getEventLocalDate } from "../../../../helpers/constants";
import moment from "moment-timezone";
import Logo from "../../../loader/Loader";
import valid from "card-validator";

function BookEvent({ setSelectedTab, eventID, userEmail, role }) {
  let notiEventID = localStorage.getItem("notiEventID");
  const [eventData, setEventData] = useState({});
  const [paymentMethods, setPaymentMethods] = useState();
  const [cardImage, setCardImage] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [emailArray, setEmailArray] = useState([
    {
      email: userEmail,
    },
  ]);
  const [isBooked, setIsBooked] = useState(false);
  const [cardsPresent, setCardsPresent] = useState(false);
  const [bookDisabled, setBookDisabled] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [isValidEmail, setIsValidEmail] = useState({});
  const [voucherInfo, setVoucherInfo] = useState({
    colorCode: "#ff0000",
    finalAmount: 0,
    status: false,
    message: "",
    voucherAmount: 0,
    remainingVoucher: 0
  });
  const [showLoader, setShowLoader] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [addCardImage, setAddCardImage] = useState(cardImages["default"]);
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cardCode, setCardCode] = useState("");
  const [error, setError] = useState({});
  const [addDisabled, setAddDisabled] = useState(false);
  const [onComplete, setOnComplete] = useState(false);
  const [payMethodArray, setPayMethodArray] = useState([]);
  const localTZone = moment.tz.guess();

  let currentMonth = new Date().toISOString().split("-")[1];
  let currentYear = new Date().toISOString().split("-")[0];

  useEffect(() => {
    document.title = "LiveStage | Book Event";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [isBooked, showAddCard]);

  const handlePayNowButton = () => {
    if (isValidEmail) {
      setBookDisabled(true)
      handlePayNow()
    } else {
      setBookDisabled(false);
    }

  }
  useEffect(() => {
    GetPaymentMethods()
      .then((response) => {
        setPayMethodArray([...response.data.data]);
        response.data.data.length > 0
          ? setCardsPresent(true)
          : setCardsPresent(false);
        let defaultCard = response.data.data.filter(
          (item) => item.isDefault !== false
        );
        setPaymentMethods(defaultCard);
        setCardImage(
          defaultCard[0].brand
            ? cardImages[defaultCard[0].brand.toLowerCase()]
            : cardImages["default"]
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [onComplete]);

  useEffect(() => {
    setShowLoader(true);
    GetEventDetails(notiEventID ? notiEventID : eventID)
      .then((response) => {
        setEventData(response.data.data);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  }, []);

  const handlePayNow = async () => {
    for (let i = 0; i < emailArray.length; i++) {
      if (Validation.empty(emailArray[i].email)) {
        setEmailError("Emails mentioned is/are empty!!");
        return;
      }
    }
    let newArray = emailArray.filter((item, i) => {
      return i !== 0;
    });
    let arrayToUse = eventData.alreadyBooked ? newArray : emailArray;
    if (arrayToUse[arrayToUse.length - 1]?.email === "") {
      setEmailError("Emails mentioned is/are empty!!");
      return;
    } else {
      if (eventData.alreadyBooked && emailArray.length < 2) {
        setEmailError("Please add a new email to book event");
        return;
      } else if (!Validation.email(arrayToUse[arrayToUse.length - 1]?.email)) {
        setEmailError("Please enter the email in a valid format");
        return;
      } else if (similarEmailCheck()) {
        setEmailError("Cannot use same email");
        return;
      }
      else if (
        !isValidEmail &&
        arrayToUse[0]?.email !== userEmail &&
        role !== "Corporate"
      ) {
        setEmailError("");
        return;
      }
    }

    if (paymentMethods.length < 1) {
      setShowAddCard(true);
      setBookDisabled(false);
      return;
    }

    const payload = {
      totalPrice: voucherInfo.status
        ? parseFloat(voucherInfo.finalAmount)
        : parseFloat(handleTicketPrice().finalTicketPrice),
      sharedUser: eventData.alreadyBooked ? newArray : emailArray,
      voucherId: voucherCode,
      eventId: eventData._id,
      customerId: paymentMethods[0].customerId, // from default card
      userTimeZone: localTZone,
      cardId: paymentMethods[0]._id,
      normalTicket: `${handleTicketPrice().NormalBifur.number}`,
      EBTicket: `${handleTicketPrice().EBBifur.number}`,
      voucherAmt: voucherInfo.voucherAmount,
      remainingVoucher: voucherInfo.remainingVoucher
    };

    try {
      const response = await EventBooking(payload);
      if (response && response.status === 200) {
        setBookDisabled(false);
        setIsBooked(true);
        setIsValidEmail(false);
        Notify("success", "Event has been booked successfully!!");
      }
    } catch (error) {
      setBookDisabled(false);
      setIsBooked(false);
      setIsValidEmail(false);
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  // Update Email Text
  const handleEmailText = (text, index) => {
    let newArray = emailArray.map((item, i) =>
      index == i
        ? {
          ...item,
          email: text,
        }
        : item
    );
    setEmailArray(newArray);
  };

  // Add new Field
  const addEmailField = () => {
    setBookDisabled(false)
    setEmailError("");
    if (emailArray[emailArray.length - 1].email === "") {
      setEmailError("Emails mentioned is/are empty!!");
    } else {
      if (!Validation.email(emailArray[emailArray.length - 1].email)) {
        setEmailError("Please enter the email in a valid format");
      } else if (similarEmailCheck()) {
        setEmailError("Cannot use same email");
      } else if (!isValidEmail && emailArray.length > 1) {
        console.log("Error");
      } else {
        if (emailArray.length < 5) {
          setEmailArray([
            ...emailArray,
            {
              email: "",
            },
          ]);
        }
      }
    }
    setVoucherCode("");
    setVoucherInfo({
      ...voucherInfo,
      colorCode: "#ff0000",
      finalAmount: 0,
      status: false,
      message: "",
      voucherAmount: 0,
      remainingVoucher: 0
    });
  };

  const similarEmailCheck = () => {
    let isSame = false;
    for (let i = emailArray.length - 2; i > -1; i--) {
      isSame =
        emailArray[emailArray.length - 1].email.toLowerCase() ==
        emailArray[i].email.toLowerCase();
      if (isSame) break;
    }
    return isSame;
  };

  // Remove Email Field
  const removeEmailField = (index) => {
    setBookDisabled(false)
    setEmailError("");
    let newArray = emailArray.filter((item, i) => index !== i);
    setEmailArray(newArray);
    setVoucherCode("");
    setVoucherInfo({
      ...voucherInfo,
      colorCode: "#ff0000",
      finalAmount: 0,
      status: false,
      message: "",
      voucherAmount: 0,
      remainingVoucher: 0
    });
  };

  const handleTicketPrice = () => {
    var finalTicketPrice;
    var EBBifur = { number: 0, price: 0 };
    var NormalBifur = { number: 0, price: 0 };

    let sharedUserLength = eventData.alreadyBooked
      ? emailArray.length - 1
      : emailArray.length;

    if (eventData.earlyBirdTicket && eventData.earlyBirdTicket > 0) {
      if (eventData.earlyBirdTicket > sharedUserLength) {
        finalTicketPrice = sharedUserLength * eventData.newEarlyBirdPrice;
        EBBifur = {
          ...EBBifur,
          number: sharedUserLength,
          price: eventData.newEarlyBirdPrice,
        };
        NormalBifur = { ...NormalBifur, number: 0, price: eventData.newPrice };
      } else {
        finalTicketPrice =
          (sharedUserLength - eventData.earlyBirdTicket) * eventData.newPrice +
          eventData.earlyBirdTicket * eventData.newEarlyBirdPrice;
        EBBifur = {
          ...EBBifur,
          number: eventData.earlyBirdTicket,
          price: eventData.newEarlyBirdPrice,
        };
        NormalBifur = {
          ...NormalBifur,
          number: sharedUserLength - eventData.earlyBirdTicket,
          price: eventData.newPrice,
        };
      }
    } else {
      finalTicketPrice = sharedUserLength * eventData.newPrice;
      EBBifur = { ...EBBifur, number: 0, price: 0 };
      NormalBifur = {
        ...NormalBifur,
        number: sharedUserLength,
        price: eventData.newPrice,
      };
    }
    return {
      finalTicketPrice: parseFloat(finalTicketPrice).toFixed(2),
      EBBifur,
      NormalBifur,
    };
  };

  const handleApplyVoucher = async () => {
    if (voucherCode) {
      setVoucherInfo({
        ...voucherInfo,
        colorCode: "#ff0000",
        finalAmount: 0,
        status: false,
        message: "",
        voucherAmount: 0,
        remainingVoucher: 0
      });
      let payload = {
        voucherCode: voucherCode,
        ticketPrice: handleTicketPrice().finalTicketPrice,
      };

      try {
        const response = await ApplyVoucher(payload);
        if (response && response.status === 200) {
          setVoucherInfo({
            ...voucherInfo,
            colorCode: "#00ff08",
            message: response.data.message,
            status: true,
            finalAmount: response.data.finalAmount,
            voucherAmount: response.data.voucherAmt,
            remainingVoucher: response.data.remainingVoucher
          });
        }
      } catch (error) {
        setVoucherCode("");
        setVoucherInfo({
          ...voucherInfo,
          colorCode: "#ff0000",
          message: error.response.data.message,
          status: false,
          finalAmount: 0,
          voucherAmount: 0,
          remainingVoucher: 0
        });
      }
    }
  };

  const handleRemoveVoucher = () => {
    setVoucherCode("");
    setVoucherInfo({
      ...voucherInfo,
      colorCode: "#ff0000",
      finalAmount: 0,
      status: false,
      message: "",
      voucherAmount: 0,
      remainingVoucher: 0
    });
  };

  const handleEmailCheck = async (email) => {
    setIsValidEmail(false);
    let payload = {
      eventId: eventID,
      email: email,
    };

    try {
      const response = await CheckBookedEmail(payload);

      if (response && response.status === 200) {
        setEmailError("")
        setIsValidEmail(true);
      }
    } catch (error) {
      setEmailError(error && error.response && error.response.data && error.response.data.message);
      setIsValidEmail(false);
    }
  };

  const handleSetDefaultCard = async (text, id) => {
    setOnComplete(false);
    const payload = {
      cardId: id,
    };
    try {
      const response = await MakeDefaultCard(payload);
      if (response && response.status === 200) {
        setOnComplete(true);
        setShowAddCard(false);
        Notify("success", "Default has been updated successfully!!");
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  // Add Card Details
  const addCardDetails = async () => {
    setOnComplete(false);
    let fields = ["cardHolderName", "cardNumber", "expirationDate", "cardCode"];
    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;
    setAddDisabled(true);
    const payload = {
      cardNumber: cardNumber,
      cvv: cardCode,
      expMonth: expiryMonth,
      expYear: expiryYear,
      name: cardHolderName.trim(),
      isDefault: "true",
    };
    try {
      let response = await AddPaymentMethods(payload); //API call
      if (response && response.status === 200) {
        setShowAddCard(false);
        setCardHolderName("");
        setCardNumber("");
        setExpiryMonth("");
        setExpiryYear("");
        setCardCode("");
        setAddCardImage(cardImages["default"]);
        setOnComplete(true);
        setAddDisabled(false);

        Notify("success", "Your card has been added successfully!!");
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
      setAddDisabled(false);
    }
  };

  // validator function
  const handleValidation = (type) => {
    if (type === "cardHolderName") {
      if (Validation.empty(cardHolderName)) {
        setError({ ...error, cardHolderName: "Card Holder Name is required" });
        return false;
      } else if (!Validation.name(cardHolderName)) {
        setError({
          ...error,
          cardHolderName: "Min 2 and Max 50 characters allowed",
        });
        return false;
      }
    } else if (type === "cardNumber") {
      let numberValidation = valid.number(cardNumber, {
        luhnValidateUnionPay: true,
      });
      if (!numberValidation.isValid) {
        setError({ ...error, cardNumber: "Invalid Card Number" });
        return false;
      }
    } else if (type === "expirationDate") {
      let expireValidation = valid.expirationDate(
        `${expiryMonth}/${expiryYear}`
      );
      if (!expireValidation.isPotentiallyValid || !expireValidation.isValid) {
        setError({ ...error, expirationDate: "Invalid expiry date selected" });
        return false;
      }
    } else if (type == "cardCode") {
      if (cardCode.length < 3 || cardCode.length > 4) {
        setError({
          ...error,
          cardCode: "CVV should be of 3 or 4 characters long",
        });
        return false;
      }
    }
    return true;
  };

  // Card Number Change Handler
  const handleCardNumberChange = (value) => {
    let numberValidation = valid.number(value);
    setCardNumber(value);

    if (numberValidation.isPotentiallyValid && numberValidation.card) {
      setAddCardImage(cardImages[numberValidation.card.type]);
    } else {
      setAddCardImage(cardImages["default"]);
    }
  };

  return (
    <div>
      {isBooked ? (
        <BookingSuccess
          eventDate={eventData.eventDate}
          eventTime={eventData.eventTime}
          eventTitle={eventData.eventTitle}
          eventTZone={eventData.setTimeZone}
          setSelectedTab={setSelectedTab}
        />
      ) : (
        <>
          <section className="make-booking-wrapper">
            <div className="flex-container">
              <div className="breadcrumbs">
                <ul>
                  <li>
                    <a
                      className="cursorPointer"
                      onClick={() => setSelectedTab(1)}
                    >
                      Home
                    </a>{" "}
                    <span>&gt;</span>{" "}
                  </li>
                  <li>
                    <a
                      className="cursorPointer"
                      onClick={() => setSelectedTab(2)}
                    >
                      All Events
                    </a>{" "}
                    <span>&gt;</span>{" "}
                  </li>
                  <li>
                    <a
                      className="cursorPointer"
                      onClick={() => setSelectedTab(7)}
                    >
                      Book Now
                    </a>{" "}
                    <span>&gt;</span>{" "}
                  </li>
                  <li>Make Booking</li>
                </ul>
              </div>
              {showLoader ? (
                <div>
                  <Logo />
                </div>
              ) : (
                <div className="inner-bookings-row">
                  <div
                    className="col-make-bookings col-lhs-booking col-height-change"
                    style={{
                      height:
                        emailArray.length > 1 &&
                        "86vh",
                    }}
                  >
                    <div className="row-book">
                      <div
                        className="inner-booking-cols cols-lhs"
                        style={{
                          borderRadius: "5px",
                          verticalAlign: "bottom",
                          backgroundImage: `url(${eventData?.videoStatus
                            ? eventData?.thumbnailImage
                            : eventData?.eventImage
                            })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                      </div>
                      <div className="inner-booking-cols cols-rhs">
                        <div className="title-make-bookings">
                          <h3>{eventData.eventTitle}</h3>
                          <h4>{eventData.eventCategory}</h4>
                        </div>
                        <div className="content-make-bookings">
                          <div className="description-scroll">
                            <p style={{ whiteSpace: "pre-line" }}>
                              {eventData.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="wrapper-artist">
                      <div className="title-make-bookings artist-main">
                        <h3>Artist</h3>
                      </div>
                      <div className="row-artist">
                        <div
                          className="col-artist artist-lhs"
                          style={{
                            borderRadius: "5px",
                            verticalAlign: "bottom",
                            backgroundImage: `url(${eventData?.userId?.profilePhoto
                              ? eventData.userId?.profilePhoto
                              : dpPhoto
                              })`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        >
                        </div>

                        <div className="col-artist artist-rhs">
                          <div className="inner-booking-cols ">
                            <div className="title-make-bookings artist-title">
                              <h3 style={{ overflowWrap: "break-word" }}>
                                {eventData.userId?.firstName +
                                  " " +
                                  eventData.userId?.lastName}
                              </h3>
                              <p>{eventData.userId?.category}</p>
                              {eventData?.userId?.rateCount > 0 && <span className="like-con-book">
                                <img src={IconLike} alt="" />
                                {eventData.artistRating} %
                              </span>}
                            </div>
                            <div className="content-make-bookings artist-content">
                              <div className="description-scroll">
                                <p style={{ whiteSpace: "pre-line" }}>
                                  {eventData.userId?.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-make-bookings col-rhs-booking">
                    <div className="booking-details-form">
                      <div className="book-form-title">
                        <h4>Booking Details</h4>
                      </div>
                      <div className="booking-from-wrap">
                        <div action="" className="book-details">
                          <div className="field-container">
                            <div
                              className="input-conatiner"
                              style={{ marginBottom: "20px" }}
                            >
                              <label>Booking user details</label>

                              {emailArray.map((item, index) => (
                                <div
                                  className="field-wrap pirice-add change-field"
                                  style={{ marginBottom: "0px" }}
                                  key={index}
                                >
                                  <input
                                    type="text"
                                    id="appt"
                                    name="price"
                                    placeholder="Email Address"
                                    value={item.email}
                                    onChange={(e) => {
                                      setBookDisabled(false)
                                      setEmailError("")
                                      handleEmailCheck(e.target.value)
                                      handleEmailText(e.target.value, index)
                                    }
                                    }
                                    disabled={index === 0}
                                  />
                                  {index === 0 && role !== "Corporate" && (
                                    <div style={{ fontSize: "10px" }}>
                                      Would you like to book the tickets for
                                      someone else, if yes please click on (+)
                                      icon to add them.
                                    </div>
                                  )}
                                  <div
                                    className="icon-plus-wrapper"
                                    style={{ top: "18px" }}
                                  >
                                    {role !== "Corporate" &&
                                      (index < 1 ? (
                                        <a
                                          className="cursorPointer"
                                          onClick={() => addEmailField()}
                                        >
                                          <img src={IconAddRound} alt="" />
                                        </a>
                                      ) : (
                                        <a
                                          className="cursorPointer"
                                          onClick={() =>
                                            removeEmailField(index)
                                          }
                                        >
                                          <img src={MinusPNG} alt="" />
                                        </a>
                                      ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="user-row">
                              <div className="user-col">
                                <div className="user-details">
                                  <p>Event Date and Time</p>
                                </div>
                                <div className="user-details">
                                  <p>Number of Tickets</p>
                                </div>
                                <div className="user-details">
                                  <p style={{ fontSize: "0.8rem" }}>
                                    Early Bird Tickets
                                  </p>
                                  <p style={{ fontSize: "0.8rem" }}>
                                    Normal Tickets
                                  </p>
                                  <hr
                                    style={{ borderTop: "1px solid #1b1b1b" }}
                                  />
                                  {voucherInfo.status && (
                                    <>
                                      <p>Total</p>
                                      <p style={{ fontSize: "1rem" }}>
                                        Voucher Amount
                                      </p>
                                      <hr
                                        style={{
                                          borderTop: "1px solid #1b1b1b",
                                        }}
                                      />
                                    </>
                                  )}
                                  <p>Grand Total</p>
                                </div>
                              </div>
                              <div className="user-col user-col-rhs">
                                <div className="user-details">
                                  <p>
                                    {
                                      getEventLocalDate(
                                        eventData.eventDate,
                                        eventData.eventTime,
                                        eventData.setTimeZone
                                      ).split(",")[0]
                                    }{" "}
                                    |
                                    {
                                      getEventLocalDate(
                                        eventData.eventDate,
                                        eventData.eventTime,
                                        eventData.setTimeZone
                                      ).split(",")[1]
                                    }
                                  </p>
                                </div>
                                <div className="user-details">
                                  <p>
                                    {eventData.alreadyBooked
                                      ? emailArray.length - 1
                                      : emailArray.length}
                                  </p>
                                </div>
                                <div className="user-details">
                                  <p style={{ fontSize: "0.8rem" }}>
                                    {`${handleTicketPrice().EBBifur.number} x ${handleTicketPrice().EBBifur?.price?.toFixed(2)
                                      }`}
                                  </p>
                                  <p style={{ fontSize: "0.8rem" }}>{`
                              ${handleTicketPrice().NormalBifur.number} x 
                              ${handleTicketPrice().NormalBifur?.price?.toFixed(2)}
                            `}</p>
                                  <hr />
                                  {voucherInfo.status && (
                                    <>
                                      <p>
                                        {handleTicketPrice().finalTicketPrice}
                                      </p>
                                      <p style={{ fontSize: "1rem" }}>
                                        {"- " + `${voucherInfo.voucherAmount}`}
                                      </p>
                                      <hr />
                                    </>
                                  )}
                                  <p>
                                    €{" "}
                                    {voucherInfo.status
                                      ? voucherInfo.finalAmount
                                      : handleTicketPrice().finalTicketPrice}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="input-conatiner full-user">
                              <div className="field-wrap pirice-add change-field">
                                <label>Apply Voucher Code</label>
                                <div
                                  className="field-wrap pirice-add change-field"
                                  style={{ marginBottom: "0px" }}
                                >
                                  <input
                                    type="text"
                                    id="appt"
                                    name="price"
                                    placeholder="Enter Voucher Code"
                                    value={voucherCode}
                                    onChange={(e) =>
                                      setVoucherCode(e.target.value)
                                    }
                                    onFocus={(e) =>
                                      setVoucherInfo({
                                        ...voucherInfo,
                                        message: "",
                                      })
                                    }
                                    disabled={voucherInfo.status}
                                  />
                                </div>
                                {voucherCode && (
                                  <div className="icon-plus-wrapper">
                                    {voucherInfo.status ? (
                                      <button
                                        className="btn-applyVoucher"
                                        type="button"
                                        onClick={() => handleRemoveVoucher()}
                                      >
                                        Remove
                                      </button>
                                    ) : (
                                      <button
                                        className="btn-applyVoucher"
                                        type="button"
                                        onClick={() => handleApplyVoucher()}
                                        disabled={voucherInfo.status}
                                      >
                                        Apply
                                      </button>
                                    )}
                                  </div>
                                )}
                                <div style={{ textAlign: "center" }}>
                                  {voucherInfo.message && (
                                    <span
                                      style={{
                                        color: voucherInfo.colorCode,
                                        fontSize: "0.9rem",
                                      }}
                                    >
                                      {voucherInfo.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="input-conatiner pay-mode-row">
                              <div className="field-wrap pirice-add change-field col-pay-mode">
                                <div className="pay-mode-row">
                                  <span>Your default Payment Method</span>
                                  <label
                                    className="change-option"
                                    style={{ marginBottom: "0px" }}
                                  >
                                    <a
                                      className="cursorPointer"
                                      onClick={() => {
                                        setShowAddCard(true);
                                      }}
                                    >
                                      {cardsPresent ? "Change" : "Add"}
                                    </a>
                                  </label>
                                </div>

                                <label className="pay-check">
                                  <img src={cardImage} alt="" />
                                  <span>
                                    {" "}
                                    {paymentMethods?.length > 0 &&
                                      `************${paymentMethods[0].lastDigit}`}
                                  </span>
                                </label>
                              </div>
                            </div>
                            {emailError && (
                              <div
                                className="input-conatiner"
                                style={{ textAlign: "center" }}
                              >
                                <span style={{ color: "red" }}>
                                  {emailError}
                                </span>
                              </div>
                            )}
                            <div className="btn-pay-con">
                              <button
                                className="primary-button"
                                type="button"
                                onClick={() => handlePayNowButton()}
                                disabled={bookDisabled}
                              >
                                Pay €
                                {voucherInfo.status
                                  ? voucherInfo.finalAmount
                                  : handleTicketPrice().finalTicketPrice}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>

          <div className={`popup-model reschedule-model ${showAddCard && "show"} bookEvent-pop`}>
            <div className="model-head">
              <h2>Choose Payment Method</h2>
              <a
                className="model-close cursorPointer"
                onClick={() => {
                  setShowAddCard(false);
                  setCardHolderName("");
                  setCardNumber("");
                  setExpiryMonth("");
                  setExpiryYear("");
                  setCardCode("");
                  setError({});
                  setAddDisabled(false);
                }}
              >
                &#10006;
              </a>
            </div>

            <div className="model-body">
              <div className="model-content">
                <div className="bookEvent-scroll">
                  {payMethodArray?.map((method, index) => (
                    <div className="form-wrap" key={index}>
                      <div className="form-bankingInfo">
                        <div className="field-wrap">
                          <div className="input-pay-wrapper">
                            <div className="forgotlink">
                              <label className="pure-checkbox">
                                <input
                                  type="radio"
                                  name="defaultCard"
                                  value={index}
                                  id={index}
                                  onChange={(e) =>
                                    handleSetDefaultCard(
                                      e.target.value,
                                      method._id
                                    )
                                  }
                                  checked={method.isDefault}
                                />
                                <span className="checkmark checkmark-pay"></span>
                                <span className="space-lhs">
                                  {`${method.brand} XXXX XXXX XXXX ${method.lastDigit}`}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div>
                    Add New Card
                  </div>
                  <div className="field-wrap corner-space">
                    <label>Card Holder's Name</label>
                    <input
                      type="text"
                      name=""
                      required=""
                      placeholder="Name on card"
                      value={cardHolderName}
                      onChange={(e) => setCardHolderName(e.target.value)}
                      onFocus={(e) => setError({ ...error, cardHolderName: "" })}
                      onBlur={(e) => handleValidation("cardHolderName")}
                    />
                    {error && (
                      <span style={{ color: "red" }}>
                        {error?.cardHolderName}
                      </span>
                    )}

                    <div
                      className="card-field-wrap"
                      style={{ position: "relative" }}
                    >
                      <label>Card Number</label>
                      <form autoComplete="off">
                        <input
                          type="text"
                          name=""
                          required=""
                          placeholder="0000 0000 0000 0000"
                          value={cardNumber}
                          onChange={(e) =>
                            handleCardNumberChange(
                              e.target.value.replace(/[^0-9]/g, "")
                            )
                          }
                          onFocus={(e) => setError({ ...error, cardNumber: "" })}
                          onBlur={(e) => handleValidation("cardNumber")}
                        />
                      </form>

                      <div
                        className="card-icon-con"
                        style={{ top: "20px", right: "15px" }}
                      >
                        <img src={addCardImage} alt="" />
                      </div>
                      {error && (
                        <span style={{ color: "red" }}>{error?.cardNumber}</span>
                      )}
                    </div>

                    <div className="drop-wrapper">
                      <label for="">Expiry Date</label>
                      <div className="drop-row-1">
                        <div className="drop-col-1">
                          <select
                            name=""
                            id=""
                            className="chng-arrow"
                            value={expiryMonth}
                            onChange={(e) => setExpiryMonth(e.target.value)}
                            onFocus={(e) =>
                              setError({ ...error, expirationDate: "" })
                            }
                          >
                            <option value="">Select Month</option>
                            {monthDropdown.map((month, index) =>
                              expiryYear === currentYear ? (
                                currentMonth <= month.id && (
                                  <option value={month.id} key={index}>
                                    {month.text}
                                  </option>
                                )
                              ) : (
                                <option value={month.id} key={index}>
                                  {month.text}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        <div className="drop-col-1">
                          <select
                            name=""
                            id=""
                            className="chng-arrow"
                            value={expiryYear}
                            onChange={(e) => setExpiryYear(e.target.value)}
                            onFocus={(e) =>
                              setError({ ...error, expirationDate: "" })
                            }
                          >
                            <option value="">Select Year</option>

                            {yearDropdown.map((year, index) => (
                              <option value={year} key={index}>
                                {year}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="drop-col-1 w-change">
                          <div className="field-wrap cvv-wrap">
                            <div className="label-cvv">
                              <label for="birthday">CVV</label>
                            </div>
                            <form autoComplete="off">
                              <input
                                type="password"
                                name=""
                                placeholder="XXX"
                                value={cardCode}
                                onChange={(e) =>
                                  setCardCode(
                                    e.target.value
                                      .replace(/[^0-9]/g, "")
                                      .slice(0, 4)
                                  )
                                }
                                onFocus={(e) =>
                                  setError({ ...error, cardCode: "" })
                                }
                                onBlur={(e) => handleValidation("cardCode")}
                              />
                            </form>
                            <div className="card-icon-cvv" style={{ top: "0px" }}>
                              <a>
                                <img src={cvvCard} alt="" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="drop-row-1">
                        <div className="drop-col-1" style={{ width: "70%" }}>
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.expirationDate}
                            </span>
                          )}
                        </div>
                        <div className="drop-col-1">
                          {error && (
                            <span style={{ color: "red" }}>
                              {error?.cardCode}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="btn-wrap text-center">
                        <button
                          type="button"
                          className="primary-button"
                          onClick={() => addCardDetails()}
                          disabled={addDisabled}
                        >
                          Save Card
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`overlay ${showAddCard && "show"}`}></div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    userEmail: USER_REDUCER.userData.email,
    role: USER_REDUCER.userData.role,
  };
};

export default connect(mapStateToProps, {})(BookEvent);