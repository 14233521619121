import API from "../../../redux/api/api";
const DownloadReceipt = async (receipt) => {
    const cookie = key => ((new RegExp((key || '=') + '=(.*?); ', 'gm')).exec(document.cookie + '; ') || ['', null])[1];
    const token = cookie('jwtTokenPetros');
    const fileNameParts = receipt.split('/');
    const filename = fileNameParts[fileNameParts.length - 1];
    const payload = {
        fileName: filename
    }
    try {
        const response = await API.post('/api/v1/users/download-invoice', payload, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            responseType: 'arraybuffer',
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "Invoice.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};

export default DownloadReceipt;