import React, { useEffect } from "react";
import logo from "../../assets/img/logo.svg";
import { connect } from "react-redux";

function TermsCond({ role, history }) {
  useEffect(() => {
    document.title = "LiveStage | Terms And Conditions";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div id="app" className="page-myaccount">
      <header className="app-header">
        <div className="flex-container">
          <div className="flex-header">
            <div className="logo">
              <a
                className="cursorPointer"
                href={
                  role == "User"
                    ? "/home/user"
                    : role == "Actor"
                      ? "/home/actor"
                      : "/"
                }
              >
                <img src={logo} alt="logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="page-section">
        <div className="flex-container ">
          <div className="title">
            <h1>Terms And Conditions</h1>
          </div>
          <div className="content miscellaneous">
            <p>
              <i>Last updated: February 2024</i>
            </p>
          </div>
          <div className="content miscellaneous">
            <p>
              The website www.LiveStage.stream and the LiveStage App
              (hereinafter both referred to as the “<b>Platform</b>” or “
              <b>LiveStage</b>”) belong to the Company “LiveStage Streaming Services Ltd” (“
              <b>Company</b>”), with registered seat in Cyprus, 19A Nafpaktou
              Str., 3051 Limassol. LiveStage is a live streaming and ticketing
              platform which hosts live and interactive performances (“
              <b>Events</b>”) from various artists and artistic groups (“
              <b>Artists</b>”, “<b>Performers</b>” or just “<b>You</b>”).
              Performers can stream their Events through a virtual stage which
              is offered by LiveStage to the end users of the Platform (“
              <b>End Users</b>”).
            </p>
            <p>
              You are subject to the following Terms and Conditions (“
              <b>Terms</b>”), which you are requested to read carefully and use
              LiveStage Services (“<b>Platform Services</b>”) only if you
              totally agree with them and accept their full application. By
              using the Platform by any means, you totally accept these Terms.
              These Terms as well as the{" "}
              <span
                className="cursorPointer"
                onClick={() => history.push("/privacy")}
              >
                <b>
                  <u>Privacy Policy</u>
                </b>
              </span>{" "}
              of LiveStage are the only legally binding agreement between you
              and the Company, and they apply with full force, just as if they
              had been made in writing and signed. If you do not agree to these
              Terms, you should not use LiveStage.
            </p>
            <p>
              Company reserves the right to modify at any time the contents and
              scope of these Terms even without notice. For this reason, we
              encourage you to visit this page from time to time to get informed
              of any changes. In the event of any subsequent amendment of the
              Terms, if you use the Website at a future date, this shall imply
              that you accept the new Terms.
            </p>
            <p>
              You must be eighteen years old or over to register as an
              Artist/Performer and use the Platform Services. By agreeing to
              these Terms or by using the Platform Services, you represent and
              warrant that you have the authority, right, and capacity to enter
              into this Agreement and that you abide by all of the present
              Terms.
            </p>
          </div>
          <div className="content miscellaneous">
            <h3>1. LIVE STAGE PERFORMER ACCOUNT </h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>1.1 Sign up</b>. To use the Platform Services, you have to
                create a personal account (“<b>Livestage Performer Account</b>”
                or “<b>Account</b>”). To this end, you have to submit the
                requested information (e.g., full name, valid e-mail address,
                phone number, password, your official page, category, photo of
                personal ID, country of issue of ID, selfie, bank details,
                etc.). We will process your request and approve or reject your
                application for registration. If we accept your application, you
                will receive an automated e-mail with a link to verify your
                Account. Once you verify your Account, you will receive a second
                automated e-mail informing you that the registration procedure
                is complete.{" "}
              </p>
              <p>
                <b>1.2 Sign in</b>. Once your Account is activated, you will be
                able to login by using the email address and password indicated.
                You can also sign in with Google or Facebook accounts.
              </p>
              <p>
                <b>1.3 Account details</b>. You can edit your Account details at
                any time (e.g., add profile photo, description, company details,
                link with accounts on other platforms such as Google or
                Facebook, manage bank account, etc.).{" "}
              </p>
              <p>
                <b>1.4 Create an Event</b>. LiveStage provides you with a
                virtual stage where you can make available your music, songs,
                stage shows, stand up comedies, etc. (the “Content”) to the End
                Users of the Platform. You can create either Public Events for
                individual End Users or Corporate Events. A Corporate Event is
                an event where tickets are available only to venues and your
                Content will be available to their multiple End Users. If you
                wish to create a Corporate Event though, you must submit your
                company details, as it is considered a B2B event. If you do not
                have company details you can only stream Public Events. To
                create an Event, you are required to fill in certain information
                about the Event (e.g., select audience type, Event category,
                Event title, ticket type, determine how much you would like to
                earn per ticket, set Event date, time zone, duration, Event’s
                description, upload Event picture or video etc.). Once you
                create your Event, you will receive a confirmation e-mail about
                the Event. This Event will be listed in the section “My events”
                on your Account, where you can see all your upcoming or past
                Events.{" "}
              </p>
              <p>
                <b>1.5 Contact us</b>. In case you have any questions, you can
                contact us by submitting a message in the “<b>Contact Us</b>”
                form.{" "}
              </p>
              <p>
                <b>1.6 </b>You are personally and solely liable for the Content
                you distribute via the Platform and any and all actions that
                take place under your Account. You are also responsible for your
                proper Log-Out after the end of each use of the website Services
                and any breach of your Account, caused by your fault or your
                improper use of the Platform Services. You are obliged to
                immediately inform the Company in case you become aware of any
                unauthorized use of your Account or any possible security
                violation. You are solely responsible for the careful use of
                your Account. Company is not liable for any damage caused to
                Performers who fail to comply with the present condition.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>2. PERFORMER’S OBLIGATIONS</h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>2.1</b> You shall be solely responsible for your Content and
                the consequences of distributing them via the Platform. You
                affirm, represent, and/or warrant that:{" "}
              </p>
              <p>
                (A) you are the creator and owner of the Content you share via
                the Platform and/or have all necessary licenses, rights,
                consents, releases and permissions to use this Content and to
                authorize LiveStage to make available your Content to its
                users/viewers;{" "}
              </p>
              <p>
                (B) your Content do not and will not: (1) infringe, violate, or
                misappropriate any third-party right, including any copyright,
                trademark, patent, trade secret, moral right, privacy right,
                right of publicity, or any other intellectual property or
                proprietary right or (2) slander, defame, or libel any other
                person; (3) contain any viruses, adware, spyware, worms, or
                other malicious code.{" "}
              </p>
              <p>
                (C) You will not stream your Event on a different platform,
                website or application, post or publish your Content on any
                social media or anywhere else on the internet, with or without a
                fee at the same time that you stream your content on LiveStage.
              </p>
              <p>
                <b>2.2</b> Throughout the duration of this Agreement and for six
                (6) months after its termination for any reason, you are not
                allowed, to compete against the Company and the Platform
                Services, by providing similar services to other platforms,
                regardless of remuneration, to any person or legal entity inside
                or outside Cyprus, which has a competing business activity in
                relation to LiveStage. The term “competing activity” implies any
                platform, application, website or any other activity which
                offers live shows or events via the internet with a ticket or
                any kind of fee for the user.
              </p>
              <table style={{ marginBottom: "10px" }}>
                <tr>
                  <td>
                    <b>2.3.1</b>
                  </td>
                  <td style={{ textAlign: "initial", paddingLeft: "10px" }}>
                    Although your Content remains yours and you retain all
                    ownership to your Content you hereby grant LiveStage
                    Platform a worldwide perpetual, royalty-free right and
                    license to broadcast and make available your Content to
                    unlimited number of users via the Platform. You also grant
                    LiveStage a right to store on servers or on the cloud,
                    encode, compress, use, modify and process your Content in
                    ways appropriate and suitable for the purpose of making it
                    available to the public via the Platfrom. You grant
                    End-Users the right to access and view your Content for
                    personal, non-commercial use. If you create a Corporate
                    Event you grant Corporates the right to broadcast your
                    Content to their End-Users.
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <b>2.3.2</b>
                  </td>
                  <td style={{ textAlign: "initial", paddingLeft: "10px" }}>
                    You give permission to LiveStage to use your name, company
                    name, artistic name and your Content for marketing and
                    advertising purposes and for promoting its business.
                  </td>
                </tr>
                <br />
                <tr>
                  <td>
                    <b>2.3.3</b>
                  </td>
                  <td style={{ textAlign: "initial", paddingLeft: "10px" }}>
                    LiveStage may advertise third parties on the Platform and/or
                    during your live Event. You acknowledge and accept that
                    third parties’ logos, trademarks and products may appear to
                    the End-Users during your live Event. By accepting these
                    Terms & Conditions you grant LiveStage permission to
                    advertise third parties during your Events.
                  </td>
                </tr>
              </table>

              <p>
                <b>2.4</b> Performers are solely responsible for their
                compliance with any Collective Management Organization rules and
                contracts.
              </p>
              <p>
                <b>2.5</b> Performers are solely responsible for the declaration
                of their income arising from the Events organized through the
                Platform to the competent tax authorities.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>3. INTELLECTUAL PROPERTY RIGHTS</h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>3.1</b> All Platform material, including, but not limited to
                text, data, graphics, logos, button icons, images and software
                is owned, controlled by, licensed to, or used with permission by
                the Company and is protected by copyright, trademark, and other
                intellectual property rights. The Platform material is made
                available solely for the use defined in the present Terms. You
                may not copy, reproduce, republish, modify, upload, post,
                transmit, or distribute in any way, including by e-mail or other
                electronic means, decompile or interfere in any way with the
                Services material.{" "}
              </p>
              <p>
                <b>3.2</b> If you believe that any intellectual property right
                has been infringed in the Services, please notify the Company
                immediately at desk@livestage.stream. When you contact us about
                copyright infringement issues, additional information about you
                or your work may be requested.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>4. PROCESSING OF PERSONAL DATA</h3>
            <div style={{ padding: "10px" }}>
              <p>
                The processing of your personal data is carried out in
                accordance with the{" "}
                <span
                  className="cursorPointer"
                  onClick={() => history.push("/privacy")}
                >
                  <u>Privacy Policy</u>
                </span>{" "}
                and the{" "}
                <span
                  className="cursorPointer"
                  onClick={() => history.push("/cookiePreference")}
                >
                  <u>Cookies Policy</u>
                </span>{" "}
                of the Website.{" "}
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>5. FEES</h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>5.1 Self-billing</b>. By creating an Account on LiveStage,
                you agree on self-billing. Self-billing means that you authorize
                LiveStage to issue invoices on behalf of you to itself in order
                for you to get paid for your performance.
              </p>
              <p>
                <b>5.2 Ticket Fee</b>. You will receive a certain fee for every
                paid ticket for your Events, as set forth herein. You will set
                the amount of fee that you wish to receive per ticket (“
                <b>Performer Fee</b>”) when creating your Event. By setting the
                amount of fee, Company will automatically determine the selling
                ticket price including VAT, which is based on the following
                formula:{" "}
              </p>
              <p>
                ((Performer Fee / (100 - Margin) * 100) + Transaction & Service
                Fee) * (1+ VAT %) = Selling Price.
              </p>
              <p>
                Margin = 20 <br /> Transaction & Service Fee = 1.50 euro <br />{" "}
                VAT: 5%.{" "}
              </p>
              <p>
                This formula may change at any time. The formula may be amended
                without notice in case of relevant law amendments. However, in
                case of material changes introduced by us and affecting our
                pricing policy, including this formula, you will be informed
                with a notification to your account.
              </p>
              <p>
                <b>5.3 Tips</b>. During an Event, Users may also tip Performers
                by selecting one of the amount choices provided (e.g., 5 or 10
                euros). Tipping is conducted by card. Your Tips will appear
                publicly during the Event. The Artist will receive 75% of the
                total tips derived from each Event he creates and is streamed
                successfully.{" "}
              </p>
              <p>
                <b>5.4 Payment method</b>. Fees shall be deposited to your bank
                account within 3-10 working days after the end of each Event by
                direct bank transfer to the bank account that you filled out in
                the billing information of your account. You will bear the
                transfer costs.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>6. CODE OF CONDUCT</h3>
            <div style={{ padding: "10px" }}>
              <p>
                The following rules, policies, and disclaimers shall govern
                and/or apply to your use of the Platform. You agree that by
                using the Platform you shall not:
              </p>
              <p>
                1. Upload, post, transmit or otherwise disseminate Content that
                is vulgar, indecent, obscene, pornographic, sexual or that is,
                in a reasonable person's view, otherwise offensive or
                objectionable;
              </p>
              <p>
                2. Libel, ridicule, defame, mock, stalk, intimidate, threaten,
                harass, or abuse anyone, hatefully, racially, ethnically or in
                any other manner;
              </p>
              <p>
                3. Upload or transmit (or attempt to upload or transmit) files
                that contain viruses, Trojan horses, worms, time bombs,
                cancelbots, corrupted files or data, or any other similar
                software or programs that may damage the operation of LiveStage
                or the computers of other users of the Platform;
              </p>
              <p>
                4. Violate the contractual, personal, intellectual property or
                other rights of any party including by using, uploading,
                transmitting, distributing, or otherwise making available any
                information or material made available through the Service in
                any manner that infringes any copyright, trademark, patent,
                trade secret, or other right of any party (including rights of
                privacy or publicity);
              </p>
              <p>
                5. Create false personas, multiple identities, multiple user
                Accounts, set up an Account on behalf of someone other than
                yourself, use bots or other automated software programs to
                defraud or which otherwise violate these Terms and Conditions.
              </p>
              <p>
                6. Post or publish by any means personal data of other persons
                without their explicit consent.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>7. TERMINATION</h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>7.1</b> The Company may terminate or suspend any and all
                Services, as well as any registered Account immediately, without
                prior notice or liability, for any reason, including the breach
                of any of these Terms and Conditions. Upon termination of the
                Account, your right to use the Services will automatically
                cease.
              </p>
              <p>
                <b>7.2</b> If you wish to terminate your Account, you can simply
                stop using the Services of the Platform and delete your Account.
                The provision under paragraph 2.2 continues to apply afterwards.
              </p>
              <p>
                <b>7.3</b> Upon termination of your Account for any reason,
                except in case of breach of the present Terms, your Fees will be
                calculated and transferred to you.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>8. NO WARRANTY</h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>8.1</b> All services and any kind of content of the Website
                is offered ‘as is’, with no guarantee, explicit or implied, of
                any kind in relation to its commercial use or its suitability
                for any specific purpose.
              </p>
              <p>
                <b>8.2</b> No explicit guarantee is given that the pages,
                services, features, options and contents of the Website will be
                provided without interruption or errors, or that any errors will
                be corrected. Despite our efforts, there is no guarantee that
                the Website does not contain viruses or other harmful content.
              </p>
              <p>
                <b>8.3</b> LiveStage may modify, update, or change the scope of
                the Services at any time. If we consider that a change of scope
                is of major importance to you, you will be notified with a
                statement sent to your account.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>9. LIMITATION OF LIABILITY</h3>
            <div style={{ padding: "10px" }}>
              <p>
                <b>9.1</b> Given the nature and volume of the information on the
                Internet, under no circumstances, including that of negligence,
                shall Company or its representatives be held liable for any form
                of loss or damage that you may sustain from the use of the
                services, options and content of the Website. You access and use
                the Platform and you provide content entirely and solely at you
                own initiative and responsibility. Under no circumstances,
                including, but not limited to, negligence will the Company or
                its representatives be held liable for any form of loss or
                damage arising out of or relating to these Terms and Conditions
                or that result from your use or your inability to use the
                Services of the Platform.{" "}
              </p>
              <p>
                <b>9.2</b> We cannot guarantee that there will be no
                unauthorized use of the Content by Platform Users and LiveStage
                shall not be held liable for any copying, downloading,
                publishing or exploitation on any other way of your Content by
                the End Users.
              </p>
              <p>
                <b>9.3</b> During an Event a public chat is available to all
                End-Users who have bought a ticket. End-Users will be able to
                comment on the live chat during the Event, and LiveStage does
                not perform any check or control on their comments. You
                acknowledge and accept that You and other End-Users may be
                exposed to offensive, indecent, inappropriate or even illegal
                and harmful content in the live chat of the Event. You may
                report the user posting this kind of content and they will be
                banned from the live chat of the Event. Under no circumstances,
                including, but not limited to, negligence will the Company or
                its representatives be held liable for any form of loss or
                damage that you or other End-Users may sustain from the use of
                the live chat.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>10. FORCE MAJEURE</h3>
            <div style={{ padding: "10px" }}>
              <p>
                In no event shall Company be held liable to you for any failure,
                delay, malperformance or non-performance of its obligations
                arising out or caused by forces beyond its reasonable control
                including without limitation strikes, acts of war or terrorism,
                pandemic, lockdown, governmental orders, civil or military
                disturbances, natural catastrophes, loss or malfunctions of
                utilities, communications or computer services. Company’s
                obligations may be suspended during a force majeure event.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>11. INDEMNIFICATION</h3>
            <div style={{ padding: "10px" }}>
              <p>
                You are liable towards the Company and its Third Party
                providers, for any damage caused as a result of illegal or
                harmful use of the Website from your side, as well as for the
                illicit use of the Platform Services or any use in a manner that
                does not comply with the present Terms and Conditions. You agree
                to indemnify, defend and hold the Company harmless immediately
                on demand, from and against all liability, damages, losses,
                costs and expenses, including legal fees, arising out of any
                breach of these Terms by you or other liabilities arising out of
                your use of LiveStage.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>12. APPLICABLE LAW AND OTHER TERMS</h3>
            <div style={{ padding: "10px" }}>
              <p>
                The above Terms, and any amendment thereof, are governed and
                supplemented by Cypriot law, the law of the European Union and
                the relevant international conventions to which Cyprus is a
                signatory. It is agreed that all the above Terms are of equal
                substance. If any provision of the above is contrary to the law,
                shall automatically cease to apply and shall be removed, without
                affecting in any way the validity of the other terms. You
                irrevocably agree that the courts of Nicosia Cyprus shall have
                exclusive jurisdiction to settle any dispute arising out of
                these Terms & Conditions.
              </p>
            </div>
          </div>
          <div className="content miscellaneous">
            <h3>13. DISCLOSURES</h3>
            <div style={{ padding: "10px" }}>
              <p>
                If you have any questions regarding the above Terms or would
                like more information, please contact us via e-mail at
                desk@livestage.stream or write to us at 103B Arch. Makarios III
                Avenue, 3021 Limassol, Cyprus.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER }) => {
  return {
    role: USER_REDUCER.userData?.role,
  };
};

export default connect(mapStateToProps, null)(TermsCond);
