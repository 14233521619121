import React, { useEffect, useState } from "react";
import iconClose from "../../../assets/img/letter-x.png";
import iconCal from "../../../assets/img/icon-cal.svg";
import iconWatch from "../../../assets/img/icon-watch.svg";
import iconCard from "../../../assets/img/icon-card.svg";
import iconShare from "../../../assets/img/share.png";
import { connect } from "react-redux";
import {
  GetEventDetails,
  CancelEvent,
  RescheduleEvent,
  CancelReasonList,
  CheckEventStartTime,
} from "../../../redux/actions/events";
import { Notify } from "../../../helpers/notifications";
import { Validation } from "../../../helpers/validations";
import { useHistory } from "react-router";
import ShareEvent from "../shareEvent";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getEventLocalDate } from "../../../helpers/constants";
import Logo from "../../loader/Loader";
import copy from "copy-to-clipboard";
import Store from "../../../redux/store/store";

const localTZone = moment.tz.guess();
function EventDetails({
  myEventsData,
  setShowEventDetails,
  setSelectedTab,
  eventType,
  eventIndex,
  notiEventID,
}) {
  const history = useHistory();
  const [eventData, setEventData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelNote, setCancelNote] = useState("");
  const [eventCat, setEventCat] = useState(eventType);
  const [eventRescheduleDate, setEventRescheduleDate] = useState("");
  const [eventRescheduleTime, setEventRescheduleTime] = useState("");
  const [unixDate, setUnixDate] = useState("");
  const [error, setError] = useState({});
  const [cancelError, setCancelError] = useState({});
  const [videoMute, setVideoMute] = useState(true);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [cancelReasonListing, setCancelReasonListing] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const { dispatch } = Store.store;

  useEffect(() => {
    document.title = "LiveStage | Actor - Event Details";
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    dispatch({ type: "ACTOREVENTFILE", payload: { showActorFile: true } });
    return () => {
      dispatch({ type: "ACTOREVENTFILE", payload: { showActorFile: false } });
    };
  }, []);

  useEffect(() => {
    setShowLoader(true);
    GetEventDetails(
      notiEventID ? notiEventID : myEventsData[eventType][eventIndex]._id
    )
      .then((response) => {
        setEventData(response.data.data);
        localStorage.setItem("firstStream", response.data.data.mediaLive_stream_start)
        let fullTime = response.data.data.eventTime
          .split(":")
          .join(" ")
          .split(" ");
        let hours = fullTime[0];
        if (fullTime[2] == "PM" && fullTime[0] !== "12") {
          hours = (parseInt(fullTime) + 12).toString();
        }

        dispatch({
          type: "EVENTPREVIEWFILE",
          payload: {
            eventFile: response.data.data.eventImage,
            videoStatus: response.data.data.videoStatus,
          },
        });
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  }, [triggerUpdate, notiEventID]);

  useEffect(() => {
    CancelReasonList()
      .then((response) => {
        setCancelReasonListing(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  // Cancel event handler
  const handleCancelEvent = async () => {
    setTriggerUpdate(false);
    if (Validation.empty(cancelReason)) {
      setCancelError({
        ...cancelError,
        reason: "Please select a reason to Cancel Event",
      });
      return;
    } else if (Validation.empty(cancelNote)) {
      setCancelError({ ...cancelError, note: "Cancel Note is required" });
      return;
    }

    let payload = {
      eventId: eventData._id,
      cancelReason: cancelReason,
      notes: cancelNote,
    };

    try {
      const response = await CancelEvent(payload);

      if (response && response.status === 200) {
        setTriggerUpdate(true);
        setEventCat("pastData");
        setShowCancel(false);
        Notify("success", "Event has been cancelled successfully!!");
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  // Reschedule Event Handler
  const handleRescheduleEvent = async () => {
    setTriggerUpdate(false);
    let fields = ["eventDate", "eventTime"];
    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;

    let finalTime = [
      `${eventRescheduleTime.getHours()}`,
      `${eventRescheduleTime.getMinutes()}`,
    ];
    if (finalTime[0].length < 2) {
      finalTime[0] = "0" + finalTime[0];
    }
    if (finalTime[1] < 2) {
      finalTime[1] = finalTime[1] + "0";
    }

    let payload = {
      eventId: eventData._id,
      eventDate: moment(eventRescheduleDate).format("YYYY-MM-DD"),
      eventTime: `${finalTime[0]}:${finalTime[1]}`,
      unqueEventTimeStamp:
        parseInt(
          +moment.tz(
            `${moment(eventRescheduleDate).format("YYYY-MM-DD")} ${finalTime[0]
            }:${finalTime[1]}`,
            eventData.setTimeZone
          )
        ) / 1000,
      type: "web",
      userTimeZone: localTZone
    };

    try {
      const response = await RescheduleEvent(payload);
      if (response && response.status === 200) {
        setTriggerUpdate(true);
        setShowReschedule(false);
        Notify("success", "Event has been re-scheduled successfully!!");
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
      setShowReschedule(false);
    }
  };

  const handleValidation = (type) => {
    if (type === "eventDate") {
      if (
        Validation.empty(
          eventRescheduleDate ? eventRescheduleDate?.toISOString() : ""
        )
      ) {
        setError({ ...error, eventDate: "Event Date is required" });
        return false;
      }
    } else if (type === "eventTime") {
      if (
        Validation.empty(
          eventRescheduleTime ? eventRescheduleTime?.toISOString() : ""
        )
      ) {
        setError({ ...error, eventTime: "Event Time is required" });
        return false;
      }
    }
    return true;
  };

  // Handle Unix Date Format
  const handleUnixDate = (e, type) => {
    if (type === "date") {
      setEventRescheduleDate(e);
      if (eventRescheduleTime) {
        var a = e;
        var b = new Date(unixDate);
        b.setFullYear(a.getFullYear(), a.getMonth(), a.getDate());
        setUnixDate(+b);
      } else {
        setUnixDate(+e);
      }
    } else if (type === "time") {
      setEventRescheduleTime(e);
      if (eventRescheduleDate) {
        var b = new Date(unixDate);
        b.setHours(e.getHours(), e.getMinutes(), "00", "00");
        setUnixDate(+b);
      } else {
        setUnixDate(
          +new Date().setHours(e.getHours(), e.getMinutes(), "00", "00")
        );
      }
    }
  };

  const checkEventTime = async (id, shareURL, platform) => {
    let match = localStorage.getItem("petros-unique-id")
      ? localStorage.getItem("petros-unique-id")
      : "";

    let payload = {
      eventId: id,
      fcmToken: localStorage.getItem("uniqueTokenforStreaming"),
      uniqueId: match,
    };
    try {
      const response = await CheckEventStartTime(payload);
      if (response && response.status === 200) {

        if (response.data.status) {
          localStorage.setItem("petros-unique-id", response.data.data.uniqueId);
          localStorage.setItem(
            "uniqueTokenforStreaming",
            response.data.data.fcmToken
          );
          history.push(`/streamEvent`, { eventId: id, shareEventURL: shareURL, platform: platform });
        } else {
          Notify("danger", `${response.data.message}`);
        }
      }
    } catch (error) {
      Notify("danger", `${error.response.data.message}`);
    }
  };

  return (
    <div>
      <section
        className="events-section"
        style={{ position: "relative", zIndex: 2 }}
      >
        <div className="flex-container">
          <div className="breadcrumbs">
            {history.location.pathname === "/home/actor/allevents" ? (
              <ul>
                <li>
                  <a
                    className="cursorPointer"
                    onClick={() => {
                      dispatch({ type: "EMPTY_EVENTID" });
                      setSelectedTab(1)
                    }}
                  >
                    Home
                  </a>{" "}
                  <span>&#62;</span>{" "}
                </li>
                <li>
                  <a
                    className="cursorPointer"
                    onClick={() => setShowEventDetails(false)}
                  >
                    My Events
                  </a>{" "}
                  <span>&#62;</span>{" "}
                </li>
                <li>
                  <a
                    className="cursorPointer"
                    onClick={() => setShowEventDetails(false)}
                  >
                    {(eventType === "upCommingData" || (eventData?.platform == "easyStream" && eventData?.eventStatus === "active"))
                      ? "Upcoming Events"
                      : "Past Events"}
                  </a>{" "}
                  <span>&#62;</span>{" "}
                </li>
                <li>Event Details </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <a
                    className="cursorPointer"
                    onClick={() => {
                      dispatch({ type: "EMPTY_EVENTID" });
                      setShowEventDetails(false);
                    }}
                  >
                    Home
                  </a>{" "}
                  <span>&#62;</span>{" "}
                </li>
                <li>Event Details </li>
              </ul>
            )}
          </div>

          {showLoader ? (
            <div>
              <Logo />
            </div>
          ) : (
            <div className="event-details">
              {(eventType === "upCommingData" || eventData.eventStatus === "active") &&
                !eventData.isCancelled && (
                  <div className="cancel-event">
                    <div className="shareevent-wrap">
                      <button
                        className="btn-cancelEvent"
                        type="button"
                        onClick={() => setOpenShare(!openShare)}
                      >
                        <span>Share Event</span>
                        <img src={iconShare} alt="share" />
                      </button>
                      <div className="sharedialog-wrap">
                        <ShareEvent
                          open={openShare}
                          shareEventID={
                            notiEventID
                              ? notiEventID
                              : myEventsData[eventType][eventIndex]._id
                          }
                          shareEventURL={notiEventID
                            ? eventData.share_url : myEventsData[eventType][eventIndex].share_url}
                        />
                      </div>
                    </div>
                    <div className="cancelevent-wrap">
                      <button
                        className="btn-cancelEvent"
                        type="button"
                        onClick={() => setShowModal(true)}
                      >
                        <span>Change Date / Cancel Event</span>
                        <img src={iconClose} alt="close" />
                      </button>
                    </div>
                  </div>
                )}

              <h1>{eventData.eventTitle}</h1>
              <div className="content">
                <div className="date-time">
                  <div className="flex-row">
                    <div>
                      <img src={iconCal} alt="User" />
                      {
                        getEventLocalDate(
                          eventData.eventDate,
                          eventData.eventTime,
                          eventData.setTimeZone
                        ).split(",")[0]
                      }
                    </div>
                    <div>
                      <img src={iconWatch} alt="User" />
                      {
                        getEventLocalDate(
                          eventData.eventDate,
                          eventData.eventTime,
                          eventData.setTimeZone
                        ).split(",")[1]
                      }
                    </div>
                  </div>

                  <div className="flex-row">
                    <div>
                      <div>EVENT ID: #{eventData?.randomEventId}</div>
                      {!eventData?.isForAllCorporate ? (
                        <div className="description-scroll">
                          {eventData.corporatePrice?.map((emailItem, index) => (
                            <div key={index}>
                              <img src={iconCard} alt="User" /> €{" "}
                              {parseFloat(emailItem.price).toFixed(2)} - {emailItem.email}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <>
                          <img src={iconCard} alt="User" /> € {parseFloat(eventData?.price).toFixed(2)}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <p className="cat">
                  Category: {eventData?.eventCategory} <br />
                  Total Tickets Sold: {eventData?.soldTickets} <br />
                  {(eventType === "upCommingData" || eventData?.eventStatus === "active")
                    && <>
                      Tickets Remaining: {
                        eventData.ticketType === "Unlimited" ||
                          eventData.audienceType === "Corporate"
                          ? "Available"
                          : eventData?.availableTickets
                      }
                      <br />
                    </>
                  }
                  {(eventType === "pastData" || eventData?.eventStatus === "cancelled"
                  ) && <>
                      Amount Earned: €{eventData.earnedAmount} <br />
                      Rating: {eventData.rate_percentage}%
                    </>}
                </p>
                <p className="cat">
                  Duration:{" "}
                  {parseInt(parseInt(eventData?.eventDuration) / 60) >= 1 &&
                    `${parseInt(
                      parseInt(eventData?.eventDuration) / 60
                    )} hours`}{" "}
                  {parseInt(parseInt(eventData?.eventDuration) % 60) > 0 &&
                    `${parseInt(
                      parseInt(eventData?.eventDuration) % 60
                    )} minutes`}
                </p>
                <p className="desc">
                  <strong>Description:</strong>
                </p>
                <div className="description-scroll">
                  <p style={{ whiteSpace: "pre-line" }}>
                    {eventData?.description}
                  </p>
                </div>
              </div>

              {(eventCat === "upCommingData" || eventData.eventStatus === "active") && !eventData.isCancelled && ((eventData.platform == "rtmp" && !eventData.mediaLive_stream_end) || (eventData.platform == "easyStream" && !eventData.isSessionEnd)) && (
                <button
                  className="primary-button cursorPointer"
                  type="button"
                  onClick={
                    () => { checkEventTime(eventData?._id, eventData?.share_url, eventData?.platform); }
                  }
                >
                  Start the Event
                </button>
              )}
              {eventData.isCancelled && (
                <span style={{ color: "#e50914", fontSize: "30px" }}>
                  Event Cancelled
                </span>
              )}
            </div>
          )}
        </div>
      </section>

      {/* Event cancellation - Popup model */}
      <div
        className={`popup-model cancel-reason-model ${showCancel && "show"}`}
      >
        <div className="model-head">
          <h2>Please choose the reason of cancellation</h2>
          <a
            className="model-close cursorPointer"
            onClick={() => {
              setShowCancel(false);
              setCancelError({});
              setCancelNote("");
              setCancelReason("");
            }}
          >
            &#10006;
          </a>
        </div>
        <div className="model-body">
          <div className="model-content">
            <form>
              <div className="field-wrap">
                <div className="custom-select-dropdown">
                  <select
                    value={cancelReason}
                    onChange={(e) => setCancelReason(e.target.value)}
                    onFocus={(e) =>
                      setCancelError({ ...cancelError, reason: "" })
                    }
                    onBlur={(e) => {
                      if (Validation.empty(cancelReason)) {
                        setCancelError({
                          ...cancelError,
                          reason: "Please select a reason to Cancel Event",
                        });
                      }
                    }}
                  >
                    <option value="">Select Reason</option>
                    {cancelReasonListing.map((item, index) => (
                      <option key={index} value={item.reason}>
                        {item.reason}
                      </option>
                    ))}
                  </select>
                  {cancelError && (
                    <span style={{ color: "red" }}>{cancelError.reason}</span>
                  )}
                </div>
              </div>
              <div className="field-wrap">
                <textarea
                  className="reason-deactivate fullWidth"
                  placeholder="Add note"
                  value={cancelNote}
                  onChange={(e) => setCancelNote(e.target.value)}
                  onFocus={() => setCancelError({ ...cancelError, note: "" })}
                  onBlur={() => {
                    if (Validation.empty(cancelNote)) {
                      setCancelError({
                        ...cancelError,
                        note: "Cancel Note is required",
                      });
                    }
                  }}
                ></textarea>
                {cancelError && (
                  <span style={{ color: "red" }}>{cancelError.note}</span>
                )}
              </div>
              <div className="btn-row text-center">
                <button
                  className="primary-button submit-cancellation"
                  type="button"
                  onClick={() => handleCancelEvent()}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={`overlay ${showCancel && "show"}`}></div>

      {/* Confirmation cancel event - Popup model */}
      <div className={`popup-model-alert ${showModal && "show"}`}>
        <div className="model-head remove-border">
          <a
            className="model-close cursorPointer"
            onClick={() => setShowModal(false)}
          >
            &#10006;
          </a>
        </div>
        <div className="model-body">
          <div className="model-content">
            <h2>Are you sure you want to Cancel the event or Reschedule</h2>
            <div className="btn-row text-center">
              <button
                className="primary-button"
                type="button"
                onClick={() => {
                  setShowModal(false);
                  setShowCancel(true);
                }}
              >
                Cancel Event
              </button>
              <button
                className="secondary-button btn-reschedule"
                type="button"
                onClick={() => {
                  setShowModal(false);
                  setShowReschedule(true);
                }}
              >
                Reschedule
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`overlay ${showModal && "show"}`}></div>

      {/* Reshedule event - Popup model */}
      <div
        className={`popup-model reschedule-model ${showReschedule && "show"}`}
      >
        <div className="model-head">
          <h2>Rescheduling Event</h2>
          <a
            className="model-close cursorPointer"
            onClick={() => {
              setShowReschedule(false);
              setError({});
              setEventRescheduleDate("");
              setEventRescheduleTime("");
              setUnixDate("");
            }}
          >
            &#10006;
          </a>
        </div>
        <div className="model-body">
          <div className="model-content">
            <form>
              <div className="field-wrap">
                <label>Provide date</label>
                <div className="custom-date-time">
                  <DatePicker
                    selected={eventRescheduleDate}
                    placeholderText="Select Date"
                    minDate={new Date(new Date().setDate(new Date().getDate()))}
                    onChange={(date) => {
                      handleUnixDate(date, "date");
                    }}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    className="datepicker-react-input"
                    onFocus={(e) => setError({ ...error, eventDate: "" })}
                    onBlur={(e) => handleValidation("eventDate")}
                  />
                  {error && (
                    <span style={{ color: "red" }}>{error?.eventDate}</span>
                  )}
                </div>
              </div>
              <div className="field-wrap">
                <label>Provide time</label>
                <div className="custom-date-time">
                  <DatePicker
                    selected={eventRescheduleTime}
                    placeholderText="Select Time"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={30}
                    dateFormat="h:mm aa"
                    className="datepicker-react-input"
                    onChange={(date) => handleUnixDate(date, "time")}
                    onFocus={(e) => setError({ ...error, eventTime: "" })}
                    onBlur={(e) => handleValidation("eventTime")}
                  />
                  {error && (
                    <span style={{ color: "red" }}>{error?.eventTime}</span>
                  )}
                </div>
              </div>

              {eventData.rescheduleCount === 2 && (
                <div className="field-wrap">
                  <span style={{ color: "red" }}>
                    This is the last reschedule of your event. Please make sure
                    that your event will be streamed as your fans are waiting!
                  </span>
                </div>
              )}

              <div className="btn-row text-center">
                <button
                  className="primary-button submit-cancellation"
                  type="button"
                  onClick={() => handleRescheduleEvent()}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={`overlay ${showReschedule && "show"}`}></div>
    </div>
  );
}

const mapStateToProps = ({ EVENTS_REDUCER, NOTIF_REDUCER }) => {
  return {
    myEventsData: EVENTS_REDUCER?.myEventsData,
    notiEventID: NOTIF_REDUCER.notiEventID
  };
};

export default connect(mapStateToProps, {})(EventDetails);