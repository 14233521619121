import React, { useEffect, useState, useReducer, useRef, useLayoutEffect } from "react";
import viewerCountIcon from "../../assets/img/viewerCount.svg";
import iconChat from "../../assets/img/icon-chat.svg";
import iconHeartRed from "../../assets/img/icon-heartred.svg";
import iconSpeaker from "../../assets/img/volume.svg";
import iconSpeakerMute from "../../assets/img/mute.svg";
import iconTipAdd from "../../assets/img/tip-icon.svg";
import iconTip from "../../assets/img/3x.png";
import iconShared from "../../assets/img/icon-shared.svg";
import addThumb from "../../assets/img/add-thumb.svg";
import reportEventIcon from "../../assets/img/report-event.svg";
import likeOutline from "../../assets/img/like-outline.svg";
import likeFilled from "../../assets/img/like-filled.svg";
import iconUnblockAudio from "../../assets/img/unblock-audio.png";
import dislikeOutline from "../../assets/img/dislike-outline.svg";
import dislikeFilled from "../../assets/img/dislike-filled.svg";
import fullscreenIcon from "../../assets/img/fullscreen.png";
import fullscreenOffIcon from "../../assets/img/fullscreen-off.png";
import iconEnd from "../../assets/img/icon-end.svg";
import cvvCard from "../../assets/img/icon-cvv-card.svg";
import { connect } from "react-redux";
import $ from "jquery";
import { useHistory } from "react-router";
import {
  cardImages,
  monthDropdown,
  yearDropdown,
  numFormatter,
} from "../../helpers/constants";
import { GetPaymentMethods, FileUpload, AddPaymentMethods } from "../../redux/actions/user";
import { Notify } from "../../helpers/notifications";
import {
  EventReportReasonList,
  ReportComment,
  ReportEventActor,
  TipArtist,
  RateEvent,
  RemoveViewer,
  GetCalculatedAmount,
  LikeEvent
} from "../../redux/actions/events";
import ReactHlsPlayer from "react-hls-player";
import ShareEvent from "../actor/shareEvent";
import firebase from "../../helpers/firebase";
import "firebase/firestore";
import { Validation } from "../../helpers/validations";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Grid } from "@giphy/react-components";
import Comments from "./Comments";
import Logo from "react-loader-spinner";
import Store from "../../redux/store/store";
import valid from "card-validator";

const db = firebase.firestore();

const giphyFetch = new GiphyFetch("4uPoE4bDsAewB6nPcayJeWpaJLddLUIj");

function GridDemo({ onGifClick }) {
  const fetchGifs = (offset) => giphyFetch.trending({ offset, limit: 10 });
  return (
    <>
      <Grid
        onGifClick={onGifClick}
        fetchGifs={fetchGifs}
        width={378}
        columns={3}
        gutter={6}
      />
    </>
  );
}

function updateCount(state, action) {
  switch (action.type) {
    case "increment":
      return { ...state, subsCount: action.payload - 1 };
    case "viewerCount":
      return { ...state, subsCount: action.payload - 1 };
    case "updateMessages":
      return { ...state, messages: [...state.messages, ...action.payload] };
    default:
      throw new Error();
  }
}

function SubscriberHLS({ eventId, broadCastURL, userData, blockedStatus, messageArray,
  eventID, isLiked, actorName, shareEventURL }) {
  const [state, dispatch] = useReducer(updateCount, {
    subsCount: 0,
    messages: (messageArray?.length > 0 && eventID === eventId) ? messageArray : [],
    likesCount: 0,
  });

  const playerRef = useRef();

  const history = useHistory();

  const [audio, setAudio] = useState(true);
  const [msgText, setMsgText] = useState("");
  const [showChat, setShowChat] = useState(true);
  const [showShare, setShowShare] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const [tipSuccess, setTipSuccess] = useState(false);
  const [heartLike, setHeartLike] = useState(false);
  const [tipAmount, setTipAmount] = useState("");
  const [tipValue, setTipValue] = useState("");
  const [cardImage, setCardImage] = useState("");
  const [paymentMethods, setPaymentMethods] = useState("");
  const [tipError, setTipError] = useState("");
  const [disableTipButton, setDisableTipButton] = useState(false);
  const [showReportForm, setShowReportForm] = useState(false);
  const [eReportReason, setEReportReason] = useState("");
  const [eReportNote, setEReportNote] = useState("");
  const [eReportError, setEReportError] = useState("");
  const [eReasonList, setEReasonList] = useState([]);
  const [showGif, setShowGif] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [commentBy, setCommentBy] = useState("");
  const [isReportComment, setIsReportComment] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [rating, setRating] = useState("");
  const [toggleFS, setToggleFS] = useState(false);
  const [showSomethingWentWrong, setShowSomethingWentWrong] = useState(false);
  const [showBackToHome, setShowBackToHome] = useState(false);
  const [disableChat, setDisableChat] = useState(!blockedStatus);
  const [viewC, setViewC] = useState(0);
  const [showEndSession, setShowEndSession] = useState(false);
  const [endSessionResponse, setEndSessionResponse] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryMonth, setExpiryMonth] = useState("");
  const [expiryYear, setExpiryYear] = useState("");
  const [cardCode, setCardCode] = useState("");
  const [error, setError] = useState({});
  const [addDisabled, setAddDisabled] = useState(false);
  const [tippingItems, setTippingItems] = useState([]);
  const [isCancelledByAdmin, setIsCancelledByAdmin] = useState(false);
  const [showKickOff, setShowKickOff] = useState(false);
  const [BlockAudio, setBlockAudio] = useState(false);
  const [intervalId, setIntervalId] = useState()
  const [btnDisable, setbtnDisable] = useState(false)
  const [videoPlaying, setVideoPlaying] = useState(true);
  let currentMonth = new Date().toISOString().split("-")[1];
  let currentYear = new Date().toISOString().split("-")[0];

  useEffect(() => {
    window.addEventListener('beforeunload', function (e) {
      e.preventDefault();
      e.returnValue = '';
      return "Are you sure you want to leave?";
    });
    return () => {
      window.removeEventListener('beforeunload', function (e) {
        e.preventDefault();
        e.returnValue = '';
        return "Are you sure you want to leave?";
      })
    }
  }, [])

  useEffect(async () => {
    document.title = "LiveStage";
    localStorage.setItem("flag", "false");
    playerRef.current.play().then(() => setBlockAudio(false)).catch((err) => {
      setBlockAudio(true)
    });

    // Get kickoff Status
    const checkKickOff = await db.collection("chatrooms").doc(`${eventId}`).collection("Viewers").doc(`${userData._id}`).get();
    if (checkKickOff?.data()?.kickOffStatus) {
      setShowKickOff(true);
      setVideoPlaying(false);
    }
    else {
      db.collection("chatrooms")
        .doc(`${eventId}`)
        .collection("Viewers")
        .doc(`${userData._id}`)
        .set({
          type: "user",
          status: 'active',
          platform: 'WEB',
          email: userData.email,
          timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
      db.collection("chatrooms")
        .doc(`${eventId}`)
        .collection("Viewers")
        .doc("eventStatus")
        .get().then((checkEventStatus) => {
          if (checkEventStatus?.data()?.isCancelledByAdmin) setIsCancelledByAdmin(true);
          if (checkEventStatus?.data()?.streaming === false) setShowSomethingWentWrong(true);
          setViewC(checkEventStatus?.data()?.count);
        })
        .catch((error) => {
          // setShowSomethingWentWrong(true);
        })
    }

    if (eventID !== eventId) {
      Store.store.dispatch({ type: "EMPTY_CHAT" });
      Store.store.dispatch({ type: "SET_EVENTID", payload: eventId });
    }
  }, []);

  useEffect(() => {
    !showKickOff && isCancelledByAdmin && setShowSurvey(true) && setVideoPlaying(false);
  }, [isCancelledByAdmin]);

  useEffect(() => {
    if (showKickOff) {
    }
  }, [showKickOff]);

  useLayoutEffect(() => {
    if (showSomethingWentWrong || showKickOff || isCancelledByAdmin) clearInterval(intervalId);
  }, [showSomethingWentWrong, showKickOff, isCancelledByAdmin])

  useEffect(() => {
    const fetchViewerData = async () => {
      await db.collection("chatrooms")
        .doc(`${eventId}`)
        .collection("Viewers")
        .doc(`${userData._id}`)
        .update({
          type: "user",
          status: 'active',
          email: userData.email,
          timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
      db.collection("chatrooms")
        .doc(`${eventId}`)
        .collection("Viewers")
        .doc("eventStatus")
        .get().then((checkEventStatus) => {
          if (checkEventStatus?.data()?.isCancelledByAdmin) {
            setIsCancelledByAdmin(true);
            setVideoPlaying(false);
          }
          if (checkEventStatus?.data()?.streaming === false) setShowSomethingWentWrong(true);
          setViewC(checkEventStatus?.data()?.count);
        })
        .catch((error) => {
          // setShowSomethingWentWrong(true);
        })
      // Get kickoff Status
      const checkKickOff = await db.collection("chatrooms").doc(`${eventId}`).collection("Viewers").doc(`${userData._id}`).get();
      if (checkKickOff?.data()?.kickOffStatus) {
        setShowKickOff(true);
        setVideoPlaying(false);
      }
    }
    const clearId = setInterval(() => {
      fetchViewerData();
    }, 20000);
    setIntervalId(clearId)
    return () => clearInterval(clearId);
  }, []);

  useEffect(() => {
    if (showTip) {
      GetPaymentMethods()
        .then((response) => {
          let defaultCard = response.data.data.filter(
            (item) => item.isDefault !== false
          );
          setPaymentMethods(defaultCard);
          setCardImage(
            defaultCard[0].brand
              ? cardImages[defaultCard[0].brand.toLowerCase()]
              : cardImages["default"]
          );
        })
        .catch((error) => {
        });
    }
  }, [showTip]);

  useEffect(() => {
    GetCalculatedAmount(eventId)
      .then(response => {
        setTippingItems(response.data.response.data);
      })
      .catch(error => {
      })
  }, [])

  useEffect(() => {
    heartTest();
    let divTopPos = document.getElementById("commentScrolling");
    divTopPos &&
      $("#commentScrolling").scrollTop($("#commentScrolling")[0].scrollHeight);
  }, [state.messages, showChat]);

  const heartTest = () => {
    $(".heart").off('click').on("click", function () {
      var b = Math.floor(Math.random() * 100 + 1);
      var d = ["flowOne", "flowTwo", "flowThree"];
      var a = ["colOne"];
      var c = (Math.random() * (1.6 - 1.2) + 1.2).toFixed(1);
      $(
        '<div class="heartanim part-' +
        b +
        " " +
        a[Math.floor(Math.random() * 6)] +
        '" style="font-size:' +
        Math.floor(Math.random() * (50 - 22) + 22) +
        `px;"><img src=${iconHeartRed} alt="chat"></div>`
      )
        .appendTo(".hearts")
        .css({
          animation:
            "" + d[Math.floor(Math.random() * 3)] + " " + c + "s linear",
        });
      $(".part-" + b).show();
      setTimeout(function () {
        $(".part-" + b).remove();
      }, c * 900);
    });
  }

  useEffect(() => {
    if (db) {
      // GET MESSAGES
      const unsubscribe = db
        .collection("chatrooms")
        .doc(`${eventId}`)
        .collection("messages")
        .orderBy("createdAt", "desc")
        .limit(1)
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.metadata.hasPendingWrites) {
            const data = querySnapshot.docs.map((doc) => {
              if (doc.data().type === "userMessage") {
                return doc.data().data;
              } else if (doc.data().type === "tipArtist") {
                return doc.data().data;
              } else if (doc.data().type === "chatImage") {
                return doc.data().data;
              } else if (doc.data().type === "blockUser") {
                if (doc.data().data.userId === userData._id) {
                  setDisableChat(true);
                }
              } else if (
                doc.data().type === "endSession" ||
                doc.data().type === "autoEndSession"
              ) {
                setVideoPlaying(false);
                !showKickOff && setShowSurvey(true);
              }
            });
            if (localStorage.getItem("flag") !== "false") {
              if (data[0]) {
                dispatch({ type: "updateMessages", payload: data });
                Store.store.dispatch({ type: "CHAT_MESSAGES", payload: data });
              }
            } else {
              localStorage.setItem("flag", "true");
            }
          }
        });

      return unsubscribe;
    }
  }, [db]);

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem("testRemoveViewer", true);
    }, 500);
    return () => {
      localStorage.removeItem("testRemoveViewer");
      localStorage.setItem("flag", "false");
      localStorage.getItem("testRemoveViewer") && RemoveViewer({ eventId: eventId });
      localStorage.getItem("testRemoveViewer") && history.push("/home/user");
    }
  }, []);

  useEffect(() => {
    db.collection("chatrooms")
      .doc(`${eventId}`)
      .collection("messages")
      .doc("LiveViewers")
      .update({
        liveCount: firebase.firestore.FieldValue.increment(1),
        totalCount: firebase.firestore.FieldValue.increment(1),
      });
    return () => {
      db.collection("chatrooms")
        .doc(`${eventId}`)
        .collection("messages")
        .doc("LiveViewers")
        .update({
          liveCount: firebase.firestore.FieldValue.increment(-1),
        });
    };
  }, []);

  useEffect(() => {
    EventReportReasonList()
      .then((response) => {
        setEReasonList(response.data.data);
      })
      .catch((error) => {
      });
  }, []);

  const sendMsgSignal = () => {
    const trimmedMsg = msgText.trim();
    if (trimmedMsg) {
      let dateStamp = new Date();
      db.collection("chatrooms")
        .doc(`${eventId}`)
        .collection("messages")
        .add({
          type: "userMessage",
          data: {
            msgText: trimmedMsg,
            firstName: userData.firstName,
            lastName: userData.lastName,
            userId: userData._id,
            profilePhoto: userData.profilePhoto ? userData.profilePhoto : "",
            timeStamp: `${dateStamp.getHours()}:${dateStamp.getMinutes() < 10 ? "0" + dateStamp.getMinutes() : dateStamp.getMinutes()}`,
          },
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          setMsgText("");
        })
        .catch((error) => {
          setMsgText("");
        });
      setMsgText("");
    }
  };

  const handleHeartSignal = async () => {
    let payload = {
      eventId: eventId
    }
    try {
      let response = await LikeEvent(payload);
      if (response.status === 200) {
        Store.store.dispatch({ type: "CHANGE_LIKED" });
      }
    } catch (error) {
    }
  }

  const handleThankYou = () => {
    setShowThankYou(false);
    setVideoPlaying(false);
    history.push("/home/user");
  };

  const handleKickOff = () => {
    setShowKickOff(false);
    history.push("/home/user");
  };

  const handleTipAmountChange = (value) => {
    if (value.split(".")[1] || value.split(".")[1] == "") {
      setTipAmount(
        value.split(".")[0] +
        "." +
        value
          .slice(value.indexOf("."), value.length)
          .replace(/[^0-9]/g, "")
          .slice(0, 2)
      );
    } else {
      setTipAmount(value);
    }
  };

  const handleTipPayNow = () => {
    if (tipAmount) {
      setShowTip(false);
      setTipSuccess(true);
    } else {
      setTipError("Please enter a Tip Amount");
      return;
    }
  };

  const handleArtistTip = async () => {
    setDisableTipButton(true);
    const payload = {
      eventId: eventId,
      tipAmt: parseFloat(tipAmount).toFixed(2),
      customerId: paymentMethods[0].customerId,
      value: parseFloat(tipValue).toFixed(2)
    };
    try {
      const response = await TipArtist(payload);
      if (response && response.status === 200) {
        Notify("success", `You tipped ${tipAmount} to the Artist`);
        handleTipSignal();
        setDisableTipButton(false);
        setTipSuccess(false);
      }
    } catch (error) {
      Notify("danger", `Error ${error.response.data.message}`);
      setTipAmount("");
      setTipValue("");
      setDisableTipButton(false);
      setTipSuccess(false);
    }
  };

  const handleTipSignal = () => {
    let dateStamp = new Date();
    db.collection("chatrooms")
      .doc(`${eventId}`)
      .collection("messages")
      .add({
        type: "tipArtist",
        data: {
          tippedAmount: parseFloat(tipValue).toFixed(2),
          userTippedAmount: parseFloat(tipAmount).toFixed(2),
          firstName: userData.firstName,
          lastName: userData.lastName,
          userId: userData._id,
          profilePhoto: userData.profilePhoto ? userData.profilePhoto : "",
          timeStamp: `${dateStamp.getHours()}:${dateStamp.getMinutes() < 10 ? "0" + dateStamp.getMinutes() : dateStamp.getMinutes()}`,
        },
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        setTipAmount("");
        setTipValue("");
      })
      .catch((error) => {
      });
  };

  const handleImage = async (file) => {
    if (file) {
      let reader = new FileReader();
      reader.onload = async () => {
        if (file.size > 2048576) {
          return;
        } else {
          const formdata = new FormData();
          formdata.append("image", file);
          try {
            const response = await FileUpload(formdata);
            if (response && response.status === 200) {
              sendImageSignal(response.data.fileName);
            }
          } catch (error) {
            Notify("danger", "Error sending image");
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const sendImageSignal = (imageUrl) => {
    let dateStamp = new Date();
    db.collection("chatrooms")
      .doc(`${eventId}`)
      .collection("messages")
      .add({
        type: "chatImage",
        data: {
          image: imageUrl,
          firstName: userData.firstName,
          lastName: userData.lastName,
          userId: userData._id,
          profilePhoto: userData.profilePhoto ? userData.profilePhoto : "",
          timeStamp: `${dateStamp.getHours()}:${dateStamp.getMinutes() < 10 ? "0" + dateStamp.getMinutes() : dateStamp.getMinutes()}`,
        },
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        setShowGif(false);
      })
      .catch((error) => {
      });
  };

  // Event Report
  const handleEventReport = async (e) => {
    if (Validation.empty(eReportReason)) {
      setEReportError("Please select a reason to Report Event");
      return;
    }
    let payload = {
      reason: eReportReason,
      note: eReportNote,
      eventId: eventId,
    };
    try {
      const response = await ReportEventActor(payload);
      if (response && response.status === 200) {
        Notify("success", "Event reported!!");
        setEReportReason("");
        setEReportNote("");
        setShowReportForm(false);
      }
    } catch (error) {
      Notify("danger", `${error.response.data.message}`);
      setEReportReason("");
      setEReportNote("");
      setShowReportForm(false);
    }
  };

  // Comment Report
  const handleCommentReport = async (e) => {
    if (Validation.empty(eReportReason)) {
      setEReportError("Please select a reason to Report Comment");
      return;
    }
    let payload = {
      reason: eReportReason,
      note: eReportNote,
      eventId: eventId,
      commentText: commentText,
      commentedBy: commentBy,
    };
    try {
      const response = await ReportComment(payload);
      if (response && response.status === 200) {
        Notify("success", "Comment reported!!");
        setEReportReason("");
        setEReportNote("");
        setShowReportForm(false);
        setIsReportComment(false);
        setCommentBy("");
      }
    } catch (error) {
      Notify("danger", `${error.response.data.message}`);
      setEReportReason("");
      setEReportNote("");
      setCommentBy("");
      setShowReportForm(false);
      setIsReportComment(false);
    }
  };

  // Event Rating
  const handleRating = async () => {
    let payload = {
      eventId: eventId,
      rate: rating,
    };
    try {
      const response = await RateEvent(payload);
      if (response && response.status === 200) {
        setRating("");
        setbtnDisable(true)
        setShowSurvey(false);
        setShowThankYou(true);
        setVideoPlaying(false);
        Notify("success", `Event rated successfully!!`);
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
    }
  };

  const handleEventFullscreen = () => {
    const div = document.getElementById("forFullScreen");
    if (!toggleFS) {
      if (div.requestFullscreen) {
        div.requestFullscreen();
        setToggleFS(!toggleFS);
      } else if (div.webkitRequestFullscreen) {
        div.webkitRequestFullscreen();
        setToggleFS(!toggleFS);
      } else if (div.msRequestFullScreen) {
        div.msRequestFullScreen();
        setToggleFS(!toggleFS);
      } else if (div.mozFullScreen) {
        div.mozFullScreen();
        setToggleFS(!toggleFS);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setToggleFS(!toggleFS);
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
        setToggleFS(!toggleFS);
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
        setToggleFS(!toggleFS);
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
        setToggleFS(!toggleFS);
      }
    }
  };

  const handleExit = async () => {
    setEndSessionResponse(true);
    try {
      let response = await RemoveViewer({ eventId: eventId });
      if (response.status === 200) {
        setEndSessionResponse(false);
        setShowEndSession(false);
        Store.store.dispatch({ type: "EMPTY_CHAT" });
        history.push("/home/user");
      }
    } catch (error) {
      setEndSessionResponse(true);
      setShowEndSession(false);
    }
  }

  const handleSomethingWentWrong = async () => {
    try {
      let response = await RemoveViewer({ eventId: eventId });
      if (response.status === 200) {
        history.push("/home/user");
      }
    } catch (error) {
    }
  }

  // Card Number Change Handler
  const handleCardNumberChange = (value) => {
    let numberValidation = valid.number(value);
    setCardNumber(value);
    if (numberValidation.isPotentiallyValid && numberValidation.card) {
      setCardImage(cardImages[numberValidation.card.type]);
    } else {
      setCardImage(cardImages["default"]);
    }
  };

  // Add Card Details
  const addCardDetails = async () => {
    let fields = ["cardHolderName", "cardNumber", "expirationDate", "cardCode"];
    let isvalid = false;
    for (let i = 0; i < fields.length; i++) {
      isvalid = handleValidation(fields[i]);
      if (!isvalid) break;
    }
    if (!isvalid) return;
    setAddDisabled(true);
    const payload = {
      cardNumber: cardNumber,
      cvv: cardCode,
      expMonth: expiryMonth,
      expYear: expiryYear,
      name: cardHolderName.trim(),
      isDefault: "true",
    };

    try {
      let response = await AddPaymentMethods(payload); //API call
      if (response && response.status === 200) {
        setShowAddCard(false);
        setCardHolderName("");
        setCardNumber("");
        setExpiryMonth("");
        setExpiryYear("");
        setCardCode("");
        setCardImage(cardImages["default"]);
        setAddDisabled(false);

        Notify("success", "Your card has been added successfully!!");
      }
    } catch (error) {
      Notify("danger", `Error: ${error.response.data.message}`);
      setAddDisabled(false);
    }
  };

  // validator function
  const handleValidation = (type) => {
    if (type === "cardHolderName") {
      if (Validation.empty(cardHolderName)) {
        setError({ ...error, cardHolderName: "Card Holder Name is required" });
        return false;
      } else if (!Validation.name(cardHolderName)) {
        setError({
          ...error,
          cardHolderName: "Min 2 and Max 50 characters allowed",
        });
        return false;
      }
    } else if (type === "cardNumber") {
      let numberValidation = valid.number(cardNumber, {
        luhnValidateUnionPay: true,
      });
      let test = numberValidation.isValid;
      if (!numberValidation.isValid) {
        setError({ ...error, cardNumber: "Invalid Card Number" });
        return false;
      }
    } else if (type === "expirationDate") {
      let expireValidation = valid.expirationDate(
        `${expiryMonth}/${expiryYear}`
      );
      if (!expireValidation.isPotentiallyValid || !expireValidation.isValid) {
        setError({ ...error, expirationDate: "Invalid expiry date selected" });
        return false;
      }
    } else if (type == "cardCode") {
      if (cardCode.length < 3 || cardCode.length > 4) {
        setError({
          ...error,
          cardCode: "CVV should be of 3 or 4 characters long",
        });
        return false;
      }
    }
    return true;
  };


  return (
    <div>
      <div id="app" className="page-video">
        <div className="live-video" id="forFullScreen">
          <ReactHlsPlayer
            src={broadCastURL}
            autoPlay={videoPlaying}
            muted={!audio}
            controls={false}
            width="100%"
            height="auto"
            id="hlsLiveVid"
            playerRef={playerRef}
            disablePictureInPicture={true}
            hlsConfig={{
              liveSyncDurationCount: 3,
              liveMaxLatencyDurationCount: Infinity,
              liveDurationInfinity: false,
              maxLiveSyncPlaybackRate: 2,
              enableWorker: true,
              highBufferWatchdogPeriod: 3000,
            }}
          />

          <div className="video-overlay">
            {showChat ? <div className="chatBackground">

            </div> : ""}
            <div
              className={`overlay ${(showThankYou ||
                showTip ||
                tipSuccess ||
                showReportForm ||
                showSomethingWentWrong ||
                showBackToHome ||
                showAddCard ||
                showSurvey ||
                showEndSession ||
                showKickOff) &&
                "show"
                }`}
            ></div>
            <div className="video-content-wrap">
              <div className="top-auctions">
                <div className="align-left">
                  <div className="gray-btn-theme">
                    <img
                      src={viewerCountIcon}
                      alt="chat"
                      className="icon-left-align"
                    />
                    {numFormatter(Number(viewC))}
                  </div>
                </div>
                <div className="align-right">
                  <button
                    className="gray-btn-theme"
                    onClick={() => {
                      setShowChat(!showChat)
                    }}
                  >
                    {`${showChat ? "Hide" : "Show"} Chat`}
                    <img
                      src={iconChat}
                      alt="chat"
                      className="icon-right-align"
                    />
                  </button>
                  <div>
                    <button
                      className="gray-btn-theme"
                      onClick={() => setShowShare(!showShare)}
                    >
                      Share
                      <img
                        src={iconShared}
                        alt="chat"
                        className="icon-right-align"
                      />
                    </button>
                    <div className="sharedialog-livestream-wrap">
                      <ShareEvent
                        open={showShare}
                        route="liveStream"
                        shareEventID={eventId}
                        shareEventURL={shareEventURL}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      className="gray-btn-theme"
                      onClick={() => setShowEndSession(true)}
                    >
                      Exit
                      <img
                        src={iconEnd}
                        alt="chat"
                        className="icon-right-align"
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div className="fixed-tip-img">
                <div className="fixed-img-container">
                  <div className="tip-fixed-content-img">
                    <div>
                      <img src={iconTip} alt="tip-icon" style={{ marginBottom: "190px" }} />
                    </div>
                  </div>
                  <div className="tip-fixed-content">
                    <button
                      className="gray-btn-theme"
                      onClick={() => setShowTip(true)}
                      style={{ marginRight: "33px" }}
                    >
                      Add Tip
                    </button>
                  </div>
                </div>
              </div>

              <div
                style={{ display: "flex", position: "absolute", bottom: "0px" }}
              >
                <div>
                  <h3>
                    Live <span style={{ color: "red" }}>•</span>
                  </h3>
                </div>
                <div style={{ marginLeft: "5px" }}>
                  <button
                    className="gray-btn-theme"
                    style={{ marginBottom: "0px", minWidth: "0" }}
                    onClick={() => setAudio(!audio)}
                  >
                    <img
                      src={audio ? iconSpeaker : iconSpeakerMute}
                      alt="chat"
                      className="icon-left-align"
                    />
                  </button>
                </div>
              </div>
              <div
                className="report-event"
                onClick={() => setShowReportForm(true)}
              >
                <img src={reportEventIcon} alt="report" />
              </div>
              <div
                className="fullscreen-event"
                onClick={() => handleEventFullscreen()}
              >
                <img
                  src={toggleFS ? fullscreenOffIcon : fullscreenIcon}
                  alt="report"
                  style={{ width: "25px" }}
                />
              </div>

              {/* Report Form */}
              {showReportForm && (
                <div className="overlay-box video-report-form">
                  <div className="title">
                    <h2>Report Form</h2>
                    <a
                      className="model-close cursorPointer"
                      onClick={() => {
                        setShowReportForm(false);
                        setIsReportComment(false);
                        setEReportError("");
                        setEReportReason("");
                        setEReportNote("");
                        setCommentBy("");
                      }}
                    >
                      ✖
                    </a>
                  </div>
                  <div className="report-form">
                    <p>Please choose the reason below for reporting.</p>
                    <div className="form-group">
                      <select
                        onChange={(e) => setEReportReason(e.target.value)}
                        value={eReportReason}
                        onFocus={() => setEReportError("")}
                        onBlur={(e) => {
                          if (Validation.empty(eReportReason)) {
                            setEReportError(
                              "Please select a reason to Report Event"
                            );
                          }
                        }}
                      >
                        <option value="" style={{ background: "black" }}>
                          Select Reason
                        </option>
                        {eReasonList.map((item, index) => (
                          <option
                            key={index}
                            value={item.reason}
                            style={{ background: "black" }}
                          >
                            {item.reason}
                          </option>
                        ))}
                      </select>
                      {eReportError && (
                        <span style={{ color: "red" }}>{eReportError}</span>
                      )}
                    </div>
                    <div className="form-group">
                      <textarea
                        style={{ resize: "none" }}
                        onChange={(e) => setEReportNote(e.target.value)}
                        placeholder="Add Note (Optional)"
                        rows="7"
                        cols="50"
                        value={eReportNote}
                      ></textarea>
                    </div>
                  </div>
                  <div className="btn-wrap text-center">
                    <button
                      type="button"
                      className="primary-button"
                      onClick={(e) => {
                        isReportComment
                          ? handleCommentReport(e)
                          : handleEventReport(e);
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}

              {/* End Event */}
              {showEndSession && (
                <div className="overlay-box video-confirm-pop">
                  <h3>
                    {"Are you sure you want to exit ?"}
                  </h3>
                  <div className="btn-wrap text-center">
                    <button
                      type="button"
                      className="primary-button"
                      onClick={() => handleExit()}
                      disabled={endSessionResponse}
                    >
                      {endSessionResponse ? (
                        <div>
                          <Logo
                            type="TailSpin"
                            color="#FFFFFF"
                            height={20}
                            width={100}
                          />
                        </div>
                      ) : (
                        "Yes"
                      )}
                    </button>
                    <button
                      type="button"
                      className="secondary-button"
                      onClick={() => setShowEndSession(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              )}

              {/* Show Survey */}
              {showSurvey && (
                <div className="overlay-box video-confirm-pop">
                  <div className="title">
                    <h2>Rate Event</h2>
                    <a
                      className="model-close cursorPointer"
                      onClick={() => {
                        setShowSurvey(false);
                        setShowThankYou(true);
                        setVideoPlaying(false);
                        setRating("");
                      }}
                    >
                      ✖
                    </a>
                  </div>
                  <div className="model-body">
                    <div className="rate-options">
                      <div className="form-wrap">
                        <div>
                          <h3>{isCancelledByAdmin && `This event has been deactivated from admin due to too many reports.`} <br /> How was your experience with the event of {actorName}?</h3>
                          <div className="field-wrap">
                            <div className="radio-btn-wrap">
                              <label>
                                <span>
                                  <img
                                    src={
                                      rating === "5" ? likeFilled : likeOutline
                                    }
                                  />
                                </span>
                                <input
                                  type="radio"
                                  id="perfect"
                                  name="rating"
                                  value="5"
                                  onChange={(e) => setRating(e.target.value)}
                                />
                                Perfect
                              </label>
                            </div>
                          </div>
                          <div className="field-wrap">
                            <div className="radio-btn-wrap">
                              <label>
                                <span>
                                  <img
                                    src={
                                      rating === "0"
                                        ? dislikeFilled
                                        : dislikeOutline
                                    }
                                  />
                                </span>
                                <input
                                  type="radio"
                                  id="bad"
                                  name="rating"
                                  value="0"
                                  onChange={(e) => setRating(e.target.value)}
                                />
                                Bad
                              </label>
                            </div>
                          </div>
                          <div className="btn-wrap text-center">
                            <button
                              type="button"
                              disabled={btnDisable}
                              className="primary-button"
                              onClick={() => handleRating()}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Kick Out */}
              {showKickOff && (
                <div className="overlay-box video-confirm-pop"
                  style={{ maxWidth: "650px" }}
                >
                  <h3>
                    You have been banned from watching this event as you have violated the Terms of Use of LiveStage.
                  </h3>
                  <h3>
                    Reason: Violation of the Code of Conduct
                  </h3>
                  <div className="btn-wrap text-center">
                    <button
                      type="submit"
                      className="primary-button back-to-home"
                      onClick={() => handleKickOff()}
                    >
                      Back to Home
                    </button>
                  </div>
                </div>
              )}

              {/* ThankYou Session End */}
              {showThankYou && (
                <div className="overlay-box video-confirm-pop">
                  <h3>
                    Thank you for watching this <br /> event. Hope you have
                    enjoyed it
                  </h3>
                  <div className="btn-wrap text-center">
                    <button
                      type="submit"
                      className="primary-button back-to-home"
                      onClick={() => handleThankYou()}
                    >
                      Back to Home
                    </button>
                  </div>
                </div>
              )}

              {/* Something went wrong (mobile in background scenario) */}
              {!showSurvey && !showThankYou && showSomethingWentWrong && (
                <div className="overlay-box video-confirm-pop">
                  <h3>Something went wrong, please rejoin the event.</h3>
                  <div className="btn-wrap text-center">
                    <button
                      type="button"
                      className="primary-button back-to-home"
                      onClick={() => handleSomethingWentWrong()}
                    >
                      Back to Home
                    </button>
                  </div>
                </div>
              )}

              {/* Back To Home */}
              {showBackToHome && (
                <div className="overlay-box video-confirm-pop">
                  <h3>Please rejoin the session.</h3>
                  <div
                    className="btn-wrap text-center"
                    style={{ marginTop: "0px" }}
                  >
                    <button
                      type="button"
                      className="primary-button"
                      onClick={() => history.push("/home/user")}
                    >
                      Return To Home
                    </button>
                  </div>
                </div>
              )}

              {/* Make Tip  */}
              {showTip && (
                <div className="overlay-box video-report-form tip-amt-form">
                  <div className="inner" style={{ overflowY: "auto", height: "500px" }}>
                    <div className="text-right">
                      <a
                        className="model-close cursorPointer"
                        onClick={() => {
                          setTipAmount("");
                          setTipValue("");
                          setShowTip(false);
                        }}
                      >
                        ✖
                      </a>
                    </div>
                    <div className="tip-image">
                      <img src={iconTipAdd} alt="tip" />
                    </div>
                    <div className="title">
                      <h2>Select Tip Amount</h2>
                    </div>
                    <div className="form-tip-amt">
                      <div className="form-group btn-amt-items"
                        style={{ flexWrap: "wrap" }}
                      >
                        {tippingItems?.map((item, index) => (
                          <button
                            className="btn-amt"
                            onClick={() => {
                              setTipAmount(item.key);
                              setTipValue(item.value);
                            }}
                            key={index}
                          >
                            € {item.key}
                          </button>
                        ))}
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          value={tipAmount}
                          onChange={(e) =>
                            handleTipAmountChange(
                              e.target.value.replace(/[^0-9|^.]/g, "")
                            )
                          }
                          onFocus={() => setTipError("")}
                          onBlur={(e) => {
                            if (e.target.value) {
                              handleTipAmountChange(e.target.value + ".00");
                            }
                          }}
                          maxLength="7"
                          disabled={true}
                        />
                        {tipError && (
                          <span style={{ color: "red" }}>{tipError}</span>
                        )}
                      </div>
                      <div className="form-group">
                        <div className="default-payment">
                          <h5>Your default Payment method</h5>
                          <div className="acc-num">
                            {paymentMethods?.length > 0 ? (<><img
                              src={cardImage}
                              alt="chat"
                              className="icon-right-align"
                            />
                              ************{paymentMethods[0].lastDigit}</>) : (
                              <p style={{ color: "red" }}> Please Add your Card Details.</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrap text-center">
                      {paymentMethods?.length > 0 ? <button
                        type="submit"
                        className="primary-button"
                        onClick={() => handleTipPayNow()}
                      >
                        Pay Now
                      </button> :
                        <button
                          type="button"
                          className="primary-button"
                          onClick={() => {
                            setShowTip(false);
                            setTipAmount("");
                            setTipValue("");
                            setShowAddCard(true);
                          }}
                        >
                          Add Card
                        </button>}
                    </div>
                  </div>
                </div>
              )}

              {showAddCard &&
                <div className="overlay-box video-report-form">
                  <div className="title">
                    <h2>Add Card</h2>
                    <a
                      className="model-close cursorPointer"
                      onClick={() => {
                        setShowTip(false)
                        setShowAddCard(false);
                        setCardHolderName("");
                        setExpiryMonth("");
                        setCardNumber("");
                        setCardCode("");
                        setExpiryYear("");
                        setCardImage(cardImages["default"]);
                      }}
                    >
                      ✖
                    </a>
                  </div>

                  <div className="report-form">
                    <div className="field-wrap corner-space">
                      <label>Card Holder's Name</label>
                      <input
                        type="text"
                        name=""
                        required=""
                        placeholder="Name on card"
                        value={cardHolderName}
                        onChange={(e) => setCardHolderName(e.target.value)}
                        onFocus={(e) =>
                          setError({ ...error, cardHolderName: "" })
                        }
                        onBlur={(e) => handleValidation("cardHolderName")}
                      />
                      {error && (
                        <span style={{ color: "red" }}>
                          {error?.cardHolderName}
                        </span>
                      )}

                      <div
                        className="card-field-wrap"
                        style={{ position: "relative" }}
                      >
                        <label>Card Number</label>
                        <input
                          type="text"
                          name=""
                          required=""
                          placeholder="0000 0000 0000 0000"
                          value={cardNumber}
                          onChange={(e) =>
                            handleCardNumberChange(
                              e.target.value.replace(/[^0-9]/g, "")
                            )
                          }
                          onFocus={(e) =>
                            setError({ ...error, cardNumber: "" })
                          }
                          onBlur={(e) => handleValidation("cardNumber")}
                        />
                        <div
                          className="card-icon-con"
                          style={{ top: "20px", right: "15px" }}
                        >
                          <img src={cardImage} alt="" />
                        </div>
                        {error && (
                          <span style={{ color: "red" }}>
                            {error?.cardNumber}
                          </span>
                        )}
                      </div>

                      <div className="drop-wrapper">
                        <label for="">Expiry Date</label>
                        <div className="drop-row-1">
                          <div className="drop-col-1">
                            <select
                              name=""
                              id=""
                              className="chng-arrow"
                              value={expiryMonth}
                              onChange={(e) =>
                                setExpiryMonth(e.target.value)
                              }
                              onFocus={(e) =>
                                setError({ ...error, expirationDate: "" })
                              }
                            >
                              <option value="">Select Month</option>
                              {monthDropdown.map((month, index) =>
                                expiryYear === currentYear ? (
                                  currentMonth <= month.id && (
                                    <option value={month.id} key={index}>
                                      {month.text}
                                    </option>
                                  )
                                ) : (
                                  <option value={month.id} key={index}>
                                    {month.text}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <div className="drop-col-1">
                            <select
                              name=""
                              id=""
                              className="chng-arrow"
                              value={expiryYear}
                              onChange={(e) =>
                                setExpiryYear(e.target.value)
                              }
                              onFocus={(e) =>
                                setError({ ...error, expirationDate: "" })
                              }
                            >
                              <option value="">Select Year</option>

                              {yearDropdown.map((year, index) => (
                                <option value={year} key={index}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="drop-col-1 w-change">
                            <div className="field-wrap cvv-wrap">
                              <div className="label-cvv">
                                <label for="birthday">CVV</label>
                              </div>
                              <input
                                type="password"
                                name=""
                                placeholder="XXX"
                                value={cardCode}
                                onChange={(e) =>
                                  setCardCode(
                                    e.target.value
                                      .replace(/[^0-9]/g, "")
                                      .slice(0, 4)
                                  )
                                }
                                onFocus={(e) =>
                                  setError({ ...error, cardCode: "" })
                                }
                                onBlur={(e) => handleValidation("cardCode")}
                              />
                              <div className="card-icon-cvv" style={{ top: "0px" }}>
                                <a>
                                  <img src={cvvCard} alt="" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="drop-row-1">
                          <div
                            className="drop-col-1"
                            style={{ width: "70%" }}
                          >
                            {error && (
                              <span style={{ color: "red" }}>
                                {error?.expirationDate}
                              </span>
                            )}
                          </div>
                          <div className="drop-col-1">
                            {error && (
                              <span style={{ color: "red" }}>
                                {error?.cardCode}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="btn-wrap text-center">
                          <button
                            type="button"
                            className="primary-button"
                            onClick={() => addCardDetails()}
                            disabled={addDisabled}
                          >
                            Save Card
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {/* Tip Success */}
              {tipSuccess && (
                <div className="overlay-box video-confirm-pop">
                  <h3>
                    Are you sure want to tip <br /> € {tipAmount} to the Artist?
                  </h3>
                  <div className="btn-wrap text-center">
                    <button
                      type="submit"
                      className="primary-button"
                      onClick={() => handleArtistTip()}
                      disabled={disableTipButton}
                    >
                      Yes
                    </button>
                    <button
                      type="submit"
                      className="secondary-button"
                      onClick={() => {
                        setTipSuccess(false);
                        setTipAmount("");
                        setTipValue("");
                      }}
                    >
                      No
                    </button>
                  </div>
                </div>
              )}

              {/* Chat Component */}
              {showChat && (
                <div className="live-comments" >
                  <div className="comments-box">
                    <div className="comments-scroll" id="commentScrolling">
                      {state.messages?.map((msgItem, index) => (
                        <Comments
                          msgItem={msgItem}
                          key={index}
                          setShowReportForm={setShowReportForm}
                          setCommentText={setCommentText}
                          setIsReportComment={setIsReportComment}
                          setCommentBy={setCommentBy}
                        />
                      ))}
                    </div>

                    <div className="input-user-comment">
                      {showGif && (
                        <div className="gif-div">
                          <GridDemo
                            onGifClick={(gif, e) => {
                              e.preventDefault();
                              sendImageSignal(gif.images.preview_gif.url);
                            }}
                          />
                        </div>
                      )}
                      <div className="input-box">
                        <div className="upload-dialog">
                          <img src={addThumb} alt="add" />
                          <input
                            type="file"
                            accept="image/jpeg, image/gif"
                            onChange={(e) => handleImage(e.target.files[0])}
                            title=""
                            disabled={disableChat}
                          />
                        </div>
                        <input
                          type="text"
                          value={msgText}
                          placeholder="Write a comment...."
                          onChange={(e) => setMsgText(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") sendMsgSignal();
                          }}
                          disabled={disableChat}
                        />
                        <div
                          className="gifUpload"
                          onClick={() =>
                            !disableChat && setShowGif(!showGif)}
                        >
                          GIF
                        </div>
                        <button type="button" onClick={() => sendMsgSignal()}
                          disabled={disableChat}
                        >
                          Send
                        </button>
                      </div>
                      <div
                        className="like-auctions cursorPointer"
                      >
                        <span
                          className={`heart ${heartLike && "heart-active"}`}
                          onClick={() => {
                            if (!heartLike) {
                              setHeartLike(true);
                              handleHeartSignal();
                            }
                          }}
                          tooltip="Like Event"
                        ></span>
                        <div className="hearts"></div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ USER_REDUCER, CHAT_FILE }) => {
  return {
    userData: USER_REDUCER?.myAccountData,
    messageArray: CHAT_FILE.messageArray,
    eventID: CHAT_FILE.eventID,
    isLiked: CHAT_FILE.isLiked
  };
};

export default connect(mapStateToProps, {})(SubscriberHLS);